import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogFooter,
} from "@/components/ui/dialog";
import { Calendar } from "@/components/ui/calendar";
import { es } from "date-fns/locale";
import { Button } from "@/components/ui/button";
import { Loading, RetryErrorMessage } from "@/components";
import { useState } from "react";
import { ScrollArea } from "@/components/ui/scroll-area";
import {
  subscriptionNextDeliveryDateMutation,
  nextWeekDeliveryDatesQuery,
} from "./queries";
import {
  dateStringToDatetime,
  datetimeToDateString,
  formatDefault,
  getPaymentDateFromDeliveryDate,
} from "@/utils/dates";
import { cn } from "@/lib/utils";

type ChangeNextDeliveryDateProps = {
  hasActiveOrder: boolean;
  deliveryDate: string;
  onClose: () => void;
};

const ChangeNextDeliveryDate = ({
  hasActiveOrder,
  deliveryDate,
  onClose,
}: ChangeNextDeliveryDateProps) => {
  const [selectedDeliveryDate, setSelectedDeliveryDate] =
    useState(deliveryDate);

  const {
    data: response,
    isLoading,
    isError,
    error,
    refetch,
  } = nextWeekDeliveryDatesQuery();

  const data = response?.data;
  const mutation = subscriptionNextDeliveryDateMutation();

  const handleMutation = () => {
    mutation.mutate({ delivery_date: selectedDeliveryDate });
  };

  const mutationResponse = mutation.data?.data;

  const savedButtonIsDisabled =
    deliveryDate === selectedDeliveryDate || mutation.isPending;

  if (mutation.isError) {
    return (
      <RetryErrorMessage
        title={"No se pudo guardar los cambios. Intenta de nuevo más tarde."}
        message={mutation.error.message}
        retry={handleMutation}
        error={mutation.error}
      />
    );
  }

  const renderContent = () => {
    if (isLoading) {
      return <Loading message="Buscando días disponibles..." />;
    }

    if (isError) {
      return (
        <RetryErrorMessage
          title={
            "No se pudo obtener los días disponibles. Intenta de nuevo más tarde."
          }
          message={error.message}
          retry={refetch}
          error={error}
        />
      );
    }

    if (mutationResponse?.status === "ERROR") {
      if (mutationResponse.error_code === "RECIPE_NOT_READY") {
        return (
          <RetryErrorMessage
            title={"Order activa"}
            message={
              <p>
                Podrás cambiar tu suscripción una vez tengas acceso a tu orden
                activa.{" "}
                <a href="https://wa.me/56971295665" className="underline">
                  Si necesitas ayuda comunícate con nosotros
                </a>
              </p>
            }
          />
        );
      } else {
        return (
          <RetryErrorMessage
            title={"Error inesperado"}
            message={
              "Hubo un error inesperado. Por favor intenta de nuevo más tarde."
            }
            retry={handleMutation}
          />
        );
      }
    }

    if (data) {
      const parsedDates = data.dates.map((dateString) =>
        dateStringToDatetime(dateString),
      );
      return (
        <div className="flex flex-col gap-4 md:flex-row">
          <div className="border rounded-md p-4">
            <p className="text-sm font-medium text-center">
              Días disponibles para siguiente envío
            </p>
            <div className="flex justify-center">
              <Calendar
                mode="single"
                className="font-sans"
                disabled={(date) =>
                  !data.dates.includes(datetimeToDateString(date))
                }
                onSelect={(value) => {
                  if (value) {
                    setSelectedDeliveryDate(datetimeToDateString(value));
                  }
                }}
                selected={dateStringToDatetime(selectedDeliveryDate)}
                fromDate={parsedDates[0]}
                toDate={parsedDates.at(-1)}
                locale={es}
              />
            </div>
          </div>
          <div className="flex flex-col justify-center items-center border rounded-md p-4 bg-primary/10 text-base md:text-sm">
            <p
              className={cn(
                "text-center",
                !savedButtonIsDisabled && "line-through",
              )}
            >
              Tu próximo envío es el{" "}
              <strong className={cn("text-primary font-medium font-sans")}>
                {formatDefault(deliveryDate)}
              </strong>
              .
            </p>
            {!savedButtonIsDisabled && (
              <div>
                <p className="mt-2 text-center">
                  ¿Deseas recibir tu próxima caja el{" "}
                  <strong className="text-primary font-medium font-sans">
                    {formatDefault(selectedDeliveryDate)}
                  </strong>
                  ?
                </p>
                <p className="text-xs text-center mt-4">
                  *El siguiente cobro sería el{" "}
                  <span className="font-sans font-medium">
                    {formatDefault(
                      getPaymentDateFromDeliveryDate(selectedDeliveryDate),
                    )}
                  </span>
                </p>
              </div>
            )}
          </div>
        </div>
      );
    }
  };

  return (
    <Dialog open={true} onOpenChange={onClose}>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>Cambiar día de envío</DialogTitle>
        </DialogHeader>
        <ScrollArea className="h-[80vh]">
          {mutationResponse?.status === "OK" ? (
            <p className="text-center text-sm font-semibold pt-4">
              ✅ La fecha de tu próximo envío ahora es el{" "}
              <strong className="text-primary">
                {formatDefault(selectedDeliveryDate)}
              </strong>
              .
            </p>
          ) : (
            <div className="grid gap-4 pt-4">{renderContent()}</div>
          )}
          {hasActiveOrder && (
            <p className="text-center text-sm text-primary font-semibold py-4">
              🔔 Tienes una orden activa. El cambio que hagas aplicará para tus
              siguientes envíos.
            </p>
          )}
          <DialogFooter className="gap-4 pt-2 md:gap-2">
            <Button
              type="button"
              variant="secondary"
              onClick={onClose}
              disabled={mutation.isPending}
            >
              Cerrar
            </Button>
            {mutationResponse?.status !== "OK" && (
              <Button
                type="submit"
                disabled={savedButtonIsDisabled}
                onClick={handleMutation}
              >
                {mutation.isPending ? "Guardando..." : "Guardar cambio"}
              </Button>
            )}
          </DialogFooter>
        </ScrollArea>
      </DialogContent>
    </Dialog>
  );
};

export default ChangeNextDeliveryDate;
