import axios from "axios";
import { useQuery, useQueryClient, useMutation } from "@tanstack/react-query";
import { config } from "@/config";
import { getToken, getUIDParam } from "@/services/utils";
import { ShippingFrecuency, ShippingType } from "@/services/types";

type BaseSubscriptionData = {
  subscription_id: number;
  subscription_name: string;
  subscription_price: string;
  shipping_type: ShippingType;
  shipping_frecuency: ShippingFrecuency;
  shipping_cost: number;
  district_id: number;
  district_name: string;
  delivery_date?: string;
};

type DeliveryReactivationWizardData = BaseSubscriptionData & {
  shipping_type: "DELIVERY";
  address: string;
  address_dpto: string;
  address_additional_data: string;
  pickup_point_id?: string;
  pickup_name?: string;
  pickup_lat?: string;
  pickup_lng?: string;
  pickup_opening_hours?: string;
};

type PickupReactivationWizardData = BaseSubscriptionData & {
  shipping_type: "PICKUP";
  pickup_point_id: string;
  pickup_name: string;
  pickup_address: string;
  pickup_lat: string;
  pickup_lng: string;
  pickup_opening_hours: string;
  address?: string;
  address_dpto?: string;
  address_additional_data?: string;
  has_payment_method: boolean;
};

export type ReactivationWizardData =
  | DeliveryReactivationWizardData
  | PickupReactivationWizardData;

const fetchReactivationWizardData = async () => {
  const token = await getToken();
  return axios.get<ReactivationWizardData>(
    `${config.BACKEND_URL}/api/v1/subscriptions/reactivation_wizard`,
    {
      headers: { Authorization: `Bearer ${token}` },
      params: {
        ...getUIDParam(),
      },
    },
  );
};

export const reactivationWizardDataQuery = () =>
  useQuery({
    queryKey: ["subscription-wizard-data"],
    queryFn: () => fetchReactivationWizardData(),
  });

export type ReactivationPreConfirmationResponse = {
  order_incl_tax_sub_total: number;
  shipping_incl_tax_total: number;
  discount_incl_tax_total: number;
  order_incl_tax_total: number;
  payment_card_type: string;
  payment_card_number: string;
};

export type ReactivationPreConfirmationErrorResponse = {
  status: "error";
  error_code: string;
};

export type ReactivationPreConfirmationInput = {
  subscription_id: number;
  shipping_type: ShippingType;
  shipping_frecuency: ShippingFrecuency;
  delivery_date: string;
};

const postReactivationPreConfirmation = async (
  data: ReactivationPreConfirmationInput,
) => {
  const token = await getToken();
  return axios.post<
    | ReactivationPreConfirmationResponse
    | ReactivationPreConfirmationErrorResponse
  >(
    `${config.BACKEND_URL}/api/v1/subscriptions/reactivation_wizard_preconfirmation`,
    data,
    {
      headers: { Authorization: `Bearer ${token}` },
      params: {
        ...getUIDParam(),
      },
    },
  );
};

export const reactivationWizardPreConfirmationMutation = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: postReactivationPreConfirmation,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ["subscription-wizard-data"],
      });
    },
  });
};

type ReactivationConfirmationInput = {
  delivery_date: string;
};

const postReactivationConfirmation = async (
  data: ReactivationConfirmationInput,
) => {
  const token = await getToken();
  return axios.post<{
    new_order: any;
    order_has_been_paid: boolean;
    order_has_been_updated: boolean;
  }>(
    `${config.BACKEND_URL}/api/v1/subscriptions/reactivation_wizard_confirmation`,
    data,
    {
      headers: { Authorization: `Bearer ${token}` },
      params: {
        ...getUIDParam(),
      },
    },
  );
};

export const reactivationWizardConfirmationMutation = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: postReactivationConfirmation,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ["subscription-wizard-data"],
      });
    },
  });
};
