import { RegistryData } from "@/context/register";
import { formatWithWeekDay, parseToISO8601 } from "./dates";

function removeUndefinedValues(obj: any) {
  const newObj = { ...obj };
  // Iterate over the copied object and delete properties with undefined values
  Object.keys(newObj).forEach((key) => {
    if (newObj[key] === undefined) {
      delete newObj[key];
    }
  });
  return newObj;
}

export const transformRegistryDataToFirebaseDoc = (
  uid: string,
  registryData: RegistryData,
) => {
  if (!registryData.shipping_first_delivery_date) {
    throw new Error("Delivery Date was not provided");
  }
  const firstDeliveryDate = formatWithWeekDay(
    registryData.shipping_first_delivery_date,
  );
  const data = {
    uid,
    display_name: registryData.display_name,
    email: registryData.email,
    phone_number: registryData.phone_number,

    form_subscription_id: Number(registryData.subscription_id ?? ""),
    form_shipping_type: registryData.shipping_type,

    form_delivery_additional_data: registryData.aditional_notes,
    form_delivery_address: registryData.address,
    // Typo depto and dpto is intentional. Firebase is saving as depto but the DB is dpto. Keep it until fix it
    form_delivery_address_depto: registryData.address_dpto ?? "",
    // address_number is required on the backend but it´s no longer used. We send the dpto as fallback
    form_delivery_address_number: registryData.address_dpto ?? "",
    form_delivery_district_name: registryData.delivery_district,

    form_pickup_district_name: registryData.pickup_district,
    form_pickup_name: registryData.pickup_name,

    form_shipping_first_delivery_date: firstDeliveryDate,
    form_shipping_frecuency: registryData.shipping_frecuency,

    shipping_entity_id: registryData.shipping_entity_id,

    promo_code: registryData.promo_code,
    subscription_status: "SUBSCRIPTION_PENDING",

    user_referral_id: registryData.referrer_user_id,

    partnership_data: registryData.partnership_data,
    // Flag to recognize users using the new frontend. This can be removed once flutterflow app is removed
    new_frontend: true,
  };
  return removeUndefinedValues(data);
};

export const transformFirebaseDocToRegistryData = (doc: any) => {
  const data: RegistryData = {
    display_name: doc?.display_name,
    email: doc?.email,
    phone_number: doc?.phone_number,
    subscription_id: doc?.form_subscription_id,
    shipping_type: doc?.form_shipping_type,
    aditional_notes: doc?.form_delivery_additional_data,
    address: doc?.form_delivery_address,
    // Typo depto and dpto is intentional. Firebase is saving as depto but the DB is dpto. Keep it until fix it
    address_dpto: doc?.form_delivery_address_depto,
    delivery_district: doc?.form_delivery_district_name,
    pickup_district: doc?.form_pickup_district_name,
    pickup_name: doc?.form_pickup_name,
    shipping_frecuency: doc?.form_shipping_frecuency,
    promo_code: doc?.promo_code,
    referrer_user_id: doc?.user_referral_id,
    subscription_status: doc?.subscription_status,
    payment_subscription_error: doc?.payment_subscription_error,
    shipping_entity_id: doc?.shipping_entity_id,
    shipping_first_delivery_date: doc?.form_shipping_first_delivery_date
      ? parseToISO8601(doc?.form_shipping_first_delivery_date)
      : undefined,
    partnership_data: doc?.partnership_data,
  };
  return removeUndefinedValues(data);
};
