import {
  AlertDialogCancel,
  AlertDialogFooter,
  AlertDialogAction,
} from "@/components/ui/alert-dialog";
import { Loading, RetryErrorMessage } from "@/components";
import { subscriptionsDataQuery } from "@/services/queries";
import { useState } from "react";
import { cn } from "@/lib/utils";
import { formatCurrency } from "@/utils/currency";
import { ReactivationWizardData } from "./queries";
import { SubscriptionData } from "@/services/types";
import { ScrollArea } from "@/components/ui/scroll-area";

type SubscriptionsUpdateData = Pick<
  ReactivationWizardData,
  "subscription_name" | "subscription_price" | "subscription_id"
>;

type SubscriptionsProps = {
  reactivationData: ReactivationWizardData;
  onClose: () => void;
  onUpdate: (subscriptionData: SubscriptionsUpdateData) => void;
};

const Subscriptions = ({
  reactivationData,
  onClose,
  onUpdate,
}: SubscriptionsProps) => {
  const {
    data: response,
    isLoading,
    isError,
    error,
    refetch,
  } = subscriptionsDataQuery({});
  const [selectedSubscriptionId, setSelectedSubscriptionId] = useState(
    reactivationData.subscription_id,
  );
  const [selectedSubscriptionName, setSelectedSubscriptionName] =
    useState<string>();
  const [selectedSubscriptionPrice, setSelectedSubscriptionPrice] =
    useState<string>();

  const data = response?.data;
  const subscriptions = data?.subscriptions;

  const handleSubscriptionSelection = (s: SubscriptionData) => {
    setSelectedSubscriptionId(s.id);
    setSelectedSubscriptionName(s.name);
    setSelectedSubscriptionPrice(formatCurrency(s.price_incl_tax));
  };

  const handleConfirm = () => {
    onUpdate({
      subscription_name: selectedSubscriptionName!,
      subscription_price: selectedSubscriptionPrice!,
      subscription_id: selectedSubscriptionId,
    });
    onClose();
  };

  if (isLoading) {
    return <Loading message="🔍 Buscando información de tu suscripción..." />;
  }

  if (isError) {
    return (
      <RetryErrorMessage
        title={"No se pudo obtener la información de tu suscripción."}
        message={error.message}
        retry={refetch}
        error={error}
      />
    );
  }

  return (
    <>
      <p className="font-semibold text-sm mb-2">¿Qué tipo de caja quieres?</p>
      <ScrollArea className="h-[300px]">
        <div className="space-y-4">
          {subscriptions?.map((s) => (
            <div
              key={s.id}
              className={cn(
                "border p-2 rounded-md cursor-pointer hover:bg-primary/10",
                selectedSubscriptionId === s.id &&
                  "border-primary bg-primary/10",
              )}
              onClick={() => handleSubscriptionSelection(s)}
            >
              <h5 className="text-sm font-semibold">📦 {s.name}</h5>
              <p className="text-xs text-muted-foreground">{s.description}</p>
              <p className="text-base text-primary font-semibold font-sans mt-2">
                {formatCurrency(s.price_incl_tax)}
              </p>
            </div>
          ))}
        </div>
      </ScrollArea>

      <AlertDialogFooter className="pt-4">
        <AlertDialogCancel onClick={onClose}>Volver</AlertDialogCancel>
        <AlertDialogAction
          disabled={selectedSubscriptionName == null}
          onClick={handleConfirm}
        >
          Confirmar
        </AlertDialogAction>
      </AlertDialogFooter>
    </>
  );
};

export default Subscriptions;
