import * as z from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import {
  Form,
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form";
import { Input } from "@/components/ui/input";
import { Button } from "@/components/ui/button";
import { PhoneInput } from "@/components/ui/phone-input";
import { useRegister } from "@/context/register";
import { Link, useNavigate } from "@tanstack/react-router";
import { createUserMutation } from "@/services/queries";
import { CustomLink } from "..";

const userDataSchema = z
  .object({
    display_name: z
      .string({
        required_error: "⚠️ Necesitamos saber como referirnos a ti.",
      })
      .min(2)
      .max(100),
    phone_number: z.string({
      required_error:
        "⚠️ Necesitamos tu número de teléfono para comunicarnos contigo en caso de ser necesario.",
    }),
    email: z
      .string({
        required_error: "⚠️ El email es necesario para crear tu cuenta.",
      })
      .email("No parece ser un email válido."),
    password: z
      .string({
        required_error: "⚠️ La contraseña te permite entrar a tu cuenta.",
      })
      .min(6, "La contraseña debe tener mínimo 6 caracteres."),
    confirm_password: z
      .string({
        required_error:
          "⚠️ Te pedimos confirmar tu contraseña para disminuir errores.",
      })
      .min(6, "La contraseña debe tener mínimo 6 caracteres."),
  })
  .refine((data) => data.password === data.confirm_password, {
    path: ["confirm_password"],
    message: "Las contraseñas deben coincidir",
  });

type UserDataSchema = z.infer<typeof userDataSchema>;

const UserDataForm = () => {
  const { registryData, setRegistryData } = useRegister();
  const createUser = createUserMutation();

  const navigate = useNavigate();

  if (registryData === null) {
    navigate({ to: "/register/subscriptions" });
    return null;
  }

  if (createUser.isSuccess) {
    navigate({ to: "/register/confirmation" });
  }

  const form = useForm<UserDataSchema>({
    resolver: zodResolver(userDataSchema),
    defaultValues: registryData,
  });

  const handleSubmit = (values: UserDataSchema) => {
    const { email, password } = values;
    createUser.mutate({ email, password, registryData });
  };

  const renderError = (error: string) => {
    if (error.includes("auth/email-already-in-use")) {
      return (
        <p>
          Este correo ya está registrado.{" "}
          <Link to={"/login"} className="underline">
            ¿Deseas iniciar sesión?
          </Link>
        </p>
      );
    }
    return error;
  };

  return (
    <Form {...form}>
      <form onSubmit={form.handleSubmit(handleSubmit)} className="space-y-4">
        <FormField
          control={form.control}
          name="display_name"
          render={({ field }) => (
            <FormItem>
              <FormLabel>Nombre</FormLabel>
              <FormControl>
                <Input
                  placeholder="Ingresa tu nombre"
                  autoComplete="name"
                  onChange={(e) => {
                    field.onChange(e);
                    setRegistryData((r) => ({
                      ...r,
                      display_name: e.target.value,
                    }));
                  }}
                  value={field.value}
                />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        <FormField
          control={form.control}
          name="phone_number"
          render={({ field }) => (
            <FormItem>
              <FormLabel>Teléfono</FormLabel>
              <FormControl>
                <PhoneInput
                  id="phone_number"
                  type="tel"
                  placeholder="Ingresa tu número de teléfono"
                  autoComplete="tel"
                  value={field.value}
                  defaultCountry="CL"
                  className="font-sans"
                  countries={["CL"]}
                  onChange={(value) => {
                    field.onChange(value);
                    setRegistryData((r) => ({
                      ...r,
                      phone_number: value,
                    }));
                  }}
                />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        <FormField
          control={form.control}
          name="email"
          render={({ field }) => (
            <FormItem>
              <FormLabel>Email</FormLabel>
              <FormControl>
                <Input
                  placeholder="Ingresa tu email"
                  type="email"
                  autoComplete="email"
                  onChange={(e) => {
                    field.onChange(e);
                    setRegistryData((r) => ({ ...r, email: e.target.value }));
                  }}
                  value={field.value}
                />
              </FormControl>
              <FormDescription>
                🔔 Es importante que tu email esté bien escrito. Toda la
                información de tu suscripción la enviaremos por este medio.
              </FormDescription>
              <FormMessage />
            </FormItem>
          )}
        />
        <FormField
          control={form.control}
          name="password"
          render={({ field }) => (
            <FormItem>
              <FormLabel>Contraseña</FormLabel>
              <FormControl>
                <Input
                  placeholder="Ingresa tu contraseña"
                  type="password"
                  autoComplete="new-password"
                  id="new-password"
                  onChange={field.onChange}
                  value={field.value}
                />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        <FormField
          control={form.control}
          name="confirm_password"
          render={({ field }) => (
            <FormItem>
              <FormLabel>Confirmar contraseña</FormLabel>
              <FormControl>
                <Input
                  placeholder="Ingresa tu contraseña"
                  type="password"
                  autoComplete="new-password"
                  id="confirm_password"
                  onChange={field.onChange}
                  value={field.value}
                />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        <div>
          <p className="text-center text-muted-foreground text-sm">
            Al registrarte en Maifud, aceptas nuestros{" "}
            <CustomLink
              to="/terms-and-conditions"
              className="underline text-primary"
            >
              Términos
            </CustomLink>{" "}
            y{" "}
            <CustomLink to="/privacy-policy" className="underline text-primary">
              Política de privacidad
            </CustomLink>
            .
          </p>
        </div>
        <div className="flex justify-between pt-4 gap-4">
          <Button
            type="button"
            variant="outline"
            className="w-full sm:w-fit"
            onClick={() => navigate({ to: "/register/shipping-frecuency" })}
          >
            Volver
          </Button>
          <Button
            type="submit"
            className="w-full sm:w-fit"
            disabled={createUser.isPending}
            isLoading={createUser.isPending}
          >
            {createUser.isPending ? "Creando usuario" : "Siguiente"}
          </Button>
        </div>
      </form>
      {createUser.isError && (
        <FormMessage className="text-right">
          {renderError(createUser.error.message)}
        </FormMessage>
      )}
    </Form>
  );
};

export default UserDataForm;
