import { Link, LinkProps } from "@tanstack/react-router";

const CustomLink = (props: LinkProps) => {
  /**
   * append the uid query param to every CustomLink
   */
  const urlParams = new URLSearchParams(window.location.search);
  if (urlParams.get("uid")) {
    let newSearch = { uid: urlParams.get("uid") };
    if (typeof props.search === "object") {
      newSearch = { ...newSearch, ...props.search };
    }
    return <Link {...props} search={newSearch} />;
  }
  return <Link {...props} />;
};

export default CustomLink;
