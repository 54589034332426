import { Card } from "@/components/ui/card";
import { RadioGroupItem } from "@/components/ui/radio-group";
import { Label } from "@/components/ui/label";
import { Separator } from "@/components/ui/separator";

import type { SubscriptionData } from "@/services/types";
import { formatCurrency } from "@/utils/currency";
import { getSmallSubscriptionImage } from "@/utils/images";

type SubscriptionProps = {
  subscription: SubscriptionData;
} & React.ComponentProps<typeof Card>;

function Subscription({ subscription }: SubscriptionProps) {
  const {
    id: idAsNumber,
    name,
    price_incl_tax,
    discount,
    price_with_discount,
    aprox_people,
    aprox_quantity,
    aprox_weight,
  } = subscription;
  const id = String(idAsNumber);
  return (
    <div className="group hover:cursor-pointer">
      <RadioGroupItem value={id} id={id} className="peer sr-only" />
      <Label
        htmlFor={id}
        className="flex flex-col gap-2 items-center justify-between rounded-md border-2 border-muted bg-popover px-2 py-8 md:hover:bg-orange-100/50 peer-data-[state=checked]:border-primary [&:has([data-state=checked])]:border-primary peer-data-[state=checked]:bg-orange-100/50 [&:has([data-state=checked])]:bg-orange-100/50"
      >
        <h4 className="text-lg">{name}</h4>
        <figure className="flex justify-center overflow-hidden transition-transform transform">
          <img className="w-full" src={getSmallSubscriptionImage(id)} />
        </figure>
        <div className="flex flex-col space-y-1 my-2">
          <ul className="text-xs text-muted-foreground space-y-1">
            <li className="flex space-x-1 items-center justify-center">
              <span>
                <strong className="font-sans">{aprox_people}</strong> personas
              </span>
            </li>
            <li className="flex space-x-1 items-center text-center">
              <span>
                {/* Different text if fruits box */}
                {id === "4" ? (
                  <>
                    <strong className="font-sans">{aprox_quantity}</strong>{" "}
                    variedades de frutas de temporada
                  </>
                ) : (
                  <>
                    <strong className="font-sans">{aprox_quantity}</strong>{" "}
                    variedades de frutas y verduras de temporada
                  </>
                )}
              </span>
            </li>
            <li className="flex space-x-1 items-center justify-center">
              <span>
                <strong className="font-sans">{aprox_weight}</strong> kgs de
                peso
              </span>
            </li>
          </ul>
        </div>
        <Separator />
        <div className="text-center">
          <div className="text-xs text-muted-foreground">
            <strong className="text-sm font-semibold">
              <span className="font-sans">{`${discount}%`}</span>
            </strong>{" "}
            dto primera caja
          </div>
          <h5 className="text-2xl font-bold text-primary font-sans">
            {formatCurrency(price_with_discount)}
          </h5>
          <div className="text-xs text-muted-foreground">
            y luego{" "}
            <strong className="text-sm font-semibold font-sans">
              {formatCurrency(price_incl_tax)}
            </strong>{" "}
          </div>
          <div className="text-xs text-muted-foreground">+ envío</div>
        </div>
      </Label>
    </div>
  );
}

export default Subscription;
