import carrotImage from "@/assets/carrot.png";
import cornImage from "@/assets/corn.png";
import tomatoImage from "@/assets/tomato.png";
import { useState, useEffect } from "react";
import { Button } from "../ui/button";
import { cn } from "@/lib/utils";

const loadingImages = [carrotImage, cornImage, tomatoImage];

type LoadingProps = {
  message?: string;
  size?: "small" | "normal";
};

const Loading = ({ message, size = "normal" }: LoadingProps) => {
  const [tooLong, setTooLong] = useState(false);
  const [showReloadOption, setShowReloadOption] = useState(false);

  // Change default message after 5 seconds
  useEffect(() => {
    const timer = setTimeout(() => {
      setTooLong(true);
    }, 10000);
    return () => clearTimeout(timer);
  }, []);

  // Show option to reload page after 10 seconds
  useEffect(() => {
    const timer = setTimeout(() => {
      setShowReloadOption(true);
    }, 20000);
    return () => clearTimeout(timer);
  }, []);

  const getRandomImage = (arr: string[]) => {
    if (!Array.isArray(arr) || arr.length === 0) {
      throw new Error("The provided input is not a non-empty array.");
    }
    const randomIndex = Math.floor(Math.random() * arr.length);
    return arr[randomIndex];
  };

  return (
    <>
      {!showReloadOption && (
        <div className="flex justify-center p-4">
          <div
            className={cn(
              "animate-spin",
              size === "normal" && "w-20 h-20",
              size === "small" && "w-10 h-10",
            )}
          >
            <img
              className="w-full"
              src={getRandomImage(loadingImages)}
              alt="Imagen de una verdura girando"
            />
          </div>
        </div>
      )}
      {message && !tooLong && !showReloadOption && (
        <p className="text-center text-muted-foreground">{message}</p>
      )}
      {tooLong && !showReloadOption && (
        <div>
          <div className="text-center text-muted-foreground">
            🤔 Está tomando más tiempo del esperado.
          </div>
        </div>
      )}
      {showReloadOption && (
        <div>
          <div className="text-center text-muted-foreground">
            💥 Parece que algo salio mal. ¿Podrias intentar recargar la página?
          </div>
          <div className="flex justify-center py-2">
            <Button onClick={() => location.reload()}>Recargar</Button>
          </div>
        </div>
      )}
    </>
  );
};

export default Loading;
