import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogFooter,
} from "@/components/ui/dialog";
import { Button } from "@/components/ui/button";
import { subscriptionsDataQuery } from "@/services/queries";
import { Loading, RetryErrorMessage } from "@/components";
import { cn } from "@/lib/utils";
import { formatCurrency } from "@/utils/currency";
import { useState } from "react";
import { subscriptionTypeMutation } from "./queries";
import { getSmallSubscriptionImage } from "@/utils/images";
import { ScrollArea } from "@/components/ui/scroll-area";

type ChangeSubscriptionProps = {
  hasActiveOrder: boolean;
  activeSubscription: number;
  onClose: () => void;
};

const ChangeSubscription = ({
  hasActiveOrder,
  activeSubscription,
  onClose,
}: ChangeSubscriptionProps) => {
  const [selectedSubscriptionId, setSelectedSubscriptionId] =
    useState(activeSubscription);
  const {
    data: response,
    isLoading,
    isError,
    error,
    refetch,
  } = subscriptionsDataQuery({});
  const mutation = subscriptionTypeMutation();
  const data = response?.data;

  const handleMutation = () => {
    mutation.mutate({ subscription_id: selectedSubscriptionId });
  };

  const mutationResponse = mutation.data?.data;

  const savedButtonIsDisabled =
    activeSubscription === selectedSubscriptionId || mutation.isPending;

  const renderContent = () => {
    if (isLoading) {
      return <Loading message="Buscando suscripciones Maifud..." />;
    }

    if (mutationResponse?.status === "ERROR") {
      if (mutationResponse.error_code === "RECIPE_NOT_READY") {
        return (
          <RetryErrorMessage
            title={"Order activa"}
            message={
              <p>
                Podrás cambiar tu suscripción una vez tengas acceso a tu orden
                activa.{" "}
                <a href="https://wa.me/56971295665" className="underline">
                  Si necesitas ayuda comunícate con nosotros
                </a>
              </p>
            }
          />
        );
      } else {
        return (
          <RetryErrorMessage
            title={"Error inesperado"}
            message={
              "Hubo un error inesperado. Por favor intenta de nuevo más tarde."
            }
            retry={refetch}
          />
        );
      }
    }

    if (isError) {
      return (
        <RetryErrorMessage
          title={"No se pudo obtener la información de las suscripciones."}
          message={error.message}
          retry={refetch}
          error={error}
        />
      );
    }

    if (mutation.isError) {
      return (
        <RetryErrorMessage
          title={"No se pudo obtener cambiar la suscripción."}
          message={mutation.error.message}
          retry={handleMutation}
          error={mutation.error}
        />
      );
    }

    if (data) {
      return (
        <div className="grid grid-cols-1 gap-4">
          {data.subscriptions.map((s) => (
            <div
              key={s.name}
              className={cn(
                "border p-2 rounded-md cursor-pointer",
                s.id === selectedSubscriptionId &&
                  "bg-orange-500/10 border-orange-500",
              )}
              onClick={() => setSelectedSubscriptionId(s.id)}
            >
              <div className="flex items-center gap-2">
                <div className="max-w-20">
                  <img
                    src={getSmallSubscriptionImage(String(s.id))}
                    alt={s.name}
                    className="w-full"
                  />
                </div>
                <div>
                  <div className="flex justify-between items-center mb-1">
                    <p className="text-sm font-medium">{s.name}</p>
                    <p className="font-sans text-sm text-primary font-semibold">
                      {formatCurrency(s.price_incl_tax)}
                    </p>
                  </div>
                  <div>
                    <p className="text-xs text-muted-foreground">
                      {s.description}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      );
    }

    return null;
  };

  return (
    <Dialog open={true} onOpenChange={onClose}>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>Cambiar suscripción</DialogTitle>
        </DialogHeader>
        {mutationResponse?.status === "OK" ? (
          <p className="text-center text-sm font-semibold pt-4">
            ✅ Tu nueva suscripción es la{" "}
            <strong className="text-primary ">
              {
                data?.subscriptions.find((s) => s.id === selectedSubscriptionId)
                  ?.name
              }
            </strong>
            .
          </p>
        ) : (
          <ScrollArea className="h-[18rem]">
            <div className="grid gap-4 pt-4">{renderContent()}</div>
          </ScrollArea>
        )}
        {hasActiveOrder && (
          <p className="text-center text-sm text-primary font-semibold">
            🔔 Tienes una orden activa. El cambio que hagas aplicará para tus
            siguientes envíos.
          </p>
        )}
        <DialogFooter className="gap-4 pt-2 md:gap-2">
          <Button
            type="button"
            variant="secondary"
            onClick={onClose}
            disabled={mutation.isPending}
          >
            Cerrar
          </Button>
          {mutationResponse?.status !== "OK" && (
            <Button
              type="submit"
              disabled={savedButtonIsDisabled}
              onClick={handleMutation}
            >
              {mutation.isPending ? "Guardando..." : "Guardar cambio"}
            </Button>
          )}
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};

export default ChangeSubscription;
