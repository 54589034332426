import { ResumeCheckout } from "@/pages";
import { createFileRoute } from "@tanstack/react-router";
import { LayoutDefault } from "@/components";
import * as z from "zod";

const resumeCheckoutSchema = z.object({
  uid: z.optional(z.string()),
  link_url: z.optional(z.string()),
  error: z.optional(z.string()),
});

export type ResumeCheckoutSearchSchema = z.infer<typeof resumeCheckoutSchema>;

const Page = () => (
  <LayoutDefault>
    <ResumeCheckout />
  </LayoutDefault>
);

export const Route = createFileRoute("/rcheckout")({
  component: Page,
  validateSearch: resumeCheckoutSchema,
  beforeLoad: () => {
    document.title = "Maifud - Retomar registro";
  },
});
