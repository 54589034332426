import mega from "@/assets/press/meganoticias.avif";
import t13 from "@/assets/press/t13.avif";
import laTercera from "@/assets/press/la_tercera.svg";
import emol from "@/assets/press/emol.avif";
import duna from "@/assets/press/duna.svg";
import paisCircular from "@/assets/press/pais_circular.png";

const items = [
  {
    image: mega,
    link: "https://www.meganoticias.cl/nacional/378915-vivir-con-sobras-de-la-feria-inflacion-chile-historias-reportaje-01-06-2022.html",
    alt: "Meganoticias",
  },
  {
    image: emol,
    link: "https://www.emol.com/noticias/Economia/2024/01/11/1118261/maifud-dario-contreras.html",
    alt: "Emol",
  },
  {
    image: duna,
    link: "https://www.duna.cl/podcasts/38-en-que-se-parecen-las-startups-nanolife-y-maifud/#",
    alt: "Duna",
  },
  {
    image: laTercera,
    link: "https://www.latercera.com/pulso/noticia/estos-son-los-siete-ganadores-del-premio-iniciativas-sustentables-2023/H2NABIEI5VHSDEPOTGNVLVZUMU/",
    alt: "La Tercera",
  },
  {
    image: paisCircular,
    link: "https://www.paiscircular.cl/economia-circular/maifud-ofrece-frutas-verduras/",
    alt: "País circular",
  },
  {
    image: t13,
    link: "https://www.t13.cl/noticia/tendencias/mujer-sustentable-t13-mayo-mes-del-reciclaje-17-5-2023",
    alt: "Teletrece",
  },
];

const Press = () => {
  return (
    <div className="py-10 bg-white" id="press-section">
      <div className="max-w-7xl mx-auto">
        <h4 className="text-center text-3xl mx-auto max-w-[600px] font-bold py-0 mb-6">
          PRENSA
        </h4>
        <div className="grid grid-cols-3 gap-8 lg:grid-cols-6 lg:gap-4 items-center">
          {items.map((i) => (
            <div key={i.image} className="px-4">
              <a href={i.link} target="_blank">
                <img
                  src={i.image}
                  className="w-[158px] h-[48px] max-h-12 object-contain opacity-70 hover:opacity-100"
                  alt={i.alt}
                />
              </a>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Press;
