import { useState } from "react";
import { CardContent, Card } from "@/components/ui/card";
import { formatDefault } from "@/utils/dates";
import { DBOrder, SummaryBoxItem, SummaryUpsellItem } from "@/services/types";
import { OrderProductsAlert } from "@/components";

type DeliveryDayProps = {
  products?: SummaryBoxItem[];
  upsell?: SummaryUpsellItem[];
  userInput?: string;
  order: DBOrder;
};

const DeliveryDay = ({
  userInput,
  products,
  upsell,
  order,
}: DeliveryDayProps) => {
  const [showProducts, setShowProducts] = useState(false);

  const renderAddress = () => {
    if (order.shipping_type === "PICKUP") {
      const address = `${order.pickup_address}, ${order.pickup_district}`;
      return (
        <div className="mt-4">
          <p className="text-sm text-muted- font-semibold">Retiro en tienda:</p>
          <p className="text-sm text-muted-foreground font-sans">
            <a
              href={`https://www.google.com/maps/place/${address}`}
              target="_blank"
            >
              {address}
            </a>
          </p>
          <p className="text-sm text-muted-foreground font-sans">
            Horario: {order.pickup_opening_hours}
          </p>
        </div>
      );
    }
    if (order.shipping_type === "DELIVERY") {
      const address = `${order.person_address}, ${order.person_address_dpto}, ${order.person_district}`;
      return (
        <div className="mt-4">
          <p className="text-sm text-muted- font-semibold">Dirección:</p>
          <p className="text-sm text-muted-foreground font-sans">{address}</p>
          {order.person_adittional_data && (
            <p className="text-sm text-muted-foreground font-sans">
              "{order.person_adittional_data?.trim()}"
            </p>
          )}
        </div>
      );
    }
    return null;
  };

  return (
    <div>
      <Card className="border rounded-lg col-span-2 relative z-20">
        <div className="bg-primary px-4 py-1 rounded-tl-lg rounded-tr-lg shadow-sm flex justify-between">
          <strong className="text-white font-normal font-sans">
            Tu proximo envio
          </strong>
        </div>
        <CardContent className="px-4">
          <div className="flex justify-between border-b py-4">
            <div>
              <p className="text-xl font-semibold text-primary">
                {formatDefault(order.delivery_date)}
              </p>
              <p className="text-base font-semibold text-muted-foreground">
                {order.subscription_name}
              </p>
              {renderAddress()}
            </div>
            <div className="flex items-center justify-center">
              <button
                type="button"
                onClick={() => setShowProducts(true)}
                className="w-3/4 flex flex-col justify-center items-center"
              >
                <span className="text-4xl">📦</span>
                <span className="text-sm text-primary">
                  Ver el contenido de la caja
                </span>
              </button>
            </div>
          </div>

          <div className="pt-4">
            <div className="gap-6">
              <p className="text-muted-foreground text-sm pt-1">
                <strong>Hoy es el envío de tu caja</strong>. Si tienes algún
                inconveniente{" "}
                <a
                  href="https://wa.me/+56971295665"
                  target="_blank"
                  className="underline text-primary"
                  rel="noopener noreferrer"
                >
                  comunicate con nosotros
                </a>
              </p>
            </div>
          </div>
        </CardContent>
      </Card>
      {showProducts && products && upsell && (
        <OrderProductsAlert
          order={order}
          userInput={userInput}
          products={products}
          upsell={upsell}
          onClose={() => setShowProducts(false)}
        />
      )}
    </div>
  );
};

export default DeliveryDay;
