import { clsx, type ClassValue } from "clsx";
import { twMerge } from "tailwind-merge";

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs));
}

export function formatDate(input: string | number): string {
  const date = new Date(input);
  return date.toLocaleDateString("en-US", {
    month: "long",
    day: "numeric",
    year: "numeric",
  });
}

/**
 *
 * @description takes a date, transform it to ISO string and removes timezone info
 */
export const transformToUTCISOString = (date: Date) => {
  return date.toISOString().split("T")[0] + "T00:00:00.000Z";
};

/**
 *
 * @description takes an ISO
 */
export const transformToTimezonedDate = (dateISOString: string) => {
  const offsetHours = new Date().getTimezoneOffset() / 60;
  const dateStringWithoutTimezone = dateISOString.split("Z")[0];
  const offsetToString = (offsetHours: number) => {
    if (offsetHours > 0) {
      return `-0${offsetHours}:00`;
    } else {
      const absoluteValueOffsetHours = Math.abs(offsetHours);
      return `+0${absoluteValueOffsetHours}:00`;
    }
  };
  return new Date(`${dateStringWithoutTimezone}${offsetToString(offsetHours)}`);
};

export function absoluteUrl(path: string) {
  return `${process.env.NEXT_PUBLIC_APP_URL}${path}`;
}
