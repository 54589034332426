import * as React from "react";

import * as RPNInput from "react-phone-number-input";

import flags from "react-phone-number-input/flags";

import { Button } from "@/components/ui/button";
import { Input, InputProps } from "@/components/ui/input";
import { Popover } from "@/components/ui/popover";

import { cn } from "@/lib/utils";

export type PhoneInputValue = RPNInput.Value;

type PhoneInputProps = Omit<
  React.InputHTMLAttributes<HTMLInputElement>,
  "onChange"
> &
  RPNInput.Props<typeof RPNInput.default>;

const PhoneInput: React.ForwardRefExoticComponent<PhoneInputProps> =
  React.forwardRef<React.ElementRef<typeof RPNInput.default>, PhoneInputProps>(
    ({ className, ...props }, ref) => (
      <RPNInput.default
        ref={ref}
        placeholder={"Enter a phone number"}
        className={cn("flex", className)}
        flagComponent={FlagComponent}
        countrySelectComponent={CountrySelect}
        inputComponent={InputComponent}
        {...props}
      />
    ),
  );

PhoneInput.displayName = "PhoneInput";

const InputComponent = React.forwardRef<HTMLInputElement, InputProps>(
  ({ className, ...props }, ref) => (
    <Input
      className={cn("rounded-s-none rounded-e-lg", className)}
      {...props}
      ref={ref}
    />
  ),
);

type CountrySelectOption = { label: string; value: RPNInput.Country };

type CountrySelectProps = {
  disabled?: boolean;
  value: RPNInput.Country;
  onChange: (value: RPNInput.Country) => void;
  options: CountrySelectOption[];
};

const CountrySelect = ({ disabled, value }: CountrySelectProps) => {
  return (
    <Popover>
      <Button
        type="button"
        variant={"outline"}
        className={cn("rounded-e-none rounded-s-lg pl-3 pr-3 flex gap-1")}
        disabled={disabled}
      >
        <span className="flex items-center truncate">
          <div className="bg-foreground/20 rounded-sm flex w-6 h-4">
            {value && <FlagComponent country={value} countryName={value} />}
          </div>
        </span>
      </Button>
    </Popover>
  );
};

const FlagComponent = ({ country, countryName }: RPNInput.FlagProps) => {
  const Flag = flags[country];

  return (
    <span
      className={"inline object-contain w-6 h-4 overflow-hidden rounded-sm"}
    >
      {Flag && <Flag title={countryName} />}
    </span>
  );
};

export { PhoneInput };
