import { CardContent, Card } from "@/components/ui/card";
import { Label } from "@/components/ui/label";
import { ButtonCustomLink } from "@/components";

type SubscriptionDetailsProps = {
  name: string;
  frecuency: string;
  deliveryDay: string;
  nextDelivery: string;
};

const SubscriptionDetails = ({
  name,
  frecuency,
  deliveryDay,
  nextDelivery,
}: SubscriptionDetailsProps) => {
  return (
    <div>
      <Label className="mb-2 block">Tu suscripción</Label>
      <Card className="border rounded-lg p-2">
        <CardContent className="grid gap-4 p-2">
          <div className="flex items-center gap-4">
            <BoxIcon className="w-6 h-6 text-muted-foreground" />
            <div>
              <p className="text-sm font-medium">Tipo</p>
              <p className="text-sm text-muted-foreground">{name}</p>
            </div>
          </div>
          <div className="flex items-center gap-4">
            <CalendarIcon className="w-6 h-6 text-muted-foreground" />
            <div>
              <p className="text-sm font-medium">Frecuencia envío</p>
              <p className="text-sm text-muted-foreground">{frecuency}</p>
            </div>
          </div>
          <div className="flex items-center gap-4">
            <ClockIcon className="w-6 h-6 text-muted-foreground" />
            <div>
              <p className="text-sm font-medium">Día de envío</p>
              <p className="text-sm text-muted-foreground">
                Cada {deliveryDay}
              </p>
            </div>
          </div>
          <div className="flex items-center gap-4">
            <TruckIcon className="w-6 h-6 text-muted-foreground" />
            <div>
              <p className="text-sm font-medium">Siguiente envío</p>
              <p className="text-sm text-muted-foreground">{nextDelivery}</p>
            </div>
          </div>
          <div className="flex flex-col space-y-2">
            <ButtonCustomLink variant="outline" to="/user/subscription/edit">
              📝 Editar suscripción
            </ButtonCustomLink>
          </div>
        </CardContent>
      </Card>
    </div>
  );
};

export default SubscriptionDetails;

function BoxIcon(props: React.HTMLAttributes<SVGSVGElement>) {
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <path d="M21 8a2 2 0 0 0-1-1.73l-7-4a2 2 0 0 0-2 0l-7 4A2 2 0 0 0 3 8v8a2 2 0 0 0 1 1.73l7 4a2 2 0 0 0 2 0l7-4A2 2 0 0 0 21 16Z" />
      <path d="m3.3 7 8.7 5 8.7-5" />
      <path d="M12 22V12" />
    </svg>
  );
}

function CalendarIcon(props: React.HTMLAttributes<SVGSVGElement>) {
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <rect width="18" height="18" x="3" y="4" rx="2" ry="2" />
      <line x1="16" x2="16" y1="2" y2="6" />
      <line x1="8" x2="8" y1="2" y2="6" />
      <line x1="3" x2="21" y1="10" y2="10" />
    </svg>
  );
}

function ClockIcon(props: React.HTMLAttributes<SVGSVGElement>) {
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <circle cx="12" cy="12" r="10" />
      <polyline points="12 6 12 12 16 14" />
    </svg>
  );
}

function TruckIcon(props: React.HTMLAttributes<SVGSVGElement>) {
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <path d="M5 18H3c-.6 0-1-.4-1-1V7c0-.6.4-1 1-1h10c.6 0 1 .4 1 1v11" />
      <path d="M14 9h4l4 4v4c0 .6-.4 1-1 1h-2" />
      <circle cx="7" cy="18" r="2" />
      <path d="M15 18H9" />
      <circle cx="17" cy="18" r="2" />
    </svg>
  );
}
