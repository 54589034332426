import { CardContent, Card } from "@/components/ui/card";
import { ImportantMessage } from "@/components";

const PausedSubscription = () => {
  return (
    <Card className="border rounded-lg p-2">
      <CardContent className="grid p-2 space-y-2">
        <div className="space-y-2">
          <ImportantMessage
            title={null}
            message={
              <div>
                <p className="text-muted-foreground">
                  Tu suscripción está pausada y no se va a realizar ningún
                  cobro.
                </p>
                <p className="text-muted-foreground">
                  Si quieres activar tu suscripción{" "}
                  <a href="https://wa.me/56971295665" className="underline">
                    comunicate con nosotros para ayudarte.
                  </a>
                </p>
              </div>
            }
          />
        </div>
      </CardContent>
    </Card>
  );
};

export default PausedSubscription;
