import { useEffect } from "react";

const NotFound = () => {
  useEffect(() => {
    window.location.replace(
      `http://tienda.maifud.cl${window.location.pathname}`,
    );
  }, []);

  return null;
};

export default NotFound;
