import { Button, ButtonProps } from "@/components/ui/button";
import { CustomLink } from "@/components";
import { LinkProps } from "@tanstack/react-router";
import { cn } from "@/lib/utils";

interface ButtonCustomLinkProps extends LinkProps {
  variant?: ButtonProps["variant"];
  className?: string;
}

const ButtonCustomLink = ({
  to,
  variant = "default",
  className,
  children,
  ...props
}: ButtonCustomLinkProps) => {
  return (
    <Button variant={variant} className={cn("p-0", className)}>
      <CustomLink to={to} className="px-4 py-2 block w-full" {...props}>
        {children}
      </CustomLink>
    </Button>
  );
};

export default ButtonCustomLink;
