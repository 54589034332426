import HomeDeliveryForm from "./HomeDelivery";
import PickupDeliveryForm from "./PickupDelivery";
import { RadioGroup, RadioGroupItem } from "@/components/ui/radio-group";
import { Label } from "@/components/ui/label";
import { cn } from "@/lib/utils";
import { useState } from "react";
import type { UserShippingDataInput, ShippingType } from "@/services/types";
import type { HomeDeliveryData } from "./HomeDelivery";
import type { PickUpDeliveryData } from "./PickupDelivery";

type ShippingFormProps = {
  initialData: UserShippingDataInput;
  handleSubmitHomeDeliveryForm: (
    values: HomeDeliveryData,
    shippingCost: string,
  ) => void;
  handleSubmitPickUpForm: (
    values: PickUpDeliveryData,
    shippingCost: string,
  ) => void;
  isLoading?: boolean;
  isError?: boolean;
  error?: any;
  isSuccess?: boolean;
  onGoBack?: () => void;
};

const ShippingForm = ({
  initialData,
  handleSubmitHomeDeliveryForm,
  handleSubmitPickUpForm,
  isLoading,
  isError,
  onGoBack,
  error,
}: ShippingFormProps) => {
  const [shippingMethod, setShippingMethod] = useState(
    initialData.selected_shipping_type,
  );

  const renderShippingForm = () => {
    if (!shippingMethod) return null;
    return (
      <div>
        {shippingMethod === "DELIVERY" && (
          <HomeDeliveryForm
            onSubmit={handleSubmitHomeDeliveryForm}
            initialData={initialData}
            isLoading={isLoading}
            isError={isError}
            error={error}
            onGoBack={onGoBack}
          />
        )}
        {shippingMethod === "PICKUP" && (
          <PickupDeliveryForm
            onSubmit={handleSubmitPickUpForm}
            initialData={initialData}
            isLoading={isLoading}
            isError={isError}
            error={error}
            onGoBack={onGoBack}
          />
        )}
      </div>
    );
  };
  return (
    <div className="flex flex-col space-y-4">
      <div className="flex flex-col space-y-2">
        <RadioGroup
          defaultValue={shippingMethod}
          onValueChange={(value) => setShippingMethod(value as ShippingType)}
          className="grid grid-cols-2 gap-4"
          disabled={isLoading}
        >
          <div>
            <RadioGroupItem
              value="DELIVERY"
              id="DELIVERY"
              className="peer sr-only"
            />
            <Label
              htmlFor="DELIVERY"
              className={cn(
                "flex flex-col items-center justify-between rounded-md border-2 border-muted bg-popover p-4 hover:bg-orange-100/50 peer-data-[state=checked]:border-primary [&:has([data-state=checked])]:border-primary",
                shippingMethod == "DELIVERY" && "bg-orange-100/50",
              )}
            >
              <div className="w-full pb-2 text-center">🚚</div>
              <p className="text-center">Entrega a domicilio</p>
            </Label>
          </div>
          <div>
            <RadioGroupItem
              value="PICKUP"
              id="PICKUP"
              className="peer sr-only"
            />
            <Label
              htmlFor="PICKUP"
              className={cn(
                "flex flex-col items-center justify-between rounded-md border-2 border-muted bg-popover p-4 hover:bg-orange-100/50 peer-data-[state=checked]:border-primary [&:has([data-state=checked])]:border-primary",
                shippingMethod == "PICKUP" && "bg-orange-100/50",
              )}
            >
              <div className="w-full pb-2 text-center">🏬</div>
              <p className="text-center">Punto de retiro</p>
            </Label>
          </div>
        </RadioGroup>
      </div>
      {renderShippingForm()}
    </div>
  );
};

export default ShippingForm;
