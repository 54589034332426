import { Search as SearchIcon } from "lucide-react";
import { Input } from "@/components/ui/input";
import { Button } from "@/components/ui/button";
import * as z from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormMessage,
} from "@/components/ui/form";
import { useEffect } from "react";

const searchSchema = z.object({
  search: z.string({
    required_error: "¿Que deseas buscar?",
  }),
});

type SearchSchema = z.infer<typeof searchSchema>;
type SearchProps = {
  initialValue: string;
  onSubmit: (values: SearchSchema) => void;
};

const Search = ({ onSubmit, initialValue }: SearchProps) => {
  const form = useForm<SearchSchema>({
    resolver: zodResolver(searchSchema),
    defaultValues: {
      search: initialValue,
    },
  });

  useEffect(() => {
    if (initialValue === "") {
      form.reset();
    }
  }, [initialValue]);

  return (
    <div>
      <Form {...form}>
        <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-8">
          <div className="relative">
            <SearchIcon className="absolute left-2 top-2.5 h-4 w-4 text-muted-foreground" />
            <FormField
              control={form.control}
              name="search"
              render={({ field }) => (
                <FormItem>
                  <FormControl>
                    <Input
                      placeholder="Buscar productos"
                      className="pl-8"
                      onChange={field.onChange}
                      value={field.value}
                      autoFocus
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <Button type="submit" className="absolute right-0 top-0">
              Buscar
            </Button>
          </div>
        </form>
      </Form>
    </div>
  );
};

export default Search;
