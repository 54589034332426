import { es } from "date-fns/locale";
import { ImportantMessage } from "@/components";
import { Calendar } from "@/components/ui/calendar";
import {
  formatDayOfTheWeek,
  formatDefault,
  generateDatesBasedOnFrecuency,
} from "@/utils/dates";
import { FrecuencyType } from "@/services/types";

type FutureDaysCalendarProps = {
  frecuency: string;
  delivery_date: Date;
};

const FutureDaysCalendar = ({
  frecuency,
  delivery_date,
}: FutureDaysCalendarProps) => {
  let transformedFrecuency: FrecuencyType = "WEEKLY";
  if (frecuency === "quincenal") {
    transformedFrecuency = "BIWEEKLY";
  }
  const dates = generateDatesBasedOnFrecuency(
    delivery_date,
    transformedFrecuency,
  );

  return (
    <ImportantMessage
      title={
        <p className="text-base">
          <span>🔔</span> Así vas a recibir tus cajas
        </p>
      }
      message={
        <>
          <p className="text-muted-foreground">
            - Tu primera entrega será el dia{" "}
            <strong className="text-primary">
              {formatDefault(delivery_date)}
            </strong>{" "}
            y luego{" "}
            {
              {
                semanal: " cada semana",
                quincenal: " cada 14 días",
              }[frecuency]
            }
            , los días{" "}
            <strong className="text-primary">
              {formatDayOfTheWeek(delivery_date)}
            </strong>
            .
          </p>
          <p className="text-muted-foreground">
            - Podrás pausar o cancelar tu suscripción en cualquier momento.
          </p>
          <div className="flex flex-col items-center justify-center py-4">
            <Calendar
              mode="multiple"
              className="bg-white rounded-md font-sans"
              ISOWeek
              selected={dates}
              disabled={(date) => !dates.includes(date)}
              fromDate={delivery_date}
              toDate={dates.at(-1)}
              locale={es}
            />
            <p className="pt-2 text-center">
              Este es un ejemplo de cuando serian tus próximos 4 envíos
            </p>
          </div>
        </>
      }
    />
  );
};

export default FutureDaysCalendar;
