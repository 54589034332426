import { CardContent, Card } from "@/components/ui/card";
import { Label } from "@/components/ui/label";
import { ButtonCustomLink } from "@/components";

type SubscriptionShippingProps = {
  type: "DELIVERY" | "PICKUP";
  deliveryAddress?: string;
  deliveryDistrict?: string;
  pickupAddress?: string;
  pickupName?: string;
  pickupDistrict?: string;
};

const SubscriptionShipping = ({
  type,
  deliveryAddress,
  deliveryDistrict,
  pickupAddress,
  pickupName,
  pickupDistrict,
}: SubscriptionShippingProps) => {
  return (
    <div>
      <Label className="mb-2 block">Tu método de envío</Label>
      <Card className="border rounded-lg p-2">
        <CardContent className="grid gap-4 p-2">
          <div className="flex items-center gap-4">
            <LocateIcon className="w-6 h-6 text-muted-foreground" />
            <div>
              <p className="text-sm font-medium">
                {type === "DELIVERY"
                  ? "Entrega a domicilio"
                  : "Retiro en tiendas"}
              </p>
              <p className="flex flex-col text-sm text-muted-foreground">
                {type === "DELIVERY" && (
                  <>
                    <span>{deliveryAddress}</span>
                    <span>{deliveryDistrict}</span>
                  </>
                )}
                {type === "PICKUP" && (
                  <>
                    <span>{pickupName}</span>
                    <span>{pickupAddress}</span>
                    <span>{pickupDistrict}</span>
                  </>
                )}
              </p>
            </div>
          </div>
          <div className="flex flex-col">
            <ButtonCustomLink to="/user/shipping/edit" variant="outline">
              📝 Editar método de envío
            </ButtonCustomLink>
          </div>
        </CardContent>
      </Card>
    </div>
  );
};

export default SubscriptionShipping;

function LocateIcon(props: React.HTMLAttributes<SVGSVGElement>) {
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <line x1="2" x2="5" y1="12" y2="12" />
      <line x1="19" x2="22" y1="12" y2="12" />
      <line x1="12" x2="12" y1="2" y2="5" />
      <line x1="12" x2="12" y1="19" y2="22" />
      <circle cx="12" cy="12" r="7" />
    </svg>
  );
}
