import { useState } from "react";
import { useNavigate } from "@tanstack/react-router";
import { PageLayout, PickUpStoreForm, HomeDeliveryForm } from "@/components";
import { RadioGroup, RadioGroupItem } from "@/components/ui/radio-group";
import { Label } from "@/components/ui/label";
import deliveryIcon from "@/assets/home_delivery.png";
import pickUpIcon from "@/assets/pickup_delivery.png";
import { cn } from "@/lib/utils";
import { useRegister } from "@/context/register";
import type { PickUpFormSchema } from "@/components/PickUpStoreForm/PickUpStoreForm";
import type { HomeDeliveryFormSchema } from "@/components/HomeDeliveryForm/HomeDeliveryForm";
import { useScrollToTop } from "@/utils/hooks";

type ShippingMethod = "DELIVERY" | "PICKUP";

const ShippingMethod = () => {
  useScrollToTop();

  const { registryData, setRegistryData } = useRegister();

  const navigate = useNavigate();

  if (registryData === null) {
    navigate({ to: "/register/subscriptions" });
    return null;
  }

  const initialShippingMethod = registryData
    ? registryData.shipping_type
    : undefined;
  const [shippingMethod, setShippingMethod] = useState<
    ShippingMethod | undefined
  >(initialShippingMethod);

  const handleShippingMethodSelection = (value: ShippingMethod) => {
    setShippingMethod(value);
    setRegistryData({
      ...registryData,
      shipping_type: value,
    });
  };

  const handleSubmitPickUpForm = ({
    pickup_name,
    pickup_district,
  }: PickUpFormSchema) => {
    setRegistryData({
      ...registryData,
      pickup_name,
      shipping_type: shippingMethod,
      pickup_district,
      // Clean up home delivery data
      address: undefined,
      address_dpto: undefined,
      aditional_notes: undefined,
    });
    goToNextPage();
  };

  const handleSubmitHomeDeliveryForm = ({
    address,
    address_dpto,
    delivery_district,
    aditional_notes,
  }: HomeDeliveryFormSchema) => {
    setRegistryData({
      ...registryData,
      address,
      address_dpto,
      delivery_district,
      aditional_notes,
      shipping_type: shippingMethod,
      // Clean up pickup data
      pickup_name: undefined,
      pickup_district: undefined,
    });
    goToNextPage();
  };

  const goToNextPage = () => {
    navigate({ to: "/register/shipping-frecuency" });
  };

  const renderShippingForm = () => {
    if (!shippingMethod) return null;
    return (
      <div>
        {shippingMethod === "DELIVERY" && (
          <HomeDeliveryForm
            onSubmit={handleSubmitHomeDeliveryForm}
            initialData={registryData}
          />
        )}
        {shippingMethod === "PICKUP" && (
          <PickUpStoreForm
            onSubmit={handleSubmitPickUpForm}
            initialData={registryData}
          />
        )}
      </div>
    );
  };

  return (
    <PageLayout
      title="Escoge tu método de entrega"
      description="De esta forma enviaremos tus cajas"
      progress={40}
    >
      <div className="flex flex-col space-y-4">
        <div className="flex flex-col space-y-2">
          <Label>
            ¿Prefieres que llevemos la caja a tu casa o ir a recogerla a punto
            de retiro?
          </Label>
          <RadioGroup
            defaultValue={shippingMethod}
            onValueChange={handleShippingMethodSelection}
            className="grid grid-cols-2 gap-4"
          >
            <div>
              <RadioGroupItem
                value="DELIVERY"
                id="DELIVERY"
                className="peer sr-only"
              />
              <Label
                htmlFor="DELIVERY"
                className={cn(
                  "flex flex-col items-center justify-between rounded-md border-2 border-muted bg-popover p-4 hover:bg-orange-100/50 peer-data-[state=checked]:border-primary [&:has([data-state=checked])]:border-primary",
                  shippingMethod == "DELIVERY" && "bg-orange-100/50",
                )}
              >
                <div className="w-full  max-w-28 pb-2">
                  <img src={deliveryIcon} alt="Icono de entrega a domicilio" />
                </div>
                <p className="text-center">Entrega a domicilio</p>
              </Label>
            </div>
            <div>
              <RadioGroupItem
                value="PICKUP"
                id="PICKUP"
                className="peer sr-only"
              />
              <Label
                htmlFor="PICKUP"
                className={cn(
                  "flex flex-col items-center justify-between rounded-md border-2 border-muted bg-popover p-4 hover:bg-orange-100/50 peer-data-[state=checked]:border-primary [&:has([data-state=checked])]:border-primary",
                  shippingMethod == "PICKUP" && "bg-orange-100/50",
                )}
              >
                <div className="w-full  max-w-28 pb-2">
                  <img src={pickUpIcon} alt="Icono de punto de retiro" />
                </div>
                <p className="text-center">Punto de retiro</p>
              </Label>
            </div>
          </RadioGroup>
        </div>

        {renderShippingForm()}
      </div>
    </PageLayout>
  );
};

export default ShippingMethod;
