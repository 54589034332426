import {
  AlertDialog,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
} from "@/components/ui/alert-dialog";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/components/ui/table";

import { DBOrder, SummaryBoxItem, SummaryUpsellItem } from "@/services/types";
import { formatDefault } from "@/utils/dates";
import Loading from "@/components/Loading/Loading";
import Product from "@/components/ProductTable/ProductTable";
import { ScrollArea } from "@/components/ui/scroll-area";

type OrderProductsAlertProps = {
  order: DBOrder;
  userInput?: string;
  products?: SummaryBoxItem[];
  upsell?: SummaryUpsellItem[];
  onClose: () => void;
  isLoading?: boolean;
};

const OrderProductsAlert = ({
  order,
  userInput,
  products,
  upsell,
  onClose,
  isLoading,
}: OrderProductsAlertProps) => {
  // TODO: Add isError condition on request
  if (isLoading) {
    return (
      <AlertDialog open>
        <AlertDialogContent>
          <AlertDialogDescription>
            <Loading message="Buscando información de tu orden..." />
          </AlertDialogDescription>
        </AlertDialogContent>
      </AlertDialog>
    );
  }

  const productsToShow = products?.filter((p) => p.quantity > 0);

  return (
    <AlertDialog open>
      <AlertDialogContent>
        <AlertDialogHeader>
          <AlertDialogTitle>
            Tu{" "}
            <strong className="text-primary font-semibold">
              {order.subscription_name}
            </strong>{" "}
            del{" "}
            <strong className="text-primary font-semibold">
              {formatDefault(order.delivery_date)}
            </strong>
          </AlertDialogTitle>
          <AlertDialogDescription>
            <ScrollArea className="h-[18rem]">
              {userInput && (
                <div className="mb-4">
                  <p className="font-semibold">Tus instrucciones:</p>
                  <p className="text-sm">"{userInput}"</p>
                </div>
              )}
              <p className="font-semibold">Contenido de tu caja:</p>
              <div className="border rounded-md shadow-sm">
                <Table className="">
                  <TableHeader className="">
                    <TableRow className="text-sm bg-primary/5">
                      <TableHead className="w-[70px] font-semibold text-black">
                        Cantidad
                      </TableHead>
                      <TableHead className="font-semibold text-black">
                        Producto
                      </TableHead>
                      <TableHead className="text-end">
                        <span className="text-primary font-sans">
                          {productsToShow?.length}
                        </span>{" "}
                        productos
                      </TableHead>
                    </TableRow>
                  </TableHeader>
                  <TableBody className="text-left text-xs">
                    {productsToShow?.map((p) => (
                      <Product
                        status="CLEAN"
                        editMode={false}
                        key={p.full_product_name}
                        name={p.full_product_name}
                        quantity={p.quantity}
                        unitType={p.quantity_unit_type}
                        onDelete={() => {}}
                        onDecrease={() => {}}
                        onIncrease={() => {}}
                        onReset={() => {}}
                      />
                    ))}
                  </TableBody>
                </Table>
              </div>
              {upsell && upsell.length > 0 && (
                <>
                  <div className="mt-4 pt-2">
                    <p className="font-semibold text-left">
                      Productos adicionales comprados:
                    </p>
                    <div className="border rounded-md shadow-sm">
                      <Table className="">
                        <TableHeader className="">
                          <TableRow className="text-sm bg-primary/5">
                            <TableHead className="font-semibold text-black">
                              Producto
                            </TableHead>
                            <TableHead className="text-end">
                              <span className="text-primary font-sans">
                                {upsell?.length}
                              </span>{" "}
                              productos
                            </TableHead>
                          </TableRow>
                        </TableHeader>
                        <TableBody className="text-left text-xs">
                          {upsell?.map((p) => (
                            <TableRow key={p.full_product_name}>
                              <TableCell className="text-sm">
                                <span className="lowercase">
                                  {p.quantity}{" "}
                                  <span className="font-semibold text-primary text-[11px]">
                                    x
                                  </span>{" "}
                                  {p.full_product_name}
                                </span>
                              </TableCell>
                              <TableCell></TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </div>
                  </div>
                </>
              )}
            </ScrollArea>
          </AlertDialogDescription>
        </AlertDialogHeader>
        <AlertDialogFooter>
          <AlertDialogCancel onClick={onClose}>Cerrar</AlertDialogCancel>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  );
};

export default OrderProductsAlert;
