import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import * as z from "zod";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
  FormDescription,
} from "@/components/ui/form";
import { Input } from "@/components/ui/input";
import { Textarea } from "@/components/ui/textarea";
import { Button } from "@/components/ui/button";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import { ButtonCustomLink, Loading, RetryErrorMessage } from "@/components";
import { addressDataQuery } from "@/services/queries";
import type { UserShippingDataInput } from "@/services/types";
import { useState } from "react";

const homedeliveryFromSchema = z.object({
  selected_delivery_address: z.string({
    required_error: "⚠️ Necesitamos tu dirección para enviar tus cajas",
  }),
  selected_delivery_address_dpto: z.string().optional(),
  selected_delivery_district_name: z.string({
    required_error: "⚠️ Necesitamos tu comuna para enviar tus cajas",
  }),
  selected_delivery_additional_data: z.string().optional(),
});

export type HomeDeliveryFormSchema = z.infer<typeof homedeliveryFromSchema>;

export interface HomeDeliveryData extends HomeDeliveryFormSchema {
  selected_shipping_type_humanized: string;
  selected_delivery_address_number: string;
}

type HomeDeliveryFormProps = {
  initialData: UserShippingDataInput;
  onSubmit: (data: HomeDeliveryData, shippingCost: string) => void;
  isLoading?: boolean;
  isError?: boolean;
  error?: any;
  onGoBack?: () => void;
};

const HomeDeliveryForm = ({
  onSubmit,
  initialData,
  isLoading,
  isError,
  error,
  onGoBack,
}: HomeDeliveryFormProps) => {
  const [shippingCost, setShippingCost] = useState<string>();

  const {
    selected_delivery_address,
    selected_delivery_address_dpto,
    selected_delivery_district_name,
    selected_delivery_additional_data,
    selected_delivery_address_number,
  } = initialData;

  const form = useForm<HomeDeliveryFormSchema>({
    resolver: zodResolver(homedeliveryFromSchema),
    defaultValues: {
      selected_delivery_address:
        selected_delivery_address !== ""
          ? selected_delivery_address
          : undefined,
      selected_delivery_address_dpto:
        selected_delivery_address_dpto || selected_delivery_address_number,
      selected_delivery_district_name:
        selected_delivery_district_name !== ""
          ? selected_delivery_district_name
          : undefined,
      selected_delivery_additional_data:
        selected_delivery_additional_data !== ""
          ? selected_delivery_additional_data
          : undefined,
    },
  });

  const {
    data: response,
    isLoading: responseIsLoading,
    isError: responseIsError,
    error: responseError,
    refetch: responseRefetch,
  } = addressDataQuery();
  const data = response?.data;

  function handleSubmit(values: HomeDeliveryFormSchema) {
    onSubmit(
      {
        ...values,
        selected_shipping_type_humanized: "Entrega a domicilio",
        selected_delivery_address_number: "",
      },
      shippingCost || "",
    );
  }

  if (responseIsLoading) {
    return <Loading message="🔍 Buscando información de las comunas..." />;
  }

  if (responseIsError) {
    return (
      <RetryErrorMessage
        title={"No se pudo obtener la información de las comunas."}
        message={responseError.message}
        retry={responseRefetch}
        error={responseError}
      />
    );
  }

  if (data) {
    const { district_names } = data;
    return (
      <Form {...form}>
        <form onSubmit={form.handleSubmit(handleSubmit)} className="space-y-6">
          <FormField
            control={form.control}
            name="selected_delivery_district_name"
            render={({ field }) => {
              const selectedDistrict = field.value
                ? data.districts.find((d) => d.name == field.value)
                : undefined;
              if (selectedDistrict) {
                setShippingCost(String(selectedDistrict.delivery_price));
              }
              return (
                <FormItem>
                  <FormLabel>Comuna</FormLabel>
                  <Select
                    onValueChange={(value) => {
                      field.onChange(value);
                    }}
                    defaultValue={field.value}
                    disabled={isLoading}
                  >
                    <FormControl>
                      <SelectTrigger>
                        <SelectValue placeholder="Selecciona una comuna" />
                      </SelectTrigger>
                    </FormControl>
                    <SelectContent>
                      {[...new Set(district_names)].map((d) => (
                        <SelectItem key={d} value={d}>
                          {d}
                        </SelectItem>
                      ))}
                    </SelectContent>
                  </Select>
                  {selectedDistrict && (
                    <>
                      <FormDescription>
                        Costo de envío: {selectedDistrict.delivery_price}
                      </FormDescription>
                      <FormDescription>
                        Días de envío:{" "}
                        {selectedDistrict.humanized_shipping_days}
                      </FormDescription>
                    </>
                  )}
                  <FormMessage />
                </FormItem>
              );
            }}
          />
          <FormField
            control={form.control}
            name="selected_delivery_address"
            render={({ field }) => (
              <FormItem>
                <FormLabel>Dirección</FormLabel>
                <FormControl>
                  <Input
                    autoComplete="address"
                    onChange={(e) => {
                      field.onChange(e);
                    }}
                    value={field.value}
                    disabled={isLoading}
                  />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="selected_delivery_address_dpto"
            render={({ field }) => (
              <FormItem>
                <FormLabel>Número depto / Casa</FormLabel>
                <FormControl>
                  <Input
                    onChange={(e) => {
                      field.onChange(e);
                    }}
                    value={field.value}
                    maxLength={80}
                    disabled={isLoading}
                  />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />

          <FormField
            control={form.control}
            name="selected_delivery_additional_data"
            render={({ field }) => (
              <FormItem>
                <FormLabel>Información adicional</FormLabel>
                <FormControl>
                  <Textarea
                    onChange={(e) => {
                      field.onChange(e);
                    }}
                    value={field.value}
                    className="text-base md:text-sm"
                    disabled={isLoading}
                  />
                </FormControl>
                <FormDescription>
                  ¿Alguna información extra que nos ayude a entregar tu pedido
                  más facil y rápido?
                </FormDescription>
                <FormMessage />
              </FormItem>
            )}
          />
          {isError && (
            <FormMessage className="text-right">
              {error?.message || "Hubo un error inesperado"}
            </FormMessage>
          )}
          <div className="flex justify-between pb-4 gap-4">
            {onGoBack ? (
              <Button
                type="button"
                variant="outline"
                className="w-full sm:w-fit"
                disabled={isLoading}
                onClick={onGoBack}
              >
                Volver
              </Button>
            ) : (
              <ButtonCustomLink to="/user/subscription" variant="outline">
                Volver
              </ButtonCustomLink>
            )}
            {initialData.selected_shipping_type !== "DELIVERY" ? (
              <Button
                type="submit"
                className="w-full sm:w-fit"
                disabled={isLoading}
              >
                {isLoading ? "Cambiando" : "Usar este método"}
              </Button>
            ) : (
              <Button
                type="submit"
                className="w-full sm:w-fit"
                disabled={isLoading}
              >
                {isLoading ? "Guardando" : "Guardar"}
              </Button>
            )}
          </div>
        </form>
      </Form>
    );
  }
};

export default HomeDeliveryForm;
