import * as z from "zod";
import { Link, useNavigate } from "@tanstack/react-router";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import {
  Card,
  CardHeader,
  CardTitle,
  CardDescription,
  CardContent,
  CardFooter,
} from "@/components/ui/card";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form";
import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import { login } from "@/firebase";
import { useState } from "react";
import { getErrorMessage } from "./utils";
import { useScrollToTop } from "@/utils/hooks";

const loginSchema = z.object({
  email: z
    .string({
      required_error: "El correo es requerido",
    })
    .email("Este correo no es válido"),
  password: z.string().min(1, "La contraseña es requerida"),
});

type LoginSchema = z.infer<typeof loginSchema>;

const Login = () => {
  useScrollToTop();
  const [error, setError] = useState();
  const [isLoading, setIsLoading] = useState(false);

  const navigate = useNavigate();

  const form = useForm<LoginSchema>({
    resolver: zodResolver(loginSchema),
    defaultValues: {
      email: "",
      password: "",
    },
  });
  // TODO: Replace this call with a query from services
  const onSubmit = (values: LoginSchema) => {
    const { email, password } = values;
    setError(undefined);
    setIsLoading(true);
    login(email, password)
      .then(() => {
        navigate({
          to: "/user/subscription",
        });
      })
      .catch((err) => setError(err))
      .finally(() => {
        setIsLoading(false);
      });
  };

  return (
    <div className="mx-auto max-w-[400px] w-full mt-6 mb-24">
      <Card>
        <CardHeader className="space-y-1">
          <CardTitle className="text-2xl">Bienvenido</CardTitle>
          <CardDescription>
            Ingresa tus datos para iniciar sesión
          </CardDescription>
        </CardHeader>
        <CardContent>
          <Form {...form}>
            <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-4">
              <FormField
                control={form.control}
                name="email"
                render={({ field }) => {
                  return (
                    <FormItem>
                      <FormLabel htmlFor="email">Correo</FormLabel>
                      <FormControl>
                        <Input
                          id="email"
                          type="email"
                          placeholder="Ingresa tu correo"
                          autoComplete="email"
                          value={field.value}
                          onChange={field.onChange}
                        />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  );
                }}
              />
              <FormField
                control={form.control}
                name="password"
                render={({ field }) => {
                  return (
                    <FormItem>
                      <FormLabel htmlFor="password">Contraseña</FormLabel>
                      <Input
                        id="password"
                        type="password"
                        placeholder="Ingresa tu contraseña"
                        autoComplete="password"
                        value={field.value}
                        onChange={field.onChange}
                      />
                      <FormMessage />
                    </FormItem>
                  );
                }}
              />
              <Button
                className="w-full"
                type="submit"
                disabled={isLoading}
                isLoading={isLoading}
              >
                {isLoading ? "Entrando" : "Entrar"}
              </Button>
              {error && (
                <FormMessage className="text-center">
                  {getErrorMessage((error as Error).message)}
                </FormMessage>
              )}
            </form>
          </Form>
        </CardContent>
        <CardFooter>
          <div className="text-sm text-muted-foreground flex flex-col justify-center items-center space-y-4 w-full">
            <Link
              className="underline underline-offset-2 hover:text-primary"
              to="/forgot-password"
            >
              ¿Olvidaste tu contraseña?
            </Link>
            <Link
              className="underline underline-offset-2 hover:text-primary"
              to="/register/subscriptions"
            >
              ¿No tienes cuenta? Crea una aquí
            </Link>
          </div>
        </CardFooter>
      </Card>
    </div>
  );
};

export default Login;
