import { Separator } from "@/components/ui/separator";
import ShippingEditComponent from "@/serverComponents/ShippingEdit";

const ShippingEdit = () => {
  return (
    <div className="space-y-4">
      <div>
        <h3 className="text-lg font-medium">Editar envío / punto de retiro</h3>
        <p className="text-sm text-muted-foreground">
          Puedes cambiar a donde quieres que enviemos tus cajas
        </p>
      </div>
      <Separator />
      <ShippingEditComponent />
    </div>
  );
};

export default ShippingEdit;
