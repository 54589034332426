import maifudLogo from "@/assets/maifud_logo.png";
import { cn } from "@/lib/utils";
import { version } from "@/../package.json";
import corfoLogo from "@/assets/corfo_logo.png";
import { Separator } from "@/components/ui/separator";
import {
  InstagramIcon,
  FacebookIcon,
  LinkedinIcon,
  MessageSquareText,
} from "lucide-react";
import { config } from "@/config";

type FooterProps = {
  variant?: "small" | "big";
};

const Footer = ({ variant = "small" }: FooterProps) => {
  const renderLogoSection = () => {
    return (
      <div className="flex flex-col items-center md:items-start space-y-6">
        <div className="flex justify-between w-full items-center md:flex-col md:justify-center md:items-start">
          <div>
            <div className="flex flex-row space-x-1 items-baseline">
              <img
                src={maifudLogo}
                alt="Logo de Maifud"
                className={cn(
                  "opacity-80",
                  variant === "big" && "max-w-44",
                  variant === "small" && "max-w-24",
                )}
              />
              <small className="text-[10px] text-muted-foreground">
                v{version}
              </small>
            </div>
          </div>
          <div>
            <p className="text-xs text-muted-foreground">Con el apoyo de</p>
            <div className="flex items-center -translate-x-2">
              <a href="https://corfo.cl/sites/cpp/homecorfo" target="_blank">
                <img className="w-28" src={corfoLogo} alt="Logo de Corfo" />
              </a>
            </div>
          </div>
        </div>
        <div className="flex justify-start w-full">
          <ul className="space-y-1">
            <li>
              <a href="mailto:contacto@maifud.cl">contacto@maifud.cl</a>
            </li>
            <li className="font-sans flex items-center gap-1">
              <MessageSquareText color="#25D366" />
              <a href="https://wa.me/56971295665" target="_blank">
                +56971295665
              </a>
            </li>
          </ul>
        </div>
      </div>
    );
  };

  const renderSuscripcionSection = () => {
    return (
      <div>
        <h4 className="text-base mb-4 font-medium">Suscripción</h4>
        <ul className="text-sm space-y-2">
          <li>
            <a href={`${config.BASE_URL}/#boxes-section`}>Nuestras cajas</a>
          </li>
          <li>
            <a href={`${config.BASE_URL}/#how-it-works-section`}>
              ¿Como funciona?
            </a>
          </li>
          <li>
            <a href={`${config.BASE_URL}/#impact-section`}>Impacto ambiental</a>
          </li>
          <li>
            <a href={`${config.BASE_URL}/#maifud-lovers-section`}>
              MaifudLovers
            </a>
          </li>
          <li>
            <a href={`${config.BASE_URL}/#faq-section`}>Preguntas frecuentes</a>
          </li>
        </ul>
      </div>
    );
  };

  const renderMaifudSection = () => {
    return (
      <div>
        <h4 className="text-base mb-4 font-medium">Más sobre Maifud</h4>
        <ul className="text-sm space-y-2">
          <li>
            <a href={"https://tienda.maifud.cl/el-problema"}>El problema</a>
          </li>
          <li>
            <a href={"https://tienda.maifud.cl/nosotros"}>Nosotros</a>
          </li>
          <li>
            <a href={"https://tienda.maifud.cl/despacho"}>Despachos</a>
          </li>
          <li>
            <a href={"https://tienda.maifud.cl/blog"}>Blog</a>
          </li>
          <li>
            <a href={"https://tienda.maifud.cl/productos"}>Tienda granel</a> 🍊
          </li>
        </ul>
      </div>
    );
  };

  return (
    <footer className="max-w-5xl mx-auto pb-4 py-2 pt-0 text-sm text-black">
      <Separator className="bg-orange-500/30 mb-6" />
      <div className="grid grid-cols-1 gap-6 md:grid-cols-2 md:gap-10 mb-6 px-4">
        <div>{renderLogoSection()}</div>
        <div className="flex justify-between md:justify-end gap-10">
          <div>{renderSuscripcionSection()}</div>
          <div>{renderMaifudSection()}</div>
        </div>
      </div>
      <Separator className="bg-orange-500/30 mb-6" />
      <div className="flex justify-between items-center px-4 pb-4">
        <ul className="flex flex-col md:flex-row md:space-x-2">
          <li className="md:border-r-2 md:border-r-orange-500/30 md:pr-2">
            © {new Date().getFullYear()} Maifud
          </li>
          <li className="md:border-r-2 md:border-r-orange-500/30 md:pr-2">
            <a href="/privacy-policy">Política de privacidad</a>
          </li>
          <li>
            <a href="/terms-and-conditions">Términos y condiciones</a>
          </li>
        </ul>
        <div className="flex flex-row space-x-2">
          <a
            href="https://www.instagram.com/maifud.cl"
            target="_blank"
            rel="noopener noreferrer"
          >
            <InstagramIcon className="text-muted-foreground" />
          </a>
          <a
            href="https://www.facebook.com/maifud"
            target="_blank"
            rel="noopener noreferrer"
          >
            <FacebookIcon className="text-muted-foreground" />
          </a>
          <a
            href="https://www.linkedin.com/company/maifud/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <LinkedinIcon className="text-muted-foreground" />
          </a>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
