import { useEffect, useState } from "react";
import * as Sentry from "@sentry/react";
import { FirebaseError, initializeApp } from "firebase/app";
import {
  getAuth,
  onAuthStateChanged,
  signInWithEmailAndPassword,
  signOut,
  createUserWithEmailAndPassword,
  sendPasswordResetEmail,
  deleteUser,
} from "firebase/auth";
import { getFirestore, setDoc, doc, getDoc } from "firebase/firestore";
import type { User } from "firebase/auth";
import { RegistryData } from "./context/register";
import {
  transformRegistryDataToFirebaseDoc,
  transformFirebaseDocToRegistryData,
} from "./utils/firebase";
import { config } from "@/config";

const firebaseConfig = {
  apiKey: "AIzaSyCPuZek1oPQRh37rBIRrkaWtjjnuv9MVJ8",
  authDomain: "maifud-dde84.firebaseapp.com",
  projectId: "maifud-dde84",
  storageBucket: "maifud-dde84.appspot.com",
  messagingSenderId: "260188908096",
  appId: "1:260188908096:web:233ad452236ea3f6520da5",
  measurementId: "G-7ML9B4BH53",
};

const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
export const db = getFirestore(app);

export const useUser = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [user, setUser] = useState<User | null>(null);

  useEffect(() => {
    onAuthStateChanged(
      auth,
      (user) => {
        setUser(user);
        setIsLoading(false);
      },
      () => {
        throw new Error("Couldn´t get user status from firebase");
      },
      () => {
        setIsLoading(false);
      },
    );
  }, []);

  return {
    user,
    isLoading,
  };
};

export const getUser: () => Promise<User | null> = () => {
  return new Promise((resolve) => {
    const auth = getAuth();
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      unsubscribe();
      resolve(user);
    });
  });
};

export const login = async (email: string, password: string) => {
  try {
    const userCredentials = await signInWithEmailAndPassword(
      auth,
      email,
      password,
    );
    return userCredentials.user;
  } catch (error) {
    throw Error((error as FirebaseError).message);
  }
};

export const logout = async () => {
  // Clear register localstorage on logout
  try {
    localStorage.setItem(config.REGISTER_LOCALSTORAGE_KEY, "");
  } catch (error) {
    Sentry.captureException(error);
  }
  return signOut(auth);
};

export const deleteUserFromFirebase = async () => {
  const auth = getAuth();
  const user = auth.currentUser;
  if (user) {
    return deleteUser(user)
      .then(() => {
        logout();
        location.href = config.BASE_URL;
      })
      .catch((error) => {
        throw new Error(error);
      });
  } else {
    throw new Error("User is required to be deleted");
  }
};

const saveRegistryFormData = async (
  uid: string,
  registryData: RegistryData,
) => {
  const firebaseRegistryData = transformRegistryDataToFirebaseDoc(
    uid,
    registryData,
  );
  return setDoc(doc(db, "users", uid), firebaseRegistryData);
};

export const createUser = async ({
  email,
  password,
  registryData,
}: {
  email: string;
  password: string;
  registryData: RegistryData;
}) => {
  return createUserWithEmailAndPassword(auth, email, password).then((value) => {
    const uid = value.user.uid;
    return saveRegistryFormData(uid, registryData);
  });
};

export const getRegistryUserData = async () => {
  const user = await getUser();
  if (user) {
    const document = await getDoc(doc(db, "users", user.uid));
    const transformedData = transformFirebaseDocToRegistryData(document.data());
    return transformedData;
  } else {
    return {};
  }
};

export const resetPassword = async (email: string) => {
  return sendPasswordResetEmail(auth, email);
};
