import { version } from "@/../package.json";

const cacheKey = `${version}-${new Date().toLocaleDateString()}`;

export const config = {
  development: {
    BASE_URL: "http://localhost:5173",
    BACKEND_URL: "http://127.0.0.1:8000",
    REGISTER_LOCALSTORAGE_KEY: `maifud-registry-data-${cacheKey}`,
    CART_LOCALSTORAGE_KEY: `maifud-cart-data-${cacheKey}`,
  },
  production: {
    BASE_URL: "https://maifud.app",
    BACKEND_URL: "https://maifud.herokuapp.com",
    REGISTER_LOCALSTORAGE_KEY: `maifud-registry-data-${cacheKey}`,
    CART_LOCALSTORAGE_KEY: `maifud-cart-data-${cacheKey}`,
  },
}[import.meta.env.MODE]!;
