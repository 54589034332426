import { createFileRoute, redirect } from "@tanstack/react-router";
import { UserData as UserDataPage } from "@/pages";
import { getUser } from "@/firebase";

export const Route = createFileRoute("/register/user-data")({
  component: UserDataPage,
  beforeLoad: async ({ location }) => {
    const user = await getUser();
    if (user) {
      throw redirect({
        to: "/register/confirmation",
        search: {
          redirect: location.href,
        },
      });
    }
  },
});
