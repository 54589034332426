import {
  AlertDialogCancel,
  AlertDialogFooter,
  AlertDialogAction,
} from "@/components/ui/alert-dialog";
import { EditIcon } from "lucide-react";
import {
  getShippingTypeName,
  getShippingFrecuencyName,
} from "@/utils/subscriptions";
import type { Section } from "./";
import { ReactivationWizardData } from "./queries";
import { formatCurrency } from "@/utils/currency";
import { formatDefault } from "@/utils/dates";

type SummaryProps = {
  reactivationData: ReactivationWizardData;
  onClose: () => void;
  onSelectSection: (section: Section) => void;
  onConfirmation: () => void;
};

const Summary = ({
  reactivationData: r,
  onSelectSection,
  onClose,
  onConfirmation,
}: SummaryProps) => {
  const itemClassName =
    "border rounded-md flex justify-between items-center p-2 hover:bg-primary/10 cursor-pointer";
  return (
    <>
      <div className="space-y-4">
        <div>
          <p className="text-xs font-semibold">Tipo de suscripción</p>
          <div
            className={itemClassName}
            onClick={() => onSelectSection("SUBSCRIPTION")}
          >
            <p className="text-sm font-normal">
              📦 {r.subscription_name}
              {" - "}
              <span className="text-primary font-sans font-semibold">
                {r.subscription_price}
              </span>
            </p>
            <div className="flex items-center gap-1">
              <small className="text-[10px] text-muted-foreground">
                Cambiar
              </small>
              <EditIcon className="text-primary" size={18} />
            </div>
          </div>
        </div>

        <div>
          <p className="text-xs font-semibold">Método de envío</p>
          <div
            className={itemClassName}
            onClick={() => onSelectSection("SHIPPING_TYPE")}
          >
            <p className="text-sm font-normal">
              {r.shipping_type === "DELIVERY" && (
                <>
                  <p>
                    🚚 {getShippingTypeName(r.shipping_type)}
                    {" - "}
                    <span className="text-primary font-sans font-semibold">
                      {formatCurrency(r.shipping_cost)}
                    </span>
                  </p>
                  <div className="text-xs font-semibold font-sans">
                    <p>{r.address}</p>
                    {r.address_additional_data && (
                      <p>"{r.address_additional_data}"</p>
                    )}
                    <p>{r.district_name}</p>
                  </div>
                </>
              )}
              {r.shipping_type === "PICKUP" && (
                <>
                  <p>
                    🏬 {getShippingTypeName(r.shipping_type)}
                    {" - "}
                    <span className="text-primary font-sans font-semibold">
                      {formatCurrency(r.shipping_cost)}
                    </span>
                  </p>
                  <div className="text-xs font-semibold font-sans">
                    <p>{r.pickup_name}</p>
                    <p>
                      {r.pickup_address}, {r.district_name}
                    </p>
                  </div>
                </>
              )}
            </p>
            <div className="flex items-center gap-1">
              <small className="text-[10px] text-muted-foreground">
                Cambiar
              </small>
              <EditIcon className="text-primary" size={18} />
            </div>
          </div>
        </div>

        <div>
          <p className="text-xs font-semibold">Frecuencia de envío</p>
          <div
            className={itemClassName}
            onClick={() => onSelectSection("SHIPPING_FRECUENCY")}
          >
            <p className="text-sm font-normal">
              ⏰ {getShippingFrecuencyName(r.shipping_frecuency)}
            </p>
            <div className="flex items-center gap-1">
              <small className="text-[10px] text-muted-foreground">
                Cambiar
              </small>
              <EditIcon className="text-primary" size={18} />
            </div>
          </div>
        </div>

        <div>
          <p className="text-xs font-semibold">Fecha siguiente envío</p>
          <div
            className={itemClassName}
            onClick={() => onSelectSection("DELIVERY_DATE")}
          >
            <p className="text-sm font-normal font-sans">
              {r.delivery_date && "🗓️"} {formatDefault(r.delivery_date)}
            </p>
            <div className="flex items-center gap-1">
              <small className="text-[10px] text-muted-foreground">
                Cambiar
              </small>
              <EditIcon className="text-primary" size={18} />
            </div>
          </div>
        </div>
      </div>
      <AlertDialogFooter className="pt-4">
        <AlertDialogCancel onClick={onClose}>Cerrar</AlertDialogCancel>
        <AlertDialogAction
          disabled={r.delivery_date == null}
          onClick={onConfirmation}
        >
          Confirmar
        </AlertDialogAction>
      </AlertDialogFooter>
    </>
  );
};

export default Summary;
