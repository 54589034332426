import boxSmall from "@/assets/photos/50/small.webp";
import boxMedium from "@/assets/photos/50/medium.webp";
import boxLarge from "@/assets/photos/50/big.webp";
import boxFruits from "@/assets/photos/50/fruits.webp";

export const getSmallSubscriptionImage = (subscriptionId: string) => {
  const images: { [key: string]: string } = {
    "1": boxMedium,
    "3": boxSmall,
    "2": boxLarge,
    "4": boxFruits,
  };
  return images[subscriptionId] || "";
};
