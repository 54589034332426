import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import {
  fetchAdressData,
  fetchPersonalData,
  postPersonalData,
  fetchUserSubscription,
  fetchSubscriptionsData,
  fetchUserHomeData,
  fetchPaymentMethods,
  postFrecuency,
  fetchCheckout,
  fetchResumeCheckout,
  searchProducts,
  fetchUserSubscriptionData,
  fetchBoxContent,
  postUserInputToGetBoxSuggestion,
  getReferral,
  postUserSubscription,
  getPauseSuscription,
  postPauseSuscription,
  postDeleteSuscription,
  getUserShippingData,
  postUserShippingData,
  fetchSubscriptions,
  fetchUserHasDeliveryOnSpecialWeek,
  postDeliveryDateForSpecialWeek,
  postRetryPayment,
  deletePaymentMethod,
  updatePaymentMethod,
  getLatestBoxContent,
  postResetBox,
  postConfirmBox,
  getUserOrders,
  getOrderProducts,
  getOrderDetails,
  getOrderPaymentsTotal,
  postResumeCheckoutDeliveryDate,
  getDiscounts,
  postCheckPartnership,
  purchaseUpsellProducts,
  getRecommendedProducts,
  getAllProducts,
  getBestSellerProducts,
  getSubscriptionStatus,
  postSendCodePartnership,
  postVerifyCodePartnership,
  getUpsellDiscount,
} from "./requests";

// Firebase queries
import { createUser, resetPassword, getRegistryUserData } from "@/firebase";
import {
  CheckoutInput,
  DeleteSuscriptionPost,
  FrecuencyInput,
  PauseSuscriptionPost,
  ResumeCheckoutInput,
  SummaryCartProduct,
} from "./types";
import { HomeDeliveryData } from "@/components/ShippingForm/HomeDelivery";
import { PickUpDeliveryData } from "@/components/ShippingForm/PickupDelivery";

export const addressDataQuery = () =>
  useQuery({
    queryKey: ["adress-"],
    queryFn: fetchAdressData,
  });

export const personalDataQuery = () =>
  useQuery({
    queryKey: ["personal-data"],
    queryFn: fetchPersonalData,
  });

export const personalDataMutation = () =>
  useMutation({
    mutationFn: postPersonalData,
  });

export const subscriptionsQuery = () =>
  useQuery({
    queryKey: ["subscriptions"],
    queryFn: fetchSubscriptions,
  });

export const specialWeekQuery = () =>
  useQuery({
    queryKey: ["special-week"],
    queryFn: fetchUserHasDeliveryOnSpecialWeek,
  });

export const specialWeekMutation = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: postDeliveryDateForSpecialWeek,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["special-week"] });
      queryClient.invalidateQueries({ queryKey: ["subscription-status"] });
    },
  });
};

export const subscriptionQuery = () =>
  useQuery({
    queryKey: ["user-subscription"],
    queryFn: fetchUserSubscription,
  });

export const subscriptionMutation = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: postUserSubscription,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["user-subscription"] });
    },
  });
};

export const subscriptionsDataQuery = ({
  promo_code,
  referrer_code,
  partnership_data,
}: {
  promo_code?: string;
  referrer_code?: string;
  partnership_data?: string;
}) =>
  useQuery({
    queryKey: ["subscriptions-registry"],
    queryFn: () =>
      fetchSubscriptionsData({ promo_code, referrer_code, partnership_data }),
  });

export const frequencyQuery = (frecuencyInput: FrecuencyInput) =>
  useQuery({
    queryKey: ["frecuency", JSON.stringify(frecuencyInput)],
    queryFn: () => postFrecuency(frecuencyInput),
  });

export const createUserMutation = () =>
  useMutation({
    mutationFn: createUser,
  });

export const resetPasswordMutation = () =>
  useMutation({
    mutationFn: resetPassword,
  });

export const userHomeQuery = () =>
  useQuery({
    queryKey: ["user-home-data"],
    queryFn: fetchUserHomeData,
  });

export const userRegistryDataQuery = () =>
  useQuery({
    queryKey: ["user-registry-data"],
    queryFn: getRegistryUserData,
  });

export const userPaymentMethodsQuery = () =>
  useQuery({
    queryKey: ["payment-methods"],
    queryFn: fetchPaymentMethods,
  });

export const userCheckoutQuery = (checkoutInput: CheckoutInput) =>
  useQuery({
    queryKey: ["checkout"],
    queryFn: () => fetchCheckout(checkoutInput),
  });

export const userResumeCheckoutQuery = (
  resumeCheckoutInput: ResumeCheckoutInput,
) =>
  useQuery({
    queryKey: ["resume-checkout"],
    queryFn: () => fetchResumeCheckout(resumeCheckoutInput),
  });

export const searchProductsQuery = (search: string) =>
  useQuery({
    queryKey: ["products", search],
    queryFn: () => searchProducts(search),
    enabled: search !== undefined && search !== "",
  });

export const recommendedProductsQuery = () =>
  useQuery({
    queryKey: ["recommended", "products"],
    queryFn: () => getRecommendedProducts(),
  });

export const allProductsQuery = () =>
  useQuery({
    queryKey: ["all", "products"],
    queryFn: () => getAllProducts(),
  });

export const bestSellerProductsQuery = () =>
  useQuery({
    queryKey: ["best-seller", "products"],
    queryFn: () => getBestSellerProducts(),
  });

export const purchaseUpsellProductsMutation = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (data: { cart: SummaryCartProduct[]; total: number }) =>
      purchaseUpsellProducts(data.cart, data.total),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["subscription-status"] });
      queryClient.invalidateQueries({ queryKey: ["latest-box-content"] });
      queryClient.invalidateQueries({ queryKey: ["box-content"] });
    },
  });
};

export const userSubscriptionDataQuery = () =>
  useQuery({
    queryKey: ["user-subscription-data"],
    queryFn: fetchUserSubscriptionData,
  });

export const boxContentQuery = () =>
  useQuery({
    queryKey: ["box-content"],
    queryFn: fetchBoxContent,
  });

export const boxSuggestionMutation = () =>
  useMutation({
    mutationFn: (userInput?: string) =>
      postUserInputToGetBoxSuggestion(userInput),
  });

export const referralQuery = (referralCode?: string) =>
  useQuery({
    queryKey: ["referral"],
    queryFn: () => getReferral(referralCode),
  });

export const pauseQuery = () =>
  useQuery({
    queryKey: ["pause-suscription"],
    queryFn: () => getPauseSuscription(),
  });

export const pauseMutation = () =>
  useMutation({
    mutationFn: (values: PauseSuscriptionPost) => postPauseSuscription(values),
  });

export const deleteMutation = () =>
  useMutation({
    mutationFn: (values: DeleteSuscriptionPost) =>
      postDeleteSuscription(values),
    onSuccess: () => {
      // deleteUserFromFirebase();
    },
  });

export const userShippingDataQuery = () =>
  useQuery({
    queryKey: ["user-shipping-data"],
    queryFn: () => getUserShippingData(),
  });

export const userShippingDataMutation = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (values: HomeDeliveryData | PickUpDeliveryData) =>
      postUserShippingData(values),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["user-shipping-data"] });
      queryClient.invalidateQueries({ queryKey: ["subscription-wizard-data"] });
    },
  });
};

export const retryPaymentMutation = () =>
  useMutation({
    mutationFn: postRetryPayment,
  });

export const updatePaymentMutation = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: updatePaymentMethod,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["payment-methods"] });
    },
  });
};

export const deletePaymentMutation = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: deletePaymentMethod,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["payment-methods"] });
    },
  });
};

export const latestBoxContentQuery = (options?: {
  productlessResponse: boolean;
}) => {
  return useQuery({
    queryKey: ["latest-box-content", JSON.stringify(options)],
    queryFn: () => getLatestBoxContent(options),
  });
};

export const resetBoxMutation = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: postResetBox,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ["box-content"],
      });
      queryClient.invalidateQueries({
        queryKey: ["user-home-data"],
      });
      queryClient.invalidateQueries({
        queryKey: ["subscription-status"],
      });
    },
  });
};

export const confirmBoxMutation = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: postConfirmBox,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ["box-content", "user-home-data"],
      });
    },
  });
};

export type ConfirmBoxMutation = ReturnType<typeof confirmBoxMutation>;

export const ordersQuery = () =>
  useQuery({
    queryKey: ["orders"],
    queryFn: getUserOrders,
  });

export const orderProductsQuery = (orderId?: number, enabled = true) =>
  useQuery({
    queryKey: ["orders", orderId],
    queryFn: () => getOrderProducts(orderId),
    enabled: orderId !== undefined && enabled,
  });

export const orderDetailsQuery = (orderId?: number, enabled = true) =>
  useQuery({
    queryKey: ["orders", "details", orderId],
    queryFn: () => getOrderDetails(orderId),
    enabled: orderId !== undefined && enabled,
  });

export const orderPaymentsTotalQuery = (orderId?: number, enabled = true) =>
  useQuery({
    queryKey: ["orders", "payments", orderId],
    queryFn: () => getOrderPaymentsTotal(orderId),
    enabled: orderId !== undefined && enabled,
  });

export const resumeCheckoutDeliveryDateMutation = () =>
  useMutation({
    mutationFn: postResumeCheckoutDeliveryDate,
  });

export const discountsQuery = () =>
  useQuery({
    queryKey: ["discounts"],
    queryFn: getDiscounts,
  });

export const upsellDiscountQuery = () =>
  useQuery({
    queryKey: ["upsell-discount"],
    queryFn: getUpsellDiscount,
  });

export const partnershipCheckMutation = () =>
  useMutation({
    mutationFn: postCheckPartnership,
  });

export const partnershipSendCode = () =>
  useMutation({
    mutationFn: postSendCodePartnership,
  });

export const partnershipVerifyCode = () =>
  useMutation({
    mutationFn: postVerifyCodePartnership,
  });

export const subscriptionStatusQuery = () =>
  useQuery({
    queryKey: ["subscription-status"],
    queryFn: getSubscriptionStatus,
  });
