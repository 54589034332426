import { useSearch } from "@tanstack/react-router";
import {
  ButtonCustomLink,
  Loading,
  PageLayout,
  RetryErrorMessage,
  TableItem,
} from "@/components";
import {
  userResumeCheckoutQuery,
  resumeCheckoutDeliveryDateMutation,
} from "@/services/queries";
import { Label } from "@/components/ui/label";
import { Button } from "@/components/ui/button";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import { z } from "zod";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form";
import type { ResumeCheckoutSearchSchema } from "@/routes/rcheckout";
import { useUser } from "@/firebase";
import { useState } from "react";
import ConfirmationAlert from "./ConfirmationAlert";
import { ResumeCheckoutOutput } from "@/services/types";
import { AxiosError } from "axios";

const resumeCheckoutSchema = z.object({
  first_delivery_date: z.string({
    required_error: "⚠️ Necesitamos la fecha para tu primera entrega",
  }),
});

export type ResumeCheckoutSchema = z.infer<typeof resumeCheckoutSchema>;

const ResumeCheckout = () => {
  const [showCompletePaymentConfirmation, setShowCompletePaymentConfirmation] =
    useState(false);
  const { user } = useUser();
  const {
    uid,
    link_url,
    error: pageError,
  } = useSearch({
    strict: false,
  }) as ResumeCheckoutSearchSchema;
  const {
    data: response,
    isLoading,
    isError,
    error,
    refetch,
  } = userResumeCheckoutQuery({ uid, link_url });
  const deliveryDateMutation = resumeCheckoutDeliveryDateMutation();

  const data = response?.data;

  const form = useForm<ResumeCheckoutSchema>({
    resolver: zodResolver(resumeCheckoutSchema),
  });

  function onSubmit({ first_delivery_date }: ResumeCheckoutSchema) {
    if (uid) {
      deliveryDateMutation.mutate(
        {
          first_delivery_date: first_delivery_date.toLowerCase(),
          uid,
          link_url: link_url ?? "",
        },
        {
          onSuccess: () => {
            setShowCompletePaymentConfirmation(true);
          },
        },
      );
    } else {
      throw new Error("UID and link_url are required");
    }
  }

  const handleGoToWebpay = () => {
    if (data?.inscription_url) {
      location.href = `${data.inscription_url}&new_frontend=true`;
    } else {
      throw new Error("inscription_url is not defined");
    }
  };

  const renderError = () => {
    if (!deliveryDateMutation.isError) return null;

    const errorCode = (deliveryDateMutation.error as AxiosError).response?.data;

    if (errorCode === "DATE_NOT_AVAILABLE") {
      return (
        <div className="pt-4">
          <p className="text-center text-red-500 text-sm">
            🙁 La fecha que escogiste ya no está disponible
          </p>
        </div>
      );
    }

    return (
      <div>
        <p className="text-center text-red-500 text-sm">
          Hubo un problema al continuar con tu registro. Por favor intenta de
          nuevo más tarde.
        </p>
      </div>
    );
  };

  const renderFooter = (data: ResumeCheckoutOutput) => {
    if (user?.email == null) {
      return null;
    }

    return (
      <>
        <ul className="pt-6 space-y-2 text-xs text-muted-foreground">
          <li>
            El primer cobro se hará ahora y luego regularmente se cobrará{" "}
            <strong>{data.subscription_subtotal} + costo de envio</strong> el
            domingo anterior a tu siguiente entrega.
          </li>
          <li>
            Desde tu cuenta, podrás pausar o cancelar en cualquier momento.
          </li>
          <li>
            Se abrirá una nueva ventana para que ingreses tu método de pago y
            finalices el proceso de suscripción.
          </li>
        </ul>
        <Form {...form}>
          <form onSubmit={form.handleSubmit(onSubmit)}>
            <div className="pt-4">
              <FormField
                control={form.control}
                name="first_delivery_date"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>Primer día de envío</FormLabel>
                    <Select
                      onValueChange={field.onChange}
                      defaultValue={field.value}
                    >
                      <FormControl>
                        <SelectTrigger>
                          <SelectValue placeholder="Primer día de envío" />
                        </SelectTrigger>
                      </FormControl>
                      <SelectContent>
                        {data.frecuency_names.map((d) => (
                          <SelectItem key={d} value={d}>
                            {d}
                          </SelectItem>
                        ))}
                      </SelectContent>
                    </Select>
                    <FormMessage />
                  </FormItem>
                )}
              />
            </div>
            {renderError()}
            <div className="flex flex-col justify-end py-4 gap-4">
              <Button
                type="submit"
                className="w-full"
                disabled={deliveryDateMutation.isPending}
                isLoading={deliveryDateMutation.isPending}
              >
                Completar suscripción
              </Button>
            </div>
          </form>
        </Form>
        {showCompletePaymentConfirmation && (
          <ConfirmationAlert onConfirm={handleGoToWebpay} />
        )}
      </>
    );
  };

  const renderContent = () => {
    if (isLoading) {
      return <Loading message="🔍 Buscando la información de tu registro..." />;
    }
    if (isError) {
      return (
        <RetryErrorMessage
          title={"No se pudo obtener la información del registro."}
          message={error.message}
          retry={refetch}
          error={error}
        />
      );
    }
    if (data === undefined) return null;

    const selectedShippingType =
      data.shipping_type === "PICKUP"
        ? "Retiro en tiendas"
        : "Entrega a domicilio";

    return (
      <div className="mx-auto max-w-md w-full">
        <div className="space-y-4">
          {pageError && pageError === "date_is_not_valid" && (
            <div className="pt-4">
              <p className="text-center text-red-500 text-sm">
                🙁 La fecha que escogiste ya no está disponible. Por favor
                escoge otra.
              </p>
            </div>
          )}
          <div className="p-4 border-b-orange-200 bg-orange-100">
            {user?.email ? (
              <>
                <p className="text-sm font-medium leading-none text-center">
                  ¿Deseas editar la información?
                </p>
                <div className="flex justify-center pt-2">
                  <ButtonCustomLink
                    variant="outline"
                    to="/register/subscriptions"
                  >
                    Volver a iniciar
                  </ButtonCustomLink>
                </div>
              </>
            ) : (
              <>
                <p className="text-sm text-muted-foreground text-center mt-2">
                  Accede a tu cuenta para completa tu suscripción
                </p>
                <div className="flex justify-center pt-2">
                  <ButtonCustomLink to="/login">
                    Iniciar sesión
                  </ButtonCustomLink>
                </div>
              </>
            )}
          </div>
          <div>
            <Label>Resumen suscripción</Label>
            <TableItem
              title="Suscripción"
              content={data.subscription_name || ""}
            />
            <TableItem
              title="Frecuencia de envío"
              content={data.shipping_frecuency || ""}
            />
            <TableItem title="Método de envío" content={selectedShippingType} />
            {data.shipping_type === "PICKUP" && (
              <>
                <TableItem
                  title="Punto de retiro"
                  content={data.pickup_name || ""}
                />
                {/* TODO: find out how to get the pickup address */}
                {/* <TableItem
            title="Dirección punto de retiro"
            content={
              <a
                className="underline text-sm"
                target="_blank"
                href={`https://maps.google.com/maps?hl=es&q=${registryData.pickup_address}`}
              >
                {registryData.pickup_address}
              </a>
            }
          /> */}
              </>
            )}
            {data.shipping_type === "DELIVERY" && (
              <>
                <TableItem
                  title="Dirección envio"
                  content={
                    <div className="flex flex-col text-xs">
                      <p>{`${data.delivery_address} - ${data.delivery_address_number}`}</p>
                      <p>{data.delivery_district_name}</p>
                      <p>{data.additional_data}</p>
                    </div>
                  }
                />
              </>
            )}
          </div>

          <div>
            <Label>Resumen primer pago</Label>
            <TableItem title="Subtotal" content={data.subscription_subtotal} />
            <TableItem
              title={"Costo de entrega"}
              content={data.shipping_cost}
            />
            <TableItem
              title={`Descuento primer pedido`}
              content={data.discount_amount}
            />
            <TableItem
              title="Total"
              content={data.subscription_total_with_discount}
              isImportant
            />
          </div>
        </div>
        {renderFooter(data)}
      </div>
    );
  };

  return (
    <PageLayout
      title="Completa tu suscripción"
      description="Solo falta un paso para completar tu suscripción"
    >
      {renderContent()}
    </PageLayout>
  );
};

export default ResumeCheckout;
