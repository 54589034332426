import { Textarea } from "@/components/ui/textarea";
import {
  AlertDialog,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
} from "@/components/ui/alert-dialog";
import { pastBoxesQuery } from "./queries";
import { useState } from "react";
import { Loading } from "@/components";
import { cn } from "@/lib/utils";

type PastBoxesProps = {
  subscriptionId: number;
  onClose: () => void;
};

const PastBoxes = ({ subscriptionId, onClose }: PastBoxesProps) => {
  const [week, setWeek] = useState(1);
  const { data: response, isLoading } = pastBoxesQuery({
    subscriptionId: subscriptionId,
    week,
  });
  const data = response?.data;

  const renderContent = () => {
    if (isLoading) {
      return (
        <AlertDialogHeader>
          <AlertDialogDescription>
            <Loading message="Buscando información de cajas..." />
          </AlertDialogDescription>
        </AlertDialogHeader>
      );
    }
    if (data) {
      return (
        <AlertDialogHeader>
          <AlertDialogDescription>
            <Textarea
              className="h-[300px] bg-orange-500/10 selection:bg-primary selection:text-white"
              value={`${data.products
                .filter((p) => p.quantity > 0)
                .map(
                  (p) =>
                    `${p.quantity} ` +
                    `${p.quantity_unit_type} ` +
                    `${p.full_product_name} `,
                )
                .join("\n")}`}
              readOnly
            />
          </AlertDialogDescription>
        </AlertDialogHeader>
      );
    }
  };

  return (
    <AlertDialog open>
      <AlertDialogContent>
        <AlertDialogTitle>
          <strong className="font-semibold text-primary">
            {data?.subscription_name}
          </strong>{" "}
          de hace{" "}
          <strong className="font-semibold text-primary">
            {week} {week === 1 ? "semana" : "semanas"}
          </strong>
          .
        </AlertDialogTitle>
        <div className="flex justify-between -translate-y-1">
          <button
            type="button"
            onClick={() => setWeek((w) => Math.min(w + 1, 5))}
            disabled={week === 5}
          >
            <span
              className={cn(
                "text-sm text-primary underline",
                week === 5 && "opacity-50",
              )}
            >
              Ver semana antes
            </span>
          </button>
          <button
            type="button"
            onClick={() => setWeek((w) => Math.max(w - 1, 1))}
            disabled={week === 1}
          >
            <span
              className={cn(
                "text-sm text-primary underline",
                week === 1 && "opacity-50",
              )}
            >
              Ver semana despues
            </span>
          </button>
        </div>
        {renderContent()}
        <AlertDialogFooter>
          <AlertDialogCancel onClick={onClose}>Cerrar</AlertDialogCancel>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  );
};

export default PastBoxes;
