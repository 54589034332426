import {
  ButtonCustomLink,
  ImportantMessage,
  Loading,
  PageLayout,
  RetryErrorMessage,
  TableItem,
} from "@/components";
import { Button } from "@/components/ui/button";
import { userCheckoutQuery, subscriptionsDataQuery } from "@/services/queries";
import { useRegister } from "@/context/register";
import { Label } from "@/components/ui/label";
import { Link } from "@tanstack/react-router";
import { formatWithWeekDay } from "@/utils/dates";
import { formatStringCurrency } from "@/utils/currency";
import { useState } from "react";

function UserData() {
  const [redirecting, setRedirecting] = useState(false);
  const { registryData } = useRegister();
  // All those fields should be defined at this page when coming from the wizard
  const {
    subscription_id,
    shipping_type,
    shipping_entity_id,
    promo_code,
    partnership_data,
    referrer_user_id,
  } = registryData!;

  const { data: subscriptionsResponse, isLoading: subscriptionsIsLoading } =
    subscriptionsDataQuery({});

  const {
    data: response,
    isLoading,
    isError,
    error,
    refetch,
  } = userCheckoutQuery({
    subscription_id: subscription_id!,
    // TODO: remove the harcoded string once the backend checkoutv2 endpoint supports "PIKCUP" and "DELIVERY" instead.
    shipping_type:
      shipping_type! === "PICKUP" ? "Retiro en tiendas" : "Entrega a domicilio",
    shipping_entity_id: shipping_entity_id!,
    referrer_user_id: referrer_user_id!,
    promo_code: promo_code!,
    partnership_data: partnership_data
      ? JSON.stringify(partnership_data)
      : undefined,
  });

  const handleGoToWebpay = () => {
    setRedirecting(true);
    if (response?.data.inscription_url) {
      location.href = response.data.inscription_url;
    } else {
      throw new Error("inscription_url is not defined");
    }
  };

  const renderContent = () => {
    if (isLoading || subscriptionsIsLoading) {
      return <Loading message="🔍 Buscando información de tu registro..." />;
    }

    if (isError) {
      return (
        <RetryErrorMessage
          title={"No se pudo obtener la información del registro."}
          message={error.message}
          retry={refetch}
          error={error}
        />
      );
    }

    if (response && subscriptionsResponse) {
      const data = response.data;

      const subscriptions = subscriptionsResponse.data.subscriptions;
      const selectedSubscription = subscriptions.find(
        (s) => String(s.id) === String(registryData?.subscription_id),
      );
      const selectedShippingType =
        registryData?.shipping_type === "PICKUP"
          ? "Retiro en tiendas"
          : "Entrega a domicilio";
      const firstDeliveryDateFormatted = formatWithWeekDay(
        registryData?.shipping_first_delivery_date,
      );

      const renderFreeShippingReason = () => {
        if (data.free_shipping_reason === null) {
          return "";
        }
        if (data.free_shipping_reason === "REFEREE") {
          return "(Referido)";
        }
        if (data.free_shipping_reason === "PROMO_CODE") {
          return "(Código envío gratis)";
        }
      };

      return (
        <>
          {data.error_raised && (
            <div className="mb-2">
              <ImportantMessage
                variant="destructive"
                title="🚫 Error en el pago"
                message="Hubo un error al momento de inscribir tu tarjeta. Puedes intentarlo de nuevo."
              />
            </div>
          )}

          <div className="mx-auto max-w-md w-full">
            <div className="p-4 border-b-orange-200 bg-orange-100 mb-4">
              <p className="text-sm font-medium leading-none text-center">
                ¿Deseas editar la información?
              </p>
              <div className="flex justify-center pt-2">
                <ButtonCustomLink
                  to="/register/subscriptions"
                  variant="outline"
                >
                  Empezar de nuevo
                </ButtonCustomLink>
              </div>
            </div>
            <div className="space-y-4">
              <div>
                <Label>Resumen suscripción</Label>
                <TableItem
                  title="Suscripción"
                  content={selectedSubscription?.name || ""}
                />
                <TableItem
                  title="Frecuencia de envío"
                  content={registryData?.shipping_frecuency || ""}
                />
                <TableItem
                  title="Fecha primer envío"
                  content={firstDeliveryDateFormatted}
                />
                <TableItem
                  title="Método de envío"
                  content={selectedShippingType}
                />
                {registryData?.shipping_type === "PICKUP" && (
                  <>
                    <TableItem
                      title="Punto de retiro"
                      content={registryData.pickup_name || ""}
                    />
                    {/* TODO: find out how to get the pickup address */}
                    {/* <TableItem
                    title="Dirección punto de retiro"
                    content={
                      <a
                        className="underline text-sm"
                        target="_blank"
                        href={`https://maps.google.com/maps?hl=es&q=${registryData.pickup_address}`}
                      >
                        {registryData.pickup_address}
                      </a>
                    }
                  /> */}
                  </>
                )}
                {registryData?.shipping_type === "DELIVERY" && (
                  <>
                    <TableItem
                      title="Dirección envio"
                      content={
                        <div className="flex flex-col text-xs">
                          <p>{`${registryData.address} - ${registryData.address_dpto}`}</p>
                          <p>{registryData.delivery_district}</p>
                          <p>{registryData.aditional_notes}</p>
                        </div>
                      }
                    />
                  </>
                )}
              </div>

              <div>
                <Label>Resumen primer pago</Label>
                <TableItem
                  title="Subtotal"
                  content={formatStringCurrency(data.subscription_subtotal)}
                />
                <TableItem
                  title={"Costo de entrega " + renderFreeShippingReason()}
                  content={formatStringCurrency(data.shipping_cost)}
                />
                <TableItem
                  title={`${data.discount_reason} (${data.discount}%)`}
                  content={formatStringCurrency(data.discount_amount)}
                />
                <TableItem
                  title="Total"
                  content={formatStringCurrency(
                    data.subscription_total_with_discount,
                  )}
                  isImportant
                />
              </div>
            </div>
            <ul className="pt-6 space-y-2 text-xs text-muted-foreground">
              <li>
                El primer cobro se hará ahora y luego regularmente se cobrará{" "}
                <strong>
                  {formatStringCurrency(data.subscription_subtotal)} + costo de
                  envio
                </strong>{" "}
                el domingo anterior a tu siguiente entrega.
              </li>
              <li>
                Desde tu cuenta, podrás pausar o cancelar en cualquier momento.
              </li>
              <li>
                Se abrirá una nueva ventana para que ingreses tu método de pago
                y finalices el proceso de suscripción.
              </li>
            </ul>
            <div className="flex flex-col justify-end py-4 gap-4">
              <Link
                to={"/register/shipping-frecuency"}
                className="text-center text-sm underline block text-primary"
              >
                Volver atrás
              </Link>
              <Button
                type="submit"
                className="w-full"
                onClick={handleGoToWebpay}
              >
                {redirecting ? "Redirigiendo..." : "Completar suscripción"}
              </Button>
            </div>
          </div>
        </>
      );
    }
  };

  return (
    <PageLayout
      title="Detalles de pago"
      description="Este es el resumen del cobro que haremos por tu suscripción"
      progress={96}
    >
      {renderContent()}
    </PageLayout>
  );
}

export default UserData;
