import { CardContent, Card } from "@/components/ui/card";
import { ButtonCustomLink, CustomLink } from "@/components";
import { formatDefault } from "@/utils/dates";
import { DBOrder } from "@/services/types";
import happyAvocadoImage from "@/assets/happy_avocado.png";

type WaitingForBoxConfirmationProps = {
  deliveryDate: string;
  nextCutoffDatetime: string;
  order: DBOrder;
};

const WaitingForBoxConfirmation = ({
  deliveryDate,
  nextCutoffDatetime,
  order,
}: WaitingForBoxConfirmationProps) => {
  const renderAddress = () => {
    if (order.shipping_type === "PICKUP") {
      const address = `${order.pickup_address}, ${order.pickup_district}`;
      return (
        <div className="mt-4">
          <p className="text-sm font-semibold">Retiro en tienda:</p>
          <p className="text-sm text-muted-foreground font-sans">
            <a
              href={`https://www.google.com/maps/place/${address}`}
              target="_blank"
            >
              {address}
            </a>
          </p>
          <p className="text-sm text-muted-foreground font-sans">
            Horario: {order.pickup_opening_hours}
          </p>
        </div>
      );
    }
    if (order.shipping_type === "DELIVERY") {
      const address = `${order.person_address?.trim()}, ${order.person_address_dpto?.trim()}, ${order.person_district?.trim()}`;
      return (
        <div className="mt-4">
          <p className="text-sm font-semibold">Dirección:</p>
          <p className="text-sm text-muted-foreground font-sans">{address}</p>
          {order.person_adittional_data && (
            <p className="text-sm text-muted-foreground font-sans">
              "{order.person_adittional_data}"
            </p>
          )}
        </div>
      );
    }
    return null;
  };

  return (
    <div>
      <Card className="border rounded-lg col-span-2 relative z-20">
        <div className="bg-primary px-4 py-1 rounded-tl-lg rounded-tr-lg shadow-sm flex justify-between">
          <strong className="text-white font-normal font-sans">
            Tu proximo envio
          </strong>
        </div>
        <CardContent className="px-4">
          <div className="flex justify-between border-b py-4">
            <div>
              <p className="text-xl font-semibold text-primary">
                {formatDefault(deliveryDate)}
              </p>
              <p className="text-base font-semibold text-muted-foreground">
                {order.subscription_name}
              </p>
              {renderAddress()}
            </div>
          </div>

          <div className="py-4 border-b">
            <div className="flex flex-col md:flex-row justify-between items-center gap-6">
              <p className="text-sm">
                ¡El contenido de tu caja ya está disponible!
              </p>
              <div className="w-full md:text-right">
                <ButtonCustomLink
                  to="/user/subscription/box"
                  className="w-full md:w-fit"
                >
                  Ver contenido caja
                </ButtonCustomLink>
              </div>
            </div>
          </div>

          <div className="pt-4">
            {nextCutoffDatetime && (
              <small className="text-sm">
                ⚠️ Puedes personalizar tu caja hasta las{" "}
                <strong className="text-primary font-sans">3PM</strong> del{" "}
                <strong className="text-primary font-sans">
                  {formatDefault(nextCutoffDatetime)}
                </strong>
              </small>
            )}
          </div>
        </CardContent>
      </Card>

      {/* Avocado banner */}
      <div className="flex items-center border px-6 py-4  rounded-bl-md rounded-br-md gap-6 bg-primary/10 -translate-y-[5px] relative z-10">
        <div className="w-[60px]">
          <img src={happyAvocadoImage} alt="Palta felíz" className="w-full" />
        </div>
        <div className="flex flex-col w-full">
          <h3 className="font-semibold">Complementa tu caja</h3>
          <p className="text-muted-foreground text-sm font-semibold">
            Agrega productos de Maifud a tu caja sin costo de envío extra.
          </p>
          <CustomLink
            to="/user/subscription/box/add-products"
            className="text-primary underline font-semibold"
          >
            Ver productos
          </CustomLink>
        </div>
      </div>
    </div>
  );
};

export default WaitingForBoxConfirmation;
