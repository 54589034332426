import SVGSeparator from "./SVGSeparator";
import feature1Image from "@/assets/home/feature-1.png";
import feature2Image from "@/assets/home/feature-2.png";
import feature3Image from "@/assets/home/feature-3.png";
import feature4Image from "@/assets/home/feature-4.png";

type FeatureProps = {
  imagePath: string;
  title: string;
  description: string;
};

const Feature = ({ imagePath, title, description }: FeatureProps) => {
  return (
    <div className="flex flex-col items-center">
      <div className="max-w-[290px] max-h-[290px] flex items-center justify-center h-full">
        <img src={imagePath} alt="" />
      </div>
      <h4 className="text-lg font-medium text-center text-primary leading-tight">
        {title}
      </h4>
      <p className="text-center">{description}</p>
    </div>
  );
};

const Features = () => {
  return (
    <div className="">
      <div className="max-w-7xl mx-auto">
        <div className="grid grid-cols-2 lg:grid-cols-4 gap-6 p-6">
          <Feature
            imagePath={feature1Image}
            title="Apoya a productores locales"
            description="Revalorizamos cosecha que no cumplió los estándares del comercio."
          />
          <Feature
            imagePath={feature2Image}
            title="Come variado y estacional"
            description="Nuestras cajas traen gran variedad de frutas y verduras frescas de
            temporada."
          />
          <Feature
            imagePath={feature3Image}
            title="Ahorra dinero"
            description="Tus frutas y verduras a mejores precios que el mercado tradicional."
          />
          <Feature
            imagePath={feature4Image}
            title="¡Más fácil imposible!"
            description="Recibe tu caja regularmente y sin preocupaciones. ¡Pausa cuando
            quieras!"
          />
        </div>
      </div>
      <SVGSeparator direction="UP" />
    </div>
  );
};

export default Features;
