import {
  AlertDialogCancel,
  AlertDialogFooter,
  AlertDialogAction,
} from "@/components/ui/alert-dialog";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/components/ui/table";
import {
  ReactivationPreConfirmationErrorResponse,
  ReactivationPreConfirmationResponse,
  ReactivationWizardData,
} from "./queries";
import { Loading, RetryErrorMessage } from "@/components";
import {
  reactivationWizardPreConfirmationMutation,
  reactivationWizardConfirmationMutation,
} from "./queries";
import { useEffect } from "react";
import { formatCurrency } from "@/utils/currency";
import { getShippingTypeName } from "@/utils/subscriptions";
import { formatDefault } from "@/utils/dates";
import meadowImage from "@/assets/meadow.png";
import paymentFailed from "@/assets/payment-failed.webp";
import { Button } from "@/components/ui/button";

type ConfirmationProps = {
  reactivationData: ReactivationWizardData;
  onClose: () => void;
  onFinish: () => void;
};

const Confirmation = ({
  reactivationData: r,
  onClose,
  onFinish,
}: ConfirmationProps) => {
  const preConfirmationMutation = reactivationWizardPreConfirmationMutation();
  const confirmationMutation = reactivationWizardConfirmationMutation();

  useEffect(() => {
    if (r.delivery_date === undefined) {
      onClose();
    } else {
      // Get confirmation data on component load
      preConfirmationMutation.mutate({
        subscription_id: r.subscription_id,
        shipping_frecuency: r.shipping_frecuency,
        shipping_type: r.shipping_type,
        delivery_date: r.delivery_date,
      });
    }
  }, []);

  // Make sure confirmation is not visible when delivery_date is undefined
  useEffect(() => {
    if (r.delivery_date === undefined) {
      onClose();
    }
  }, [r.delivery_date]);

  const handleConfirm = () => {
    if (r.delivery_date) {
      confirmationMutation.mutate({ delivery_date: r.delivery_date });
    }
  };

  const renderSuccessConfirmation = () => {
    return (
      <div>
        <p className="text-primary text-center font-medium text-lg">
          ¡Te damos la bienvenida de vuelta! 💚
        </p>
        <div className="w-32 h-32 rounded-full mx-auto overflow-hidden my-4">
          <img
            src={meadowImage}
            alt="Imagen de vegetales y frutas en una pradera soleada"
          />
        </div>

        <p className="text-sm text-center mb-4">
          Tu suscripción ya está activa nuevamente y tu próximo envío será el{" "}
          <strong className="text-primary font-semibold font-sans">
            {formatDefault(r.delivery_date)}
          </strong>
        </p>

        <Button className="w-full" onClick={onFinish}>
          Ir a mi cuenta
        </Button>
      </div>
    );
  };

  const renderPaymentFailedConfirmation = () => {
    return (
      <div>
        <p className="text-primary text-center font-medium text-lg">
          ¡Te damos la bienvenida de vuelta! 💚
        </p>
        <div className="w-32 h-32 rounded-full mx-auto overflow-hidden my-4">
          <img
            src={paymentFailed}
            alt="Imagen de vegetales y frutas en una pradera soleada"
          />
        </div>
        <div className="bg-red-500/10 p-4 rounded-md mb-4">
          <p className="text-sm text-center mb-4">
            Tu suscripción ya está activa nuevamente, pero no pudimos procesar
            el pago de tu caja del{" "}
            <strong className="text-primary font-semibold font-sans">
              {formatDefault(r.delivery_date)}
            </strong>
            .
          </p>

          <p className="text-sm text-center mb-4">
            Ve a "💳 Métodos de pago" en tu cuenta para re-intentar el pago.
          </p>
        </div>

        <Button className="w-full" onClick={onFinish}>
          Ir a mi cuenta
        </Button>
      </div>
    );
  };

  const renderContent = () => {
    if (confirmationMutation.isPending) {
      return <Loading message="📦 Reactivando tu suscripción..." />;
    }
    if (confirmationMutation.isError) {
      return (
        <RetryErrorMessage
          title={"Hubo un problema al reactivar tu suscripción."}
          message={"Tu suscripción no se ha podido reactivar."}
          error={confirmationMutation.error}
        />
      );
    }
    if (confirmationMutation.isSuccess) {
      const orderHasBeenPaid =
        confirmationMutation.data.data.order_has_been_paid;
      return orderHasBeenPaid
        ? renderSuccessConfirmation()
        : renderPaymentFailedConfirmation();
    }
    if (preConfirmationMutation.isPending) {
      return <Loading message="🔍 Buscando información de tu suscripción..." />;
    }
    if (preConfirmationMutation.isError) {
      return (
        <RetryErrorMessage
          title={"No se pudo obtener la información de tu suscripción."}
          message={preConfirmationMutation.error.message}
          error={preConfirmationMutation.error}
        />
      );
    }
    if (preConfirmationMutation.data?.data) {
      const response = preConfirmationMutation.data?.data;
      if (
        (response as ReactivationPreConfirmationErrorResponse).status ===
        "error"
      ) {
        return <p>Error</p>;
      }
      const data = response as ReactivationPreConfirmationResponse;

      return (
        <div>
          <p className="text-sm mb-4 border border-primary rounded-md p-2 bg-primary/10">
            ⚠️ Vamos a realizar un cobro de{" "}
            <strong className="text-primary font-semibold font-sans">
              {formatCurrency(data.order_incl_tax_total)}
            </strong>{" "}
            a tu método de pago{" "}
            <strong className="text-primary font-semibold">
              {data.payment_card_type}
            </strong>{" "}
            terminado en{" "}
            <strong className="text-primary font-semibold font-sans">
              {data.payment_card_number.replace("XXXXXXXXXXXX", "")}
            </strong>{" "}
            para crear un pedido con envío el{" "}
            <strong className="text-primary font-semibold font-sans">
              {formatDefault(r.delivery_date)}
            </strong>
          </p>
          <div className="border rounded-md">
            <Table>
              <TableHeader>
                <TableRow className="text-xs">
                  <TableHead className="font-semibold">Concepto</TableHead>
                  <TableHead className="font-semibold">Monto</TableHead>
                </TableRow>
              </TableHeader>
              <TableBody className="text-left text-xs">
                <TableRow className="font-sans">
                  <TableCell>{r.subscription_name}</TableCell>
                  <TableCell>
                    {formatCurrency(data.order_incl_tax_sub_total)}
                  </TableCell>
                </TableRow>
                <TableRow className="font-sans">
                  <TableCell>{getShippingTypeName(r.shipping_type)}</TableCell>
                  <TableCell>
                    {data.shipping_incl_tax_total === 0 ? (
                      <small className="text-xs">Envio gratis</small>
                    ) : (
                      formatCurrency(data.shipping_incl_tax_total)
                    )}
                  </TableCell>
                </TableRow>
                {data.discount_incl_tax_total > 0 && (
                  <TableRow className="font-sans">
                    <TableCell>Descuento</TableCell>
                    <TableCell>
                      -{formatCurrency(data.discount_incl_tax_total)}
                    </TableCell>
                  </TableRow>
                )}
                <TableRow className="font-sans">
                  <TableCell>Total:</TableCell>
                  <TableCell className="font-sans text-base text-primary font-semibold">
                    {formatCurrency(data.order_incl_tax_total)}
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </div>
        </div>
      );
    }
  };

  return (
    <>
      <div className="space-y-4">
        <div>{renderContent()}</div>
      </div>
      {!confirmationMutation.isSuccess && (
        <AlertDialogFooter className="pt-4">
          <AlertDialogCancel
            disabled={confirmationMutation.isPending}
            onClick={onClose}
          >
            Volver
          </AlertDialogCancel>
          <AlertDialogAction
            onClick={handleConfirm}
            disabled={
              preConfirmationMutation.isPending ||
              confirmationMutation.isPending
            }
          >
            Reactivar
          </AlertDialogAction>
        </AlertDialogFooter>
      )}
    </>
  );
};

export default Confirmation;
