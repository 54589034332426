import { Loading, RetryErrorMessage } from "@/components";
import { Label } from "@/components/ui/label";
import { subscriptionStatusQuery } from "@/services/queries";
import ActiveOrder from "./ActiveOrder";
import NextOrder from "./NextOrder";
import TerminatedSubscription from "./TerminatedSubscription";
import PausedSubscription from "./PausedSubscription";

const SubscriptionStatus = () => {
  const {
    data: response,
    isLoading,
    isError,
    error,
    refetch,
  } = subscriptionStatusQuery();

  const data = response?.data;

  if (isLoading) {
    return (
      <div className="border rounded-md">
        <Loading size="small" />
      </div>
    );
  }

  if (isError) {
    return (
      <RetryErrorMessage
        title={"No se pudo obtener la información de tu subscripción."}
        message={error.message}
        retry={refetch}
        error={error}
      />
    );
  }

  if (data) {
    const {
      order,
      subscription_state,
      next_cutoff_datetime,
      now,
      next_delivery_date,
      next_payment_datetime,
      next_recipe_datetime,
      order_is_editable,
    } = data;
    if (subscription_state === "SUBSCRIPTION_TERMINATED") {
      return (
        <div>
          <Label className="mb-2 block">Tu próximo envío</Label>
          <TerminatedSubscription />
        </div>
      );
    }
    if (subscription_state === "SUBSCRIPTION_PAUSED") {
      return (
        <div>
          <Label className="mb-2 block">Tu próximo envío</Label>
          <PausedSubscription />
        </div>
      );
    }
    if (order) {
      return (
        <div>
          <ActiveOrder
            subscriptionState={subscription_state}
            order={order}
            nextCutoffDatetime={next_cutoff_datetime}
            nextRecipeDatetime={next_recipe_datetime}
            isEditable={order_is_editable}
          />
        </div>
      );
    } else {
      return (
        <div>
          <NextOrder
            now={now}
            deliveryDate={next_delivery_date}
            nextRecipeDatetime={next_recipe_datetime}
            paymentDatetime={next_payment_datetime}
            nextCutoffDatetime={next_cutoff_datetime}
          />
        </div>
      );
    }
  }

  return null;
};

export default SubscriptionStatus;
