import { useState } from "react";
import {
  AlertDialogCancel,
  AlertDialogFooter,
  AlertDialogAction,
} from "@/components/ui/alert-dialog";
import { ReactivationWizardData } from "./queries";
import { Calendar } from "@/components/ui/calendar";
import { Loading, RetryErrorMessage } from "@/components";
import { es } from "date-fns/locale";
import { frequencyQuery } from "@/services/queries";
import { dateStringToDatetime, datetimeToDateString } from "@/utils/dates";

type SubscriptionsUpdateData = Pick<ReactivationWizardData, "delivery_date">;

type DeliveryDateProps = {
  reactivationData: ReactivationWizardData;
  onClose: () => void;
  onUpdate: (subscriptionData: SubscriptionsUpdateData) => void;
};

const DeliveryDate = ({
  reactivationData: r,
  onUpdate,
  onClose,
}: DeliveryDateProps) => {
  const {
    data: response,
    isLoading,
    isError,
    error,
    refetch,
  } = frequencyQuery({
    shipping_type: r.shipping_type,
    district_name: r.district_name,
    pickup_name: r.pickup_name,
  });
  const [selectedDeliveryDate, setDeliveryDate] = useState<string>();
  const data = response?.data;

  const handleConfirm = () => {
    onUpdate({
      delivery_date: selectedDeliveryDate!,
    });
    onClose();
  };

  const renderContent = () => {
    if (isLoading) {
      return <Loading message="🔍 Buscando información de tu suscripción..." />;
    }

    if (isError) {
      return (
        <RetryErrorMessage
          title={"No se pudo obtener la información de tu suscripción."}
          message={error.message}
          retry={refetch}
          error={error}
        />
      );
    }
    if (data) {
      const availableDates = data.dates;
      const selectedDate = selectedDeliveryDate
        ? dateStringToDatetime(selectedDeliveryDate)
        : undefined;
      return (
        <div className="flex flex-col items-center justify-center">
          <Calendar
            mode="single"
            className="font-sans"
            fromDate={dateStringToDatetime(availableDates[0])}
            toDate={dateStringToDatetime(availableDates.at(-1)!)}
            selected={selectedDate}
            onSelect={(value) => {
              if (value) {
                setDeliveryDate(datetimeToDateString(value));
              }
            }}
            disabled={(date) => {
              return !availableDates.includes(datetimeToDateString(date));
            }}
            locale={es}
          />
          <small>Siguientes días disponibles para tu método de envío</small>
        </div>
      );
    }
  };

  return (
    <>
      <div className="space-y-4">
        <p className="font-semibold text-sm mb-2">
          ¿Cuanto quieres recibir tu caja tu siguiente caja?
        </p>
        <div>{renderContent()}</div>
      </div>
      <AlertDialogFooter className="pt-4">
        <AlertDialogCancel onClick={onClose}>Volver</AlertDialogCancel>
        <AlertDialogAction onClick={handleConfirm}>Confirmar</AlertDialogAction>
      </AlertDialogFooter>
    </>
  );
};

export default DeliveryDate;
