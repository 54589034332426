import { ReferralLanding } from "@/pages";
import { createFileRoute, redirect } from "@tanstack/react-router";
import { LayoutDefault } from "@/components";
import * as z from "zod";

const referralSchema = z.object({
  referral_code: z.optional(z.string()),
});

export type ReferralSearchSchema = z.infer<typeof referralSchema>;

const Page = () => (
  <LayoutDefault>
    <ReferralLanding />
  </LayoutDefault>
);

export const Route = createFileRoute("/li")({
  component: Page,
  validateSearch: referralSchema,
  beforeLoad: async ({ location }) => {
    document.title = "Maifud - Código de referido _";
    if (location.searchStr.includes("referral_code=")) {
      return;
    }
    const referral_code = Object.keys(location.search)[0];
    throw redirect({
      to: "/li",
      search: {
        referral_code,
      },
    });
  },
});
