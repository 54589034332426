import { ReactivationWizardData } from "./queries";
import ShippingEdit from "@/serverComponents/ShippingEdit";
import { ScrollArea } from "@/components/ui/scroll-area";

type ShippingTypeProps = {
  reactivationData: ReactivationWizardData;
  onClose: () => void;
  onUpdate: () => void;
};

const ShippingType = ({ onClose, onUpdate }: ShippingTypeProps) => {
  return (
    <ScrollArea className="h-[300px]">
      <ShippingEdit onGoBack={onClose} onSuccess={onUpdate} />
    </ScrollArea>
  );
};

export default ShippingType;
