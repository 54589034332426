import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import * as z from "zod";
import { useNavigate } from "@tanstack/react-router";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
  FormDescription,
} from "@/components/ui/form";
import { Input } from "@/components/ui/input";
import { Textarea } from "@/components/ui/textarea";
import { Button } from "@/components/ui/button";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import { Loading, RetryErrorMessage } from "@/components";
import { addressDataQuery } from "@/services/queries";
import { useRegister, type RegistryData } from "@/context/register";

const homedeliveryFromSchema = z.object({
  address: z.string({
    required_error: "⚠️ Necesitamos tu dirección para enviar tus cajas",
  }),
  address_dpto: z.string().optional(),
  delivery_district: z.string({
    required_error: "⚠️ Necesitamos tu comuna para enviar tus cajas",
  }),
  aditional_notes: z.string().optional(),
});

export type HomeDeliveryFormSchema = z.infer<typeof homedeliveryFromSchema>;

type HomeDeliveryFormProps = {
  initialData: RegistryData | null;
  onSubmit: (data: HomeDeliveryFormSchema) => void;
};

/**
 * HomeDeliveryForm is only used on the registry flow
 */
const HomeDeliveryForm = ({ onSubmit, initialData }: HomeDeliveryFormProps) => {
  const { setRegistryData } = useRegister();
  const navigate = useNavigate();

  const form = useForm<HomeDeliveryFormSchema>({
    resolver: zodResolver(homedeliveryFromSchema),
    defaultValues: initialData ?? {},
  });

  const {
    data: response,
    isLoading,
    isError,
    error,
    refetch,
  } = addressDataQuery();
  const data = response?.data;

  function handleSubmit(values: HomeDeliveryFormSchema) {
    onSubmit(values);
  }

  if (isLoading) {
    return <Loading message="🔍 Buscando información de comunas..." />;
  }

  if (isError) {
    return (
      <RetryErrorMessage
        title={"No se pudo obtener la información de las comunas."}
        message={error.message}
        retry={refetch}
        error={error}
      />
    );
  }

  if (data) {
    const { district_names, districts } = data;
    return (
      <Form {...form}>
        <form onSubmit={form.handleSubmit(handleSubmit)} className="space-y-6">
          <FormField
            control={form.control}
            name="delivery_district"
            render={({ field }) => {
              const selectedDistrict =
                field.value &&
                data.districts.find((d) => d.name == field.value);
              return (
                <FormItem>
                  <FormLabel>Comuna</FormLabel>
                  <Select
                    onValueChange={(value) => {
                      field.onChange(value);
                      setRegistryData((r) => ({
                        ...r,
                        district: value,
                        shipping_entity_id: districts.find(
                          (d) => d.district_name === value,
                        )?.id,
                      }));
                    }}
                    defaultValue={field.value}
                  >
                    <FormControl>
                      <SelectTrigger>
                        <SelectValue placeholder="Selecciona una comuna" />
                      </SelectTrigger>
                    </FormControl>
                    <SelectContent>
                      {[...new Set(district_names)].map((d) => (
                        <SelectItem key={d} value={d}>
                          {d}
                        </SelectItem>
                      ))}
                    </SelectContent>
                  </Select>
                  {selectedDistrict && (
                    <>
                      <FormDescription>
                        Costo de envío: {selectedDistrict.delivery_price}
                      </FormDescription>
                      <FormDescription>
                        Días de envío:{" "}
                        {selectedDistrict.humanized_shipping_days}
                      </FormDescription>
                    </>
                  )}
                  <FormMessage />
                </FormItem>
              );
            }}
          />
          <FormField
            control={form.control}
            name="address"
            render={({ field }) => (
              <FormItem>
                <FormLabel>Dirección</FormLabel>
                <FormControl>
                  <Input
                    autoComplete="address"
                    onChange={(e) => {
                      field.onChange(e);
                      setRegistryData((r) => ({
                        ...r,
                        address: e.target.value,
                      }));
                    }}
                    value={field.value}
                  />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="address_dpto"
            render={({ field }) => (
              <FormItem>
                <FormLabel>Número depto / Casa</FormLabel>
                <FormControl>
                  <Input
                    onChange={(e) => {
                      field.onChange(e);
                      setRegistryData((r) => ({
                        ...r,
                        address_dpto: e.target.value,
                      }));
                    }}
                    value={field.value}
                    maxLength={80}
                  />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="aditional_notes"
            render={({ field }) => (
              <FormItem>
                <FormLabel>Información adicional</FormLabel>
                <FormControl>
                  <Textarea
                    onChange={(e) => {
                      field.onChange(e);
                      setRegistryData((r) => ({
                        ...r,
                        aditional_notes: e.target.value,
                      }));
                    }}
                    value={field.value}
                    className="text-base md:text-sm"
                  />
                </FormControl>
                <FormDescription>
                  ¿Alguna información extra que nos ayude a entregar tu pedido
                  más facil y rápido?
                </FormDescription>
                <FormMessage />
              </FormItem>
            )}
          />
          <div className="flex justify-between py-4 gap-4">
            <Button
              type="button"
              variant="outline"
              className="w-full sm:w-fit"
              onClick={() => navigate({ to: "/register/subscriptions" })}
            >
              Volver
            </Button>
            <Button type="submit" className="w-full sm:w-fit">
              Siguiente
            </Button>
          </div>
        </form>
      </Form>
    );
  }
};

export default HomeDeliveryForm;
