import { Link, useRouter } from "@tanstack/react-router";
import maifudLogo from "@/assets/maifud_logo.png";
import { Button } from "@/components/ui/button";
import { Footer } from "@/components";
import { ArrowLeftCircle } from "lucide-react";

type LayoutDefaultProps = {
  children: React.ReactNode;
};

const LayoutDefault = ({ children }: LayoutDefaultProps) => {
  const router = useRouter();
  return (
    <>
      <div className="layout-background">
        <nav className="flex justify-center p-2 my-4 relative">
          <div className="justify-between flex my-2 absolute left-6 top-[13px] lg:hidden">
            <Button
              className="underlinespace-x-1 items-center p-0 text-muted-foreground"
              variant="link"
              onClick={() => router.history.back()}
            >
              <ArrowLeftCircle size={32} />
            </Button>
          </div>
          <Link to={"/"} className="flex items-baseline">
            <img alt="Maifud Logo" src={maifudLogo} className="w-48" />
          </Link>
        </nav>
        <div className="lg:mx-4">{children}</div>
      </div>
      <Footer />
    </>
  );
};

export default LayoutDefault;
