import { useState } from "react";
import { CalendarIcon } from "@radix-ui/react-icons";
import { es } from "date-fns/locale";
import { cn } from "@/lib/utils";
import {
  FormControl,
  FormDescription,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@/components/ui/popover";
import { Calendar } from "@/components/ui/calendar";
import { Button } from "@/components/ui/button";
import {
  formatDefault,
  dateStringToDatetime,
  datetimeToDateString,
} from "@/utils/dates";

type FormCalendarProps = {
  field: any;
  onChange: any;
  availableDates: string[];
};

const FormCalendar = ({
  field,
  onChange,
  availableDates,
}: FormCalendarProps) => {
  const [calendarOpen, setCalendarOpen] = useState(false);

  return (
    <FormItem className="flex flex-col">
      <FormLabel>Selecciona la fecha de tu primer envio</FormLabel>

      <Popover open={calendarOpen} onOpenChange={setCalendarOpen}>
        <PopoverTrigger asChild>
          <FormControl>
            <Button
              variant={"outline"}
              className={cn(
                "w-[240px] pl-3 text-left font-normal",
                !field.value && "text-muted-foreground",
              )}
            >
              {field.value ? formatDefault(field.value) : <span>---</span>}
              <CalendarIcon className="ml-auto h-4 w-4 opacity-50" />
            </Button>
          </FormControl>
        </PopoverTrigger>
        <PopoverContent className="w-auto p-0" align="start">
          <Calendar
            mode="single"
            className="font-sans"
            onSelect={(value) => {
              onChange(value);
              setCalendarOpen(false);
            }}
            selected={field.value}
            fromDate={dateStringToDatetime(availableDates[0])}
            disabled={(date) => {
              return !availableDates.includes(datetimeToDateString(date));
            }}
            locale={es}
          />
        </PopoverContent>
      </Popover>

      <FormDescription>
        Los envios de tu suscripción se calcularán en base a este dia.
      </FormDescription>
      <FormMessage />
    </FormItem>
  );
};

export default FormCalendar;
