import axios from "axios";
import { useQuery } from "@tanstack/react-query";
import { config } from "@/config";
import { getToken, getUIDParam } from "@/services/utils";
import { BoxItem } from "@/services/types";

export type PastBoxResponse = {
  products: BoxItem[];
  subscription_name: string;
  created_at: string;
};

const fetchPastBoxes = async (subcriptionId: number, week: number) => {
  const token = await getToken();
  return axios.get<PastBoxResponse>(
    `${config.BACKEND_URL}/api/v1/boxes/week_content`,
    {
      headers: { Authorization: `Bearer ${token}` },
      params: {
        week,
        subscription_id: subcriptionId,
        ...getUIDParam(),
      },
    },
  );
};

export const pastBoxesQuery = ({
  subscriptionId,
  week,
}: {
  subscriptionId: number;
  week: number;
}) =>
  useQuery({
    queryKey: ["past-boxes", subscriptionId, week],
    queryFn: () => fetchPastBoxes(subscriptionId, week),
  });
