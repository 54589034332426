import {
  AlertDialog,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogHeader,
  AlertDialogTitle,
} from "@/components/ui/alert-dialog";
import { Loading, RetryErrorMessage } from "@/components";
import { ReactivationWizardData, reactivationWizardDataQuery } from "./queries";
import { useEffect, useState } from "react";
import Summary from "./Summary";
import Subscriptions from "./Subscriptions";
import ShippingType from "./ShippingType";
import ShippingFrecuency from "./ShippingFrecuency";
import DeliveryDate from "./DeliveryDate";
import Confirmation from "./Confirmation";
import { useQueryClient } from "@tanstack/react-query";

type ReactivationWizardProps = {
  onClose: () => void;
};

export type Section =
  | "SUMMARY"
  | "SUBSCRIPTION"
  | "SHIPPING_TYPE"
  | "SHIPPING_FRECUENCY"
  | "DELIVERY_DATE"
  | "CONFIRMATION";

const ReactivationWizard = ({ onClose }: ReactivationWizardProps) => {
  const queryClient = useQueryClient();
  const [activeSection, setActiveSection] = useState<Section>("SUMMARY");
  const [reactivationData, setReactivationData] =
    useState<ReactivationWizardData>();
  const {
    data: response,
    isLoading,
    isError,
    error,
    refetch,
  } = reactivationWizardDataQuery();
  const data = response?.data;

  // Update state once the response is back
  useEffect(() => {
    if (data) {
      setReactivationData(data);
    }
  }, [data]);

  const renderContent = () => {
    if (isLoading) {
      return <Loading message="🔍 Buscando información de tu suscripción..." />;
    }

    if (isError) {
      return (
        <RetryErrorMessage
          title={"No se pudo obtener la información de tu suscripción."}
          message={error.message}
          retry={refetch}
          error={error}
        />
      );
    }

    if (reactivationData) {
      if (activeSection === "SUMMARY") {
        return (
          <Summary
            reactivationData={reactivationData}
            onClose={onClose}
            onSelectSection={(section) => setActiveSection(section)}
            onConfirmation={() => setActiveSection("CONFIRMATION")}
          />
        );
      } else if (activeSection === "SUBSCRIPTION") {
        return (
          <Subscriptions
            reactivationData={reactivationData}
            onClose={() => setActiveSection("SUMMARY")}
            onUpdate={(reactivationData) =>
              setReactivationData((s) => {
                if (s) {
                  return {
                    ...s,
                    subscription_id: reactivationData.subscription_id,
                    subscription_name: reactivationData.subscription_name,
                    subscription_price: reactivationData.subscription_price,
                    delivery_date: undefined,
                  };
                }
              })
            }
          />
        );
      } else if (activeSection === "SHIPPING_TYPE") {
        return (
          <ShippingType
            reactivationData={reactivationData}
            onClose={() => setActiveSection("SUMMARY")}
            onUpdate={() => {}}
          />
        );
      } else if (activeSection === "SHIPPING_FRECUENCY") {
        return (
          <ShippingFrecuency
            reactivationData={reactivationData}
            onClose={() => setActiveSection("SUMMARY")}
            onUpdate={(reactivationData) =>
              setReactivationData((s) => {
                if (s) {
                  return {
                    ...s,
                    shipping_frecuency: reactivationData.shipping_frecuency,
                  };
                }
              })
            }
          />
        );
      } else if (activeSection === "DELIVERY_DATE") {
        return (
          <DeliveryDate
            reactivationData={reactivationData}
            onClose={() => setActiveSection("SUMMARY")}
            onUpdate={(reactivationData) =>
              setReactivationData((s) => {
                if (s) {
                  return {
                    ...s,
                    delivery_date: reactivationData.delivery_date,
                  };
                }
              })
            }
          />
        );
      } else if (activeSection === "CONFIRMATION") {
        return (
          <Confirmation
            reactivationData={reactivationData}
            onClose={() => {
              setActiveSection("SUMMARY");
              setReactivationData((data) => {
                if (data) {
                  // Reset delivery after going back to avoid sending stale values
                  return { ...data, delivery_date: undefined };
                }
              });
            }}
            onFinish={() => {
              queryClient.invalidateQueries({
                queryKey: ["user-home-data"],
              });
              onClose();
            }}
          />
        );
      } else {
        throw new Error("Unknown activeSection");
      }
    }
    return null;
  };

  return (
    <AlertDialog open>
      <AlertDialogContent>
        <AlertDialogHeader>
          <AlertDialogTitle>Reactivar suscripción</AlertDialogTitle>
        </AlertDialogHeader>
        <AlertDialogDescription>{renderContent()}</AlertDialogDescription>
      </AlertDialogContent>
    </AlertDialog>
  );
};

export default ReactivationWizard;
