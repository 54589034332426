import { cn } from "@/lib/utils";
import { buttonVariants } from "@/components/ui/button";
import { sidebarNavItems } from "@/pages/User";
import { CustomLink } from "@/components";

interface SidebarNavProps extends React.HTMLAttributes<HTMLElement> {
  items: typeof sidebarNavItems;
}

export function SidebarNav({ className, items, ...props }: SidebarNavProps) {
  return (
    <>
      <nav
        className={cn(
          "hidden lg:flex md:max-w-full lg:flex-col lg:space-x-0 lg:space-y-1",
          className,
        )}
        {...props}
      >
        {items.map(({ href, title, emoji }) => (
          <CustomLink
            key={href}
            // @ts-expect-error
            to={href}
            className={cn(
              buttonVariants({ variant: "ghost" }),
              "md:hover:bg-orange-100/50",
              "justify-start",
            )}
            activeProps={{
              className:
                "bg-orange-500 text-white font-semibold md:hover:bg-orange-500 md:hover:text-white",
            }}
          >
            {emoji} {title}
          </CustomLink>
        ))}
      </nav>
    </>
  );
}
