import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogFooter,
} from "@/components/ui/dialog";
import { Button } from "@/components/ui/button";
import { Loading, RetryErrorMessage } from "@/components";
import { subscriptionPauseMutation, subscriptionPauseQuery } from "./queries";
import {
  dateStringToDatetime,
  datetimeToDateString,
  formatDefault,
  getPaymentDateFromDeliveryDate,
} from "@/utils/dates";
import { Calendar } from "@/components/ui/calendar";
import { es } from "date-fns/locale";
import { useState } from "react";
import { cn } from "@/lib/utils";

type PauseSubscriptionProps = {
  hasActiveOrder: boolean;
  deliveryDate: string;
  onClose: () => void;
  onDeleteSubscription: () => void;
};

const PauseSubscription = ({
  hasActiveOrder,
  deliveryDate,
  onClose,
  onDeleteSubscription,
}: PauseSubscriptionProps) => {
  const [selectedDeliveryDate, setSelectedDeliveryDate] =
    useState<string>(deliveryDate);

  const { data: response, isLoading } = subscriptionPauseQuery();
  const mutation = subscriptionPauseMutation();
  const data = response?.data;

  const handleMutation = () => {
    mutation.mutate({ delivery_date: selectedDeliveryDate });
  };

  const mutationResponse = mutation.data?.data;

  const savedButtonIsDisabled =
    deliveryDate === selectedDeliveryDate || mutation.isPending;

  const renderContent = () => {
    if (isLoading) {
      return <Loading message="Buscando fechas disponibles..." />;
    }
    if (mutation.isError) {
      return (
        <RetryErrorMessage
          title={
            "No se pudo pausar la suscripción. Intenta de nuevo más tarde."
          }
          message={mutation.error.message}
          retry={handleMutation}
          error={mutation.error}
        />
      );
    }

    if (data) {
      const parsedDates = data.dates.map((dateString) =>
        dateStringToDatetime(dateString),
      );
      return (
        <div>
          <div className="flex flex-col gap-4 md:flex-row">
            <div className="border rounded-md p-4">
              <p className="text-sm font-medium text-center">
                Selecciona cuando quieres recibir de nuevo
              </p>
              <div className="flex justify-center">
                <Calendar
                  mode="single"
                  className="font-sans"
                  disabled={(date) =>
                    !data.dates.includes(datetimeToDateString(date))
                  }
                  onSelect={(value) => {
                    if (value) {
                      setSelectedDeliveryDate(datetimeToDateString(value));
                    }
                  }}
                  selected={
                    selectedDeliveryDate
                      ? dateStringToDatetime(selectedDeliveryDate)
                      : undefined
                  }
                  fromDate={parsedDates[0]}
                  toDate={parsedDates.at(-1)}
                  locale={es}
                />
              </div>
            </div>
            <div className="flex flex-col justify-center items-center border rounded-md p-4 bg-primary/10 text-base md:text-sm">
              <p
                className={cn(
                  "text-center",
                  !savedButtonIsDisabled && "line-through",
                )}
              >
                Tu próximo envío es el{" "}
                <strong className={cn("text-primary font-medium font-sans")}>
                  {formatDefault(deliveryDate)}
                </strong>
                .
              </p>
              {!savedButtonIsDisabled && (
                <div>
                  <p className="mt-2 text-center">
                    ¿Deseas pausar y retomar tu suscripción el{" "}
                    <strong className="text-primary font-medium font-sans">
                      {formatDefault(selectedDeliveryDate)}
                    </strong>
                    ?
                  </p>
                  <p className="text-xs text-center mt-4">
                    *El siguiente cobro sería el{" "}
                    <span className="font-sans font-medium">
                      {formatDefault(
                        getPaymentDateFromDeliveryDate(selectedDeliveryDate),
                      )}
                    </span>
                  </p>
                </div>
              )}
            </div>
          </div>
          <div className="pt-4">
            <button
              className="text-center text-sm w-full underline text-primary"
              onClick={onDeleteSubscription}
            >
              ¿Deseas terminar tu suscripción?
            </button>
          </div>
        </div>
      );
    }
  };

  return (
    <Dialog open={true} onOpenChange={onClose}>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>Pausar suscripción</DialogTitle>
        </DialogHeader>
        {mutationResponse?.status === "OK" ? (
          <p className="text-center text-sm text-primary font-semibold">
            ✅ Tu nueva fecha de envío es el{" "}
            <strong className="font-sans">{formatDefault(deliveryDate)}</strong>
          </p>
        ) : (
          <div className="grid gap-4 pt-4">{renderContent()}</div>
        )}
        {hasActiveOrder && (
          <p className="text-center text-sm text-primary font-semibold">
            🔔 Tienes una orden activa. El cambio que hagas aplicará para tus
            siguientes envíos.
          </p>
        )}
        <DialogFooter className="gap-4 pt-2 md:gap-2">
          <Button
            type="button"
            variant="secondary"
            onClick={onClose}
            disabled={mutation.isPending}
          >
            Cerrar
          </Button>
          {mutationResponse?.status !== "OK" && (
            <Button type="submit" disabled={isLoading} onClick={handleMutation}>
              {mutation.isPending ? "Guardando..." : "Guardar cambio"}
            </Button>
          )}
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};

export default PauseSubscription;
