import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import * as z from "zod";
import {
  Card,
  CardHeader,
  CardTitle,
  CardDescription,
  CardContent,
  CardFooter,
} from "@/components/ui/card";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form";
import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import { resetPasswordMutation } from "@/services/queries";

const forgotPasswordSchema = z.object({
  email: z
    .string({
      required_error: "El email es necesario para recuperar la contraseña.",
    })
    .email("No parece ser un email válido."),
});

type ForgotPasswordSchema = z.infer<typeof forgotPasswordSchema>;

const ForgotPassword = () => {
  const resetPassword = resetPasswordMutation();

  const form = useForm<ForgotPasswordSchema>({
    resolver: zodResolver(forgotPasswordSchema),
  });

  const handleSubmit = ({ email }: ForgotPasswordSchema) => {
    resetPassword.mutate(email);
  };

  return (
    <div className="mx-auto max-w-[400px] w-full mt-8 mb-24">
      <Form {...form}>
        <form onSubmit={form.handleSubmit(handleSubmit)} className="space-y-8">
          <Card>
            <CardHeader className="space-y-1">
              <CardTitle className="text-2xl">Cambiar contraseña</CardTitle>
              <CardDescription>
                Ingresa el correo asociado a tu cuenta y te enviaremos un correo
                con instrucciones. Si no lo encuentras, puede que el correo te
                haya llegado a SPAM.
              </CardDescription>
            </CardHeader>
            <CardContent className="grid gap-4">
              <div className="grid gap-2">
                <FormField
                  control={form.control}
                  name="email"
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel>Email</FormLabel>
                      <FormControl>
                        <Input
                          placeholder="Ingresa tu email"
                          autoComplete="email"
                          onChange={field.onChange}
                          value={field.value}
                        />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
              </div>
            </CardContent>
            <CardFooter className="flex flex-col space-y-2">
              <Button
                className="w-full"
                disabled={resetPassword.isPending || resetPassword.isSuccess}
                isLoading={resetPassword.isPending}
              >
                {resetPassword.isPending
                  ? "Enviando instrucciones"
                  : "Enviar instrucciones"}
              </Button>
              {resetPassword.isError && (
                <FormMessage className="text-center">
                  No fue posible enviar intrucciones para recuperar contraseña
                </FormMessage>
              )}
              {resetPassword.isSuccess && (
                <FormMessage className="text-primary text-center">
                  ✅ Si estás registrado con este correo, te hemos enviado
                  instrucciones para cambiar tu contraseña.
                </FormMessage>
              )}
            </CardFooter>
          </Card>
        </form>
      </Form>
    </div>
  );
};

export default ForgotPassword;
