import womanBox from "@/assets/home/woman-box.png";

const Testimony = ({ text, author }: { text: string; author: string }) => {
  return (
    <li className="p-4 bg-[#f6e9dd]">
      <p className="text-sm mb-4">{text}</p>
      <p className="text-base font-semibold">{author}</p>
    </li>
  );
};

const testimonies = [
  {
    text: "Debo decir que la semana pasada pedí una caja y me sorprendió lo surtida y buena lo que venía y su precio fantástico!... lo recomiendo 100%",
    author: "NATACHA",
  },
  {
    text: "Recibí hoy mi caja, siento que fue la mejor compra del mes, definitivamente seguiré comprando",
    author: "PAULINA",
  },
  {
    text: "Me encanta! La he comprado varias veces y todo lo he aprovechado al máximo",
    author: "CLAUDIA",
  },
  {
    text: "Amoooo!!! 🥰👏 cada semana felicidad pura con la llegada de la caja imperfecta",
    author: "CATALINA",
  },
];

const MaifudLovers = () => {
  return (
    <div className="pb-0 max-w-7xl mx-auto" id="maifud-lovers-section">
      <div className="flex flex-col lg:flex-row gap-4">
        <div className="w-full lg:w-1/2">
          <img src={womanBox} alt="Mujer sosteniendo una caja de Maifud" />
        </div>
        <div className="w-full lg:w-1/2 pt-2 lg:pt-20">
          <h4 className="text-center lg:text-left text-3xl mb-6 font-bold">
            Cada vez somos más{" "}
            <strong className="text-primary">#MAIFUDLOVERS</strong>
          </h4>
          <ul className="grid grid-cols-2 gap-4 pb-10">
            {testimonies.map((t) => (
              <Testimony key={t.author} author={t.author} text={t.text} />
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default MaifudLovers;
