import { createFileRoute, redirect } from "@tanstack/react-router";
import { User as UserPage } from "@/pages";
import { getUser } from "@/firebase";

import { LayoutUser } from "@/components";

const Page = () => {
  return (
    <LayoutUser>
      <UserPage />
    </LayoutUser>
  );
};

export const Route = createFileRoute("/user")({
  component: Page,
  beforeLoad: async ({ location }) => {
    const user = await getUser();
    if (!user) {
      throw redirect({
        to: "/login",
        search: {
          redirect: location.href,
        },
      });
    }
  },
});
