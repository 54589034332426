import { Separator } from "@/components/ui/separator";
import { personalDataQuery, personalDataMutation } from "@/services/queries";
import * as z from "zod";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
  FormDescription,
} from "@/components/ui/form";
import { PhoneInput } from "@/components/ui/phone-input";
import { RetryErrorMessage, Loading } from "@/components";
import { Input } from "@/components/ui/input";
import { Button } from "@/components/ui/button";
import { Textarea } from "@/components/ui/textarea";
import { useEffect } from "react";
import { Label } from "@/components/ui/label";

const userAccountSchema = z.object({
  name: z
    .string({
      required_error: "El nombre es requerido",
    })
    .min(2, "El nombre debe tener al menos 2 caracteres"),
  phone_number: z
    .string({
      required_error: "El teléfono es requerido",
    })
    .min(9, "El teléfono es de minimo 9 caracteres"),
  user_notes: z.string().optional().nullish(),
});

type UserAccountSchema = z.infer<typeof userAccountSchema>;

const UserAccount = () => {
  const {
    data: response,
    isLoading,
    isError,
    refetch,
    error,
  } = personalDataQuery();
  const data = response?.data;

  const saveUserData = personalDataMutation();

  const form = useForm<UserAccountSchema>({
    resolver: zodResolver(userAccountSchema),
    defaultValues: {
      name: data?.name,
      phone_number: data?.phone_number,
      user_notes: data?.user_notes ?? "",
    },
  });

  useEffect(() => {
    if (data) {
      form.reset(data);
    }
  }, [data]);

  const handleSubmit = (values: UserAccountSchema) => {
    saveUserData.mutate(values);
  };

  const renderPersonalData = () => {
    if (isError) {
      return (
        <RetryErrorMessage
          title={"No se pudo obtener la información personal"}
          message={error.message}
          retry={refetch}
          error={error}
        />
      );
    }
    if (isLoading) {
      return <Loading message="🔍 Buscando tu información..." />;
    }
    if (data) {
      return (
        <Form {...form}>
          <form
            onSubmit={form.handleSubmit(handleSubmit)}
            className="space-y-6"
          >
            <div>
              <Label>Correo:</Label>
              <p className="text-sm">{data.email}</p>
              <small className="text-muted-foreground text-xs">
                Si necesitas cambiar tu correo{" "}
                <a
                  className="text-primary"
                  href={`https://wa.me/+56971295665?text=Hola Soporte Maifud,\n quiero cambiar mi correo. Mi correo actual es ${data.email}`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  contáctanos aquí
                </a>
              </small>
            </div>
            <FormField
              control={form.control}
              name="name"
              render={({ field }) => {
                return (
                  <FormItem>
                    <FormLabel htmlFor="name">Nombre</FormLabel>
                    <FormControl>
                      <Input
                        id="name"
                        type="text"
                        placeholder="Ingresa tu nombre"
                        autoComplete="name"
                        value={field.value}
                        onChange={field.onChange}
                      />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                );
              }}
            />
            <FormField
              control={form.control}
              name="phone_number"
              render={({ field }) => {
                const phone =
                  field.value && field.value.startsWith("+56")
                    ? field.value
                    : `+56${field.value}`;
                return (
                  <FormItem>
                    <FormLabel htmlFor="phone_number">Teléfono</FormLabel>
                    <PhoneInput
                      id="phone_number"
                      type="tel"
                      placeholder="Ingresa tu número de teléfono"
                      autoComplete="tel"
                      value={phone}
                      defaultCountry="CL"
                      className="font-sans"
                      countries={["CL"]}
                      onChange={field.onChange}
                    />
                    <FormMessage />
                  </FormItem>
                );
              }}
            />
            <FormField
              control={form.control}
              name="user_notes"
              render={({ field }) => {
                return (
                  <FormItem>
                    <FormLabel htmlFor="user_notes">
                      Alergias o restricciones alimentarias
                    </FormLabel>
                    <Textarea
                      id="user_notes"
                      placeholder="¿Alguna alergia que quieras contarnos?"
                      value={field.value ?? ""}
                      onChange={field.onChange}
                    />
                    <FormDescription>
                      Intentaremos tener en cuenta tus alergias y restricciones
                      alimentarias al momento de crear tus cajas
                    </FormDescription>
                    <FormMessage />
                  </FormItem>
                );
              }}
            />
            <div className="flex justify-end">
              <Button
                className="w-fit"
                type="submit"
                disabled={saveUserData.isPending}
                isLoading={saveUserData.isPending}
              >
                {saveUserData.isPending ? "Guardando" : "Guardar"}
              </Button>
            </div>
            {saveUserData.isError && (
              <FormMessage className="text-right">
                {saveUserData.error.message}
              </FormMessage>
            )}
            {saveUserData.isSuccess && (
              <FormMessage className="text-right text-primary">
                Tus cambios han sido guardados correctamente
              </FormMessage>
            )}
          </form>
        </Form>
      );
    }
  };

  return (
    <div className="space-y-4">
      <div>
        <h3 className="text-lg font-medium">Cuenta</h3>
        <p className="text-sm text-muted-foreground">
          Puedes modificar tus datos personales
        </p>
      </div>
      <Separator />
      {renderPersonalData()}
    </div>
  );
};

export default UserAccount;
