import { useInView } from "react-intersection-observer";
import CountUp from "react-countup";

const Item = ({
  value,
  children,
  type,
  visible,
}: {
  value: number;
  children: string;
  type: "PERCENTAGE" | "NUMBER";
  visible: boolean;
}) => {
  return (
    <li className="flex flex-col items-center justify-center">
      <div className="text-primary text-5xl font-bold font-sans">
        {visible && (
          <CountUp
            end={value}
            duration={1}
            separator="."
            suffix={type === "PERCENTAGE" ? "%" : ""}
          />
        )}
      </div>
      <p className="text-base font-semibold text-center">{children}</p>
    </li>
  );
};

const Problem = () => {
  const { ref, inView } = useInView({
    threshold: 0,
  });
  return (
    <div ref={ref} className="pt-10 pb-20 bg-[#f6e9dd]">
      <div className="max-w-7xl mx-auto">
        <div className="lg:pl-20">
          <h4 className="text-center md:text-left text-4xl mb-2 font-bold">
            EL PROBLEMA
          </h4>
          <p className="text-center lg:text-left mb-8 text-xl">
            Qué significa en desperdicio de alimentos
          </p>
        </div>
        <ul className="grid grid-cols-1 mb-6 items-start lg:mb-0 md:grid-cols-3 p-4 space-y-6 lg:space-y-0">
          <Item type="PERCENTAGE" value={40} visible={inView}>
            De los alimentos terminan en la basura.*
          </Item>
          <Item type="NUMBER" value={2500} visible={inView}>
            Millones de toneladas en el mundo terminan en la basura.*
          </Item>
          <Item type="PERCENTAGE" value={48} visible={inView}>
            Del total ocurre en la etapa de producción.*
          </Item>
        </ul>
        <p className="text-center text-xs text-muted-foreground">
          * Fuente: WWF 2022, fibl.org 2013, World Resourse Institute 2013,
          CountryEconomy.com, FAO
        </p>
        <div className="space-y-4 md:grid md:grid-cols-3 px-4 py-6 md:px-28 md:py-20">
          <p className="text-center md:text-left text-2xl font-bold">
            EL GRAN PROBLEMA QUE QUEREMOS RESOLVER ES EL DESPERDICIO DE
            ALIMENTOS
          </p>
          <div className="md:col-start-3 text-muted-foreground text-lg text-center md:text-right">
            <p>
              Únete a nosotros en la lucha contra este gran problema mundial.
            </p>
            <p>Juntos podemos hacer la diferencia</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Problem;
