import { createFileRoute, redirect } from "@tanstack/react-router";
import { LayoutDefault, Loading } from "@/components";

const Page = () => {
  return (
    <LayoutDefault>
      <Loading />
    </LayoutDefault>
  );
};

export const Route = createFileRoute("/pr")({
  component: Page,
  beforeLoad: async ({ location }) => {
    const promo_code = Object.keys(location.search)[0];
    throw redirect({
      to: "/register/subscriptions",
      search: {
        promo_code,
      },
    });
  },
});
