import { getUser } from "@/firebase";

export const getToken = async () => {
  const user = await getUser();
  let token = "";
  if (user) {
    token = await user.getIdToken();
  }
  return token;
};

export const getUserUID = async () => {
  const user = await getUser();
  let uid = "";
  if (user) {
    uid = user.uid;
  }
  return uid;
};

export const getUIDParam = () => {
  const urlParams = new URLSearchParams(window.location.search);
  if (urlParams.get("uid") !== null) {
    return {
      uid: urlParams.get("uid") as string,
      new_frontend: true,
    };
  }
  return { new_frontend: true };
};
