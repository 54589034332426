import { ShippingFrecuency, ShippingType } from "@/services/types";

export const getShippingTypeName = (shippingType: ShippingType) => {
  if (shippingType === "DELIVERY") {
    return "Envío a domicílio";
  }
  if (shippingType === "PICKUP") {
    return "Retiro en tienda";
  }
  return null;
};

export const getShippingFrecuencyName = (
  shippingFrecuency: ShippingFrecuency,
) => {
  if (shippingFrecuency === "WEEKLY") {
    return "Semanal";
  }
  if (shippingFrecuency === "BIWEEKLY") {
    return "Quincenal";
  }
  return null;
};
