import { Link } from "@tanstack/react-router";
import { SubscriptionData } from "@/services/types";
import smallBox from "@/assets/photos/50/small.webp";
import mediumBox from "@/assets/photos/50/medium.webp";
import bigBox from "@/assets/photos/50/big.webp";
import fruitsBox from "@/assets/photos/50/fruits.webp";
import { formatCurrency } from "@/utils/currency";
import { cn } from "@/lib/utils";
import { CustomLink } from "@/components";

const IMAGES = {
  "3": smallBox,
  "1": mediumBox,
  "2": bigBox,
  "4": fruitsBox,
};

type BoxProps = SubscriptionData & { showInitialDiscount: boolean };

const Box = ({
  id,
  name,
  aprox_people,
  aprox_quantity,
  aprox_weight,
  price_incl_tax: price,
  price_with_discount: priceWithDiscount,
  showInitialDiscount,
}: BoxProps) => {
  return (
    <div>
      <div className="relative">
        <img
          src={IMAGES[`${id}` as keyof typeof IMAGES]}
          alt={name}
          className={cn("w-full md:w-4/5 block mx-auto")}
        />
        <div className="px-2 py-1 md:px-4 md:py-2 font-bold bg-primary text-white absolute bottom-2 md:bottom-6 left-0 rounded-md text-sm md:text-lg font-sans space-y-0">
          <p className="text-base">
            {showInitialDiscount
              ? formatCurrency(priceWithDiscount)
              : formatCurrency(price)}
          </p>
        </div>
      </div>
      <p className="text-center text-xl font-semibold mb-2">{name}</p>
      <ul className="text-center text-sm text-muted-foreground space-y-1">
        <li className="flex space-x-1 items-center justify-center">
          <span>
            <strong className="font-sans">{aprox_people}</strong> personas
          </span>
        </li>
        <li className="flex space-x-1 items-center justify-center">
          <span>
            {/* Different text if fruits box */}
            {id === 4 ? (
              <>
                <strong className="font-sans">{aprox_quantity}</strong>{" "}
                variedades de frutas de temporada
              </>
            ) : (
              <>
                <strong className="font-sans">{aprox_quantity}</strong>{" "}
                variedades de frutas y verduras de temporada
              </>
            )}
          </span>
        </li>
        <li className="flex space-x-1 items-center justify-center">
          <span>
            <strong className="font-sans">{aprox_weight}</strong> kgs de peso
          </span>
        </li>
      </ul>
      {id === 1 && (
        <p className="flex z-10 p-2 my-2 text-sm rounded-md space-x-2 items-center w-full justify-center bg-orange-500/30">
          <span className="font-medium">La más querida</span>
        </p>
      )}
    </div>
  );
};

type BoxesProps = {
  subscriptions?: SubscriptionData[];
  showInitialDiscount: boolean;
};

const Boxes = ({ subscriptions, showInitialDiscount }: BoxesProps) => {
  if (subscriptions) {
    const discountFormatted = subscriptions[0].discount_formatted;
    return (
      <>
        <div className="pb-4 bg-[#f6e9dd]" id="boxes-section">
          <div className="max-w-7xl mx-auto">
            <h4 className="text-center text-3xl mx-auto max-w-[600px] font-bold py-0 mb-2">
              NUESTRAS CAJAS DE SUSCRIPCIÓN
            </h4>
            <p className="text-center mb-2 text-xl">
              ELIGE EL TAMAÑO DE CAJA QUE MÁS TE ACOMODE
            </p>
            {showInitialDiscount && (
              <p className="text-center text-base">
                Tienes un{" "}
                <strong className="text-primary font-sans text-xl">
                  {discountFormatted}
                </strong>{" "}
                de descuento en tu primera caja.
              </p>
            )}
            <div className="grid grid-cols-2 mx-auto p-8 lg:px-20 lg:grid-cols-4 gap-16 lg:gap-16 lg:w-full">
              {subscriptions.map((s) => (
                <CustomLink
                  key={s.id}
                  to="/register/subscriptions"
                  search={{ subs_id: s.id }}
                >
                  <Box {...s} showInitialDiscount={showInitialDiscount} />
                </CustomLink>
              ))}
            </div>
            <div className="py-10 flex justify-center">
              <button
                type="button"
                className="bg-primary text-primary-foreground rounded-md text-lg py-4 px-6 md:text-xl"
              >
                <Link to="/register/subscriptions">¡Escoge tu caja!</Link>
              </button>
            </div>
          </div>
        </div>
        <div className="home-wave rotate-180 z-50 relative"></div>
      </>
    );
  }
};

export default Boxes;
