import { FrecuencyType } from "@/services/types";
import {
  addWeeks as addWeeksDateFns,
  format,
  parse,
  startOfWeek,
  subDays,
  parseISO,
} from "date-fns";
import { es } from "date-fns/locale";

export const formatDefault = (date: Date | string | undefined) => {
  if (!date) {
    return "";
  }
  const parsedDate = typeof date === "string" ? parseISO(date) : date;
  return format(parsedDate, "PPP", { locale: es });
};

export const formatDefaultWithTime = (date: Date | string | undefined) => {
  if (!date) {
    return "";
  }
  const parsedDate = typeof date === "string" ? parseISO(date) : date;
  return format(parsedDate, "PPP kk:mm:ss", { locale: es });
};

const FIRST_DELIVERY_DATE_FORMAT = "EEEE d 'de' MMMM";

export const formatWithWeekDay = (date: Date | string | undefined) => {
  if (!date) {
    return "";
  }
  return format(date, FIRST_DELIVERY_DATE_FORMAT, { locale: es });
};

export const formatShortDate = (date: string) => {
  return format(date, "dd-MM-yyyy", { locale: es });
};

//
export const parseToISO8601 = (dateString: string) => {
  if (!dateString) {
    console.error("date is required");
  }
  // The format should match the one used in formatWithWeekDay
  const formatString = FIRST_DELIVERY_DATE_FORMAT;
  try {
    const parsedDate = parse(dateString, formatString, new Date(), {
      locale: es,
    });
    return parsedDate;
  } catch (error) {
    console.error("date is required");
  }
};

export const parseNextDeliveryDate = (date: string) => {
  return parse(date, "EEEE d 'de' MMMM", new Date(), { locale: es });
};

export const formatWithDay = (date: Date | string | undefined) => {
  if (!date) {
    return "";
  }
  return format(date, "EEEE d 'de' MMMM", { locale: es });
};

export const addWeeks = (date: Date | string, value: number) => {
  if (!date) {
    return "";
  }
  return addWeeksDateFns(date, value);
};

export const formatDayOfTheWeek = (date: Date | string | undefined) => {
  if (!date) {
    return "";
  }
  return format(date, "cccc", { locale: es });
};

export const getStartOfWeek = (date: Date) => {
  return startOfWeek(date, { weekStartsOn: 1 });
};

export const getDayBefore = (date: Date) => {
  return subDays(date, 1);
};

export const dateStringToDatetime = (dateString: string) => {
  const [year, month, day] = dateString.split("-").map(Number);
  return new Date(year, month - 1, day);
};

export const datetimeToDateString = (date: Date) => {
  const utcDate = new Date(date.getTime() + date.getTimezoneOffset() * 60000);
  return format(utcDate, "yyyy-MM-dd");
};

export const generateDatesBasedOnFrecuency = (
  startDate: Date,
  frequency: FrecuencyType,
): Date[] => {
  let dates: Date[] = [];
  const increment = frequency === "WEEKLY" ? 1 : 2;
  for (let i = 0; i < 4; i++) {
    dates.push(addWeeksDateFns(startDate, i * increment));
  }
  return dates;
};

export const getPaymentDateFromDeliveryDate = (dateString: string) => {
  const date = parseISO(dateString);
  const previousSunday = startOfWeek(date, { weekStartsOn: 0 });
  return previousSunday.toISOString().slice(0, 10);
};
