import { formatCurrency } from "@/utils/currency";
import featuredProductsData from "./featuredProductsData";

const MaifudStore = () => {
  return (
    <div className="max-w-7xl mx-auto mb-10">
      <div className="grid grid-cols-1 lg:grid-cols-2 p-8 gap-4">
        <div>
          <div className="grid grid-cols-3 md:grid-cols-4 gap-4 ">
            {featuredProductsData.map((p) => {
              let discount = 0;

              if (p.price && p.compare_at_price) {
                discount = Math.floor(
                  100 - (p.price * 100) / p.compare_at_price,
                );
              }

              return (
                <div key={p.id} className="border rounded-md p-4 relative">
                  <div className="mb-4">
                    <img
                      src={`https://cdnx.jumpseller.com/maifud/image/${p.images[0].id}/thumb/270/360`}
                      alt={p.name}
                    />
                  </div>
                  <div>
                    <p className="leading-0 text-sm font-semibold h-10 line-clamp-2">
                      {p.name}
                    </p>
                    {discount > 0 && (
                      <p className="absolute top-2 right-2 bg-primary text-white px-2 rounded-md">
                        <strong className="font-sans text-sm">
                          {discount}%
                        </strong>{" "}
                        <span className="text-[12px]">dto.</span>
                      </p>
                    )}
                    <div className="flex flex-col items-start">
                      <p className="font-sans text-2xl text-primary font-semibold">
                        {formatCurrency(p.price)}
                      </p>
                      {p.compare_at_price && (
                        <p className="font-sans text-[12px] line-through text-gray-400">
                          {formatCurrency(p.compare_at_price)}
                        </p>
                      )}
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
          <p className="text-sm mt-2 text-center">
            *Los precios y descuentos mostrados son referenciales y están
            sujetos a cambios sin previo aviso.
          </p>
        </div>
        <div className="text-center flex flex-col justify-center order-first mb-10 lg:order-last">
          <h4 className="text-3xl font-semibold mb-10">
            Tambien puedes comprar a granel en la tienda Maifud
          </h4>
          <div className="flex flex-col gap-8">
            <p>
              Disfruta de precios hasta un 30% más bajos que en los
              supermercados. Escoge lo que más te guste, ya sea a granel o con
              nuestras cajas de suscripción.
            </p>
            <p>
              Cada compra ayuda a combatir el desperdicio y rescatas productos
              frescos que de otro modo se desperdiciarían. ¡Haz tu compra y
              apoya un consumo más sostenible!
            </p>
            <div className="flex justify-center">
              <a
                href="https://tienda.maifud.cl"
                target="_blank"
                className="bg-primary text-primary-foreground rounded-md text-lg py-4 px-6 md:text-xl"
              >
                Ir a tienda Maifud
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MaifudStore;
