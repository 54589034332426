import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogFooter,
} from "@/components/ui/dialog";
import { Button } from "@/components/ui/button";
import { RetryErrorMessage } from "@/components";
import { useState } from "react";
import { subscriptionFrecuencyMutation } from "./queries";
import { ShippingFrecuency } from "@/services/types";
import weeklyImage from "@/assets/weekly.png";
import biweeklyImage from "@/assets/biweekly.png";
import { cn } from "@/lib/utils";

const FRECUENCY_DATA: { id: ShippingFrecuency; name: string; image: string }[] =
  [
    {
      id: "WEEKLY",
      name: "Semanal",
      image: weeklyImage,
    },
    {
      id: "BIWEEKLY",
      name: "Quincenal",
      image: biweeklyImage,
    },
  ];

type ChangeFrecuencyProps = {
  hasActiveOrder: boolean;
  activeFrecuency: ShippingFrecuency;
  onClose: () => void;
};

const ChangeFrecuency = ({
  hasActiveOrder,
  activeFrecuency,
  onClose,
}: ChangeFrecuencyProps) => {
  const [selectedFrecuency, setSelectedFrecuency] = useState(activeFrecuency);

  const mutation = subscriptionFrecuencyMutation();

  const handleMutation = () => {
    mutation.mutate({ frecuency: selectedFrecuency });
  };

  const mutationResponse = mutation.data?.data;

  const savedButtonIsDisabled =
    activeFrecuency === selectedFrecuency || mutation.isPending;

  const renderContent = () => {
    if (mutationResponse?.status === "ERROR") {
      if (mutationResponse.error_code === "RECIPE_NOT_READY") {
        return (
          <RetryErrorMessage
            title={"Order activa"}
            message={
              <p>
                Podrás cambiar tu suscripción una vez tengas acceso a tu orden
                activa.{" "}
                <a href="https://wa.me/56971295665" className="underline">
                  Si necesitas ayuda comunícate con nosotros
                </a>
              </p>
            }
          />
        );
      } else {
        return (
          <RetryErrorMessage
            title={"Error inesperado"}
            message={
              "Hubo un error inesperado. Por favor intenta de nuevo más tarde."
            }
            retry={handleMutation}
          />
        );
      }
    }

    if (mutation.isError) {
      return (
        <RetryErrorMessage
          title={"No se pudo cambiar la frecuencia de envío."}
          message={mutation.error.message}
          retry={handleMutation}
          error={mutation.error}
        />
      );
    }

    return (
      <div className="grid grid-cols-1 gap-4">
        {FRECUENCY_DATA.map((f) => (
          <div
            key={f.name}
            className={cn(
              "border p-2 rounded-md cursor-pointer",
              f.id === selectedFrecuency &&
                "bg-orange-500/10 border-orange-500",
            )}
            onClick={() => setSelectedFrecuency(f.id)}
          >
            <div className="flex items-center gap-2">
              <div className="max-w-20">
                <img src={f.image} alt={f.name} className="w-full" />
              </div>
              <div>
                <div className="flex justify-between items-center mb-1">
                  <p className="text-sm font-medium">{f.name}</p>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    );
  };

  return (
    <Dialog open={true} onOpenChange={onClose}>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>Cambiar frecuencia de envío</DialogTitle>
        </DialogHeader>
        {mutationResponse?.status === "OK" ? (
          <p className="text-center text-sm font-semibold pt-4">
            ✅ Tu nueva frecuencia de envío es{" "}
            <strong className="text-primary ">
              {FRECUENCY_DATA.find((f) => f.id === selectedFrecuency)?.name}
            </strong>
            .
          </p>
        ) : (
          <div className="grid gap-4 pt-4">{renderContent()}</div>
        )}
        {hasActiveOrder && (
          <p className="text-center text-sm text-primary font-semibold">
            🔔 Tienes una orden activa. El cambio que hagas aplicará para tus
            siguientes envíos.
          </p>
        )}
        <DialogFooter className="gap-4 pt-2 md:gap-2">
          <Button
            type="button"
            variant="secondary"
            onClick={onClose}
            disabled={mutation.isPending}
          >
            Cerrar
          </Button>
          {mutationResponse?.status !== "OK" && (
            <Button
              type="submit"
              disabled={savedButtonIsDisabled}
              onClick={handleMutation}
            >
              {mutation.isPending ? "Guardando..." : "Guardar cambio"}
            </Button>
          )}
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};

export default ChangeFrecuency;
