import {
  AlertDialogCancel,
  AlertDialogFooter,
  AlertDialogAction,
} from "@/components/ui/alert-dialog";
import { ReactivationWizardData } from "./queries";
import { RadioGroup, RadioGroupItem } from "@/components/ui/radio-group";
import { Label } from "@/components/ui/label";
import { cn } from "@/lib/utils";
import { useState } from "react";
import { ShippingFrecuency as ShippingFrecuencyType } from "@/services/types";

type SubscriptionsUpdateData = Pick<
  ReactivationWizardData,
  "shipping_frecuency"
>;

type ShippingFrecuencyProps = {
  reactivationData: ReactivationWizardData;
  onClose: () => void;
  onUpdate: (subscriptionData: SubscriptionsUpdateData) => void;
};

const ShippingFrecuency = ({
  reactivationData: r,
  onUpdate,
  onClose,
}: ShippingFrecuencyProps) => {
  const [selectedShippingFrecuency, setSelectedShippingFrecuency] = useState(
    r.shipping_frecuency,
  );

  const handleConfirm = () => {
    onUpdate({
      shipping_frecuency: selectedShippingFrecuency!,
    });
    onClose();
  };

  return (
    <>
      <div className="space-y-4">
        <p className="font-semibold text-sm mb-2">
          ¿Cada cuanto tiempo quieres recibir tu caja?
        </p>
        <RadioGroup
          defaultValue={selectedShippingFrecuency}
          onValueChange={(value) =>
            setSelectedShippingFrecuency(value as ShippingFrecuencyType)
          }
          className="grid grid-cols-2 gap-4"
        >
          <div>
            <RadioGroupItem
              value="WEEKLY"
              id="WEEKLY"
              className="peer sr-only"
            />
            <Label
              htmlFor="WEEKLY"
              className={cn(
                "flex flex-col items-center justify-between rounded-md border-2 border-muted bg-popover p-4 hover:bg-orange-100/50 peer-data-[state=checked]:border-primary [&:has([data-state=checked])]:border-primary",
                selectedShippingFrecuency == "WEEKLY" && "bg-orange-100/50",
              )}
            >
              <div className="w-full text-center mb-2">⏰</div>
              <p className="text-center">Semanal</p>
            </Label>
          </div>
          <div>
            <RadioGroupItem
              value="BIWEEKLY"
              id="BIWEEKLY"
              className="peer sr-only"
            />
            <Label
              htmlFor="BIWEEKLY"
              className={cn(
                "flex flex-col items-center justify-between rounded-md border-2 border-muted bg-popover p-4 hover:bg-orange-100/50 peer-data-[state=checked]:border-primary [&:has([data-state=checked])]:border-primary",
                selectedShippingFrecuency == "BIWEEKLY" && "bg-orange-100/50",
              )}
            >
              <div className="w-full text-center mb-2">⏰</div>
              <p className="text-center">Quincenal</p>
            </Label>
          </div>
        </RadioGroup>
      </div>
      <AlertDialogFooter className="pt-4">
        <AlertDialogCancel onClick={onClose}>Volver</AlertDialogCancel>
        <AlertDialogAction onClick={handleConfirm}>Confirmar</AlertDialogAction>
      </AlertDialogFooter>
    </>
  );
};

export default ShippingFrecuency;
