// Route added to migrate from flutterflow
import { Navigate } from "@tanstack/react-router";
import { createFileRoute } from "@tanstack/react-router";

export const Route = createFileRoute("/home")({
  component: () => <Navigate to="/user/subscription" />,
  beforeLoad: () => {
    document.title = "Maifud - Suscripción";
  },
});
