import { useState } from "react";
import { Separator } from "@/components/ui/separator";
import { Card, CardContent } from "@/components/ui/card";
import { subscriptionStatusQuery } from "@/services/queries";
import { formatDefault } from "@/utils/dates";
import ChangeSubscription from "./ChangeSubscription";
import {
  Loading,
  RetryErrorMessage,
  DeleteAccount,
  TableItem,
} from "@/components";
import ChangeFrecuency from "./ChangeFrecuency";
import ChangeNextDeliveryDate from "./ChangeNextDeliveryDate";
import { useScrollToTop } from "@/utils/hooks";
import PauseSubscription from "./PauseSubscription";
import { cn } from "@/lib/utils";
import SkipNextDelivery from "./SkipNextDelivery";

type EditAction =
  | "CHANGE_SUBSCRIPTION"
  | "CHANGE_FRECUENCY"
  | "CHANGE_DAY"
  | "SKIP_NEXT_DELIVERY"
  | "PAUSE_SUBSCRIPTION"
  | "CANCEL_SUBSCRIPTION";

const EditSubscription = () => {
  useScrollToTop();
  const [activeAction, setActiveAction] = useState<EditAction>();

  const {
    data: response,
    isLoading: subscriptionDataIsLoading,
    isError,
    error,
    refetch,
  } = subscriptionStatusQuery();

  const data = response?.data;

  const renderCurrentSubscriptionContent = () => {
    if (!data) return;

    if (data.subscription_status !== "ACTIVE") {
      return (
        <div className="text-sm">
          <p className="pt-4">
            ⚠️ Tu suscripción se encuentra
            {data.subscription_status === "PAUSED"
              ? " pausada "
              : " desactivada "}{" "}
            y ningún cobro será generado hasta que actives de nuevo tu
            suscripción. Si deseas reactivarla{" "}
            <a href="https://wa.me/56971295665" className="underline">
              comunícate con nosotros
            </a>
            .
          </p>
        </div>
      );
    }

    return (
      <div className="text-sm">
        <TableItem
          variant="simple"
          title={"Tipo"}
          content={<p>{data.subscription_name}</p>}
        />
        <TableItem
          variant="simple"
          title={"Frecuencia"}
          content={
            <p>
              {data.shipping_frecuency === "WEEKLY" ? "Semanal" : "Quincenal"}
            </p>
          }
        />
        <TableItem
          variant="simple"
          title={"Siguiente envío"}
          content={
            <p onClick={() => setActiveAction("CHANGE_DAY")}>
              {formatDefault(data.next_delivery_date)}
            </p>
          }
        />
        <TableItem
          variant="simple"
          title={"Siguiente cobro"}
          content={<p>{formatDefault(data.next_payment_datetime)}</p>}
        />
        {data.order != null && (
          <p className="pt-4">
            🔔 Tienes una orden ya creada por lo que los cambios que realices NO
            se verán reflejados en tu orden.{" "}
            <a href="https://wa.me/56971295665" className="underline">
              Si necesitas hacer un cambio en tu orden comunícate con nosotros
            </a>
          </p>
        )}
      </div>
    );
  };

  const renderContent = () => {
    if (subscriptionDataIsLoading) {
      return <Loading message="Buscando opciones para tu suscripción..." />;
    }

    if (isError) {
      return (
        <RetryErrorMessage
          title={"No se pudo obtener la información de las suscripciones."}
          message={error.message}
          retry={refetch}
          error={error}
        />
      );
    }

    if (data) {
      const actions_state = {
        CHANGE_SUBSCRIPTION: {
          isDisabled: data.subscription_status !== "ACTIVE",
        },
        CHANGE_FRECUENCY: {
          isDisabled: data.subscription_status !== "ACTIVE",
        },
        CHANGE_DAY: {
          isDisabled: data.subscription_status !== "ACTIVE",
        },
        PAUSE_SUBSCRIPTION: {
          isDisabled: data.subscription_status !== "ACTIVE",
        },
        SKIP_NEXT_DELIVERY: {
          isDisabled:
            data.subscription_status !== "ACTIVE" || data.order != null,
        },
      };

      return (
        <>
          <div className="border rounded-md p-4 bg-primary/10 border-primary">
            <p className="text-base font-medium">Tu suscripción actual</p>
            {renderCurrentSubscriptionContent()}
          </div>
          <Card>
            <CardContent className="pt-6">
              <p className="text-base font-semibold">Acciones</p>
              <div className="grid grid-cols-3 gap-4">
                <button
                  type="button"
                  disabled={actions_state.CHANGE_SUBSCRIPTION.isDisabled}
                  className={cn(
                    "flex-1 text-xs border rounded-md p-4 flex flex-col items-center gap-2 hover:bg-orange-500/10",
                    actions_state.CHANGE_SUBSCRIPTION.isDisabled &&
                      "opacity-40 hover:bg-white",
                  )}
                  onClick={() => setActiveAction("CHANGE_SUBSCRIPTION")}
                >
                  <span className="text-3xl">📦</span>
                  <span className="font-medium">Cambiar tipo de caja</span>
                </button>

                <button
                  type="button"
                  disabled={actions_state.CHANGE_FRECUENCY.isDisabled}
                  className={cn(
                    "flex-1 text-xs border rounded-md p-4 flex flex-col items-center gap-2 hover:bg-orange-500/10",
                    actions_state.CHANGE_FRECUENCY.isDisabled &&
                      "opacity-40 hover:bg-white",
                  )}
                  onClick={() => setActiveAction("CHANGE_FRECUENCY")}
                >
                  <span className="text-3xl">🗓️</span>
                  <span className="font-medium">
                    Cambiar frecuencia de envío
                  </span>
                </button>

                <button
                  type="button"
                  disabled={actions_state.CHANGE_DAY.isDisabled}
                  className={cn(
                    "flex-1 text-xs border rounded-md p-4 flex flex-col items-center gap-2 hover:bg-orange-500/10",
                    actions_state.CHANGE_DAY.isDisabled &&
                      "opacity-40 hover:bg-white",
                  )}
                  onClick={() => setActiveAction("CHANGE_DAY")}
                >
                  <span className="text-3xl">🚚</span>
                  <span className="font-medium">Cambiar día de envío</span>
                </button>

                <button
                  type="button"
                  disabled={actions_state.SKIP_NEXT_DELIVERY.isDisabled}
                  className={cn(
                    "flex-1 text-xs border rounded-md p-4 flex flex-col items-center gap-2 hover:bg-orange-500/10",
                    actions_state.SKIP_NEXT_DELIVERY.isDisabled &&
                      "opacity-40 hover:bg-white",
                  )}
                  onClick={() => setActiveAction("SKIP_NEXT_DELIVERY")}
                >
                  <span className="text-3xl">➡️</span>
                  <span className="font-medium">Saltar siguiente envío</span>
                </button>

                <button
                  type="button"
                  disabled={actions_state.PAUSE_SUBSCRIPTION.isDisabled}
                  className={cn(
                    "flex-1 text-xs border rounded-md p-4 flex flex-col items-center gap-2 hover:bg-orange-500/10",
                    actions_state.PAUSE_SUBSCRIPTION.isDisabled &&
                      "opacity-40 hover:bg-white",
                  )}
                  onClick={() => setActiveAction("PAUSE_SUBSCRIPTION")}
                >
                  <span className="text-3xl">⏸️</span>
                  <span className="font-medium">Pausar suscripción</span>
                </button>
              </div>
            </CardContent>
          </Card>

          {activeAction === "CHANGE_SUBSCRIPTION" && (
            <ChangeSubscription
              hasActiveOrder={data.order != null}
              onClose={() => setActiveAction(undefined)}
              activeSubscription={data.subscription_id}
            />
          )}

          {activeAction === "CHANGE_FRECUENCY" && (
            <ChangeFrecuency
              hasActiveOrder={data.order != null}
              onClose={() => setActiveAction(undefined)}
              activeFrecuency={data.shipping_frecuency}
            />
          )}

          {activeAction === "CHANGE_DAY" && (
            <ChangeNextDeliveryDate
              hasActiveOrder={data.order != null}
              onClose={() => setActiveAction(undefined)}
              deliveryDate={data.next_delivery_date}
            />
          )}

          {activeAction === "PAUSE_SUBSCRIPTION" && (
            <PauseSubscription
              hasActiveOrder={data.order != null}
              onClose={() => setActiveAction(undefined)}
              deliveryDate={data.next_delivery_date}
              onDeleteSubscription={() =>
                setActiveAction("CANCEL_SUBSCRIPTION")
              }
            />
          )}
          {activeAction === "CANCEL_SUBSCRIPTION" && (
            <DeleteAccount
              open={activeAction === "CANCEL_SUBSCRIPTION"}
              onClose={() => setActiveAction(undefined)}
            />
          )}

          {activeAction === "SKIP_NEXT_DELIVERY" && (
            <SkipNextDelivery
              hasActiveOrder={data.order != null}
              deliveryDate={data.next_delivery_date}
              nextDeliveryDate={data.next_after_next_delivery_date}
              onClose={() => setActiveAction(undefined)}
            />
          )}
        </>
      );
    }
  };

  return (
    <div className="space-y-4">
      <div>
        <h3 className="text-lg font-medium">Editar tu suscripción</h3>
        <p className="text-sm text-muted-foreground">
          Puedes cambiar tu tipo de caja, la frecuencia y el día de envío.
        </p>
      </div>
      <Separator />
      <div className="space-y-4">{renderContent()}</div>
    </div>
  );
};

export default EditSubscription;
