import axios from "axios";
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import { config } from "@/config";
import { getToken, getUIDParam } from "@/services/utils";
import { ShippingFrecuency } from "@/services/types";

export const postEditSubscriptionType = async (data: {
  subscription_id: number;
}) => {
  const token = await getToken();
  return axios.post<{
    status: "ERROR" | "OK";
    error_code?: "RECIPE_NOT_READY" | "SUBSCRIPTION_ID_NOT_FOUND";
  }>(`${config.BACKEND_URL}/api/v1/subscriptions/edit/type`, data, {
    headers: { Authorization: `Bearer ${token}` },
    params: {
      ...getUIDParam(),
    },
  });
};

export const subscriptionTypeMutation = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: postEditSubscriptionType,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["subscription-status"] });
    },
  });
};

export const postEditSubscriptionFrecuency = async (data: {
  frecuency: ShippingFrecuency;
}) => {
  const token = await getToken();
  return axios.post<{
    status: "ERROR" | "OK";
    error_code?: "RECIPE_NOT_READY";
  }>(`${config.BACKEND_URL}/api/v1/subscriptions/edit/frecuency`, data, {
    headers: { Authorization: `Bearer ${token}` },
    params: {
      ...getUIDParam(),
    },
  });
};

export const subscriptionFrecuencyMutation = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: postEditSubscriptionFrecuency,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["subscription-status"] });
    },
  });
};

const fetchNextWeekDeliveryDates = async () => {
  const token = await getToken();
  return axios.get<{ dates: string[] }>(
    `${config.BACKEND_URL}/api/v1/subscriptions/edit/next_delivery`,
    {
      headers: { Authorization: `Bearer ${token}` },
      params: {
        ...getUIDParam(),
      },
    },
  );
};

export const nextWeekDeliveryDatesQuery = () =>
  useQuery({
    queryKey: ["next-delivery-dates"],
    queryFn: fetchNextWeekDeliveryDates,
  });

export const postEditSubscriptionNextDeliveryDate = async (data: {
  delivery_date: string;
}) => {
  const token = await getToken();
  return axios.post<{
    status: "ERROR" | "OK";
    error_code?: "RECIPE_NOT_READY" | "DELIVERY_DATE_IS_REQUIRED";
  }>(`${config.BACKEND_URL}/api/v1/subscriptions/edit/next_delivery`, data, {
    headers: { Authorization: `Bearer ${token}` },
    params: {
      ...getUIDParam(),
    },
  });
};

export const subscriptionNextDeliveryDateMutation = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: postEditSubscriptionNextDeliveryDate,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["subscription-status"] });
    },
  });
};

const getPauseSubscriptionDates = async () => {
  const token = await getToken();
  return axios.get<{ dates: string[] }>(
    `${config.BACKEND_URL}/api/v1/subscriptions/edit/pause`,
    {
      headers: { Authorization: `Bearer ${token}` },
      params: {
        ...getUIDParam(),
      },
    },
  );
};

export const subscriptionPauseQuery = () =>
  useQuery({
    queryKey: ["pause-dates"],
    queryFn: getPauseSubscriptionDates,
  });

export const postEditSubscriptionPause = async (data: {
  delivery_date: string;
}) => {
  const token = await getToken();
  return axios.post<{
    status: "OK";
  }>(`${config.BACKEND_URL}/api/v1/subscriptions/edit/pause`, data, {
    headers: { Authorization: `Bearer ${token}` },
    params: {
      ...getUIDParam(),
    },
  });
};

export const subscriptionPauseMutation = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: postEditSubscriptionPause,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["subscription-status"] });
    },
  });
};
