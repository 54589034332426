import { zodResolver } from "@hookform/resolvers/zod";

import { useForm } from "react-hook-form";
import * as z from "zod";
import { Form, FormField } from "@/components/ui/form";
import { Button } from "@/components/ui/button";
import { useNavigate } from "@tanstack/react-router";
import {
  Loading,
  RetryErrorMessage,
  FutureDaysCalendar,
  FormCalendar,
} from "@/components";
import { frequencyQuery } from "@/services/queries";
import { useRegister } from "@/context/register";
import { Navigate } from "@tanstack/react-router";
import type { RegistryData } from "@/context/register";
import { useEffect } from "react";

const frecuencyFormSchema = z.object({
  shipping_first_delivery_date: z.date({
    required_error: "La fecha de envío es obligatoria.",
  }),
});

export type FrecuencyFormSchema = z.infer<typeof frecuencyFormSchema>;

type FrecuencyFormProps = {
  initialData: RegistryData | null;
  frecuency: string;
  onSubmit: (data: FrecuencyFormSchema) => void;
};

export const FRECUENCY = {
  WEEKLY: "semanal",
  BIWEEKLY: "quincenal",
} as const;

const FrecuencyForm = ({ frecuency, onSubmit }: FrecuencyFormProps) => {
  const { registryData } = useRegister();
  const navigate = useNavigate();

  if (registryData === null) {
    return <Navigate to="/register/subscriptions" />;
  }

  const form = useForm<FrecuencyFormSchema>({
    resolver: zodResolver(frecuencyFormSchema),
  });

  // Reset delivery date after changing shipping frecuency
  useEffect(() => {
    form.resetField("shipping_first_delivery_date");
  }, [registryData.shipping_frecuency]);

  function handleSubmit({ shipping_first_delivery_date }: FrecuencyFormSchema) {
    onSubmit({ shipping_first_delivery_date });
  }

  const {
    data: response,
    isLoading,
    isError,
    error,
    refetch,
  } = frequencyQuery({
    shipping_type: registryData.shipping_type!,
    pickup_name: registryData.pickup_name,
    district_name:
      registryData.shipping_type! === "DELIVERY"
        ? registryData.delivery_district
        : registryData.pickup_district,
  });
  const data = response?.data;

  if (isLoading) {
    return <Loading message="🔍 Buscando frecuencias de envío..." />;
  }

  if (isError) {
    return (
      <RetryErrorMessage
        title={"No se pudo obtener la información de las frecuencias de envío."}
        message={error.message}
        retry={refetch}
        error={error}
      />
    );
  }

  if (data) {
    const { shipping_first_delivery_date: delivery_date } = form.watch();
    const { dates } = data;
    const shouldShowSummary = delivery_date;

    return (
      <div className="flex flex-col gap-8">
        <Form {...form}>
          <form
            onSubmit={form.handleSubmit(handleSubmit)}
            className="space-y-8"
          >
            <FormField
              control={form.control}
              name="shipping_first_delivery_date"
              render={({ field }) => {
                const handleOnChange = (value: Date) => {
                  field.onChange(value);
                };
                return (
                  <FormCalendar
                    field={field}
                    onChange={handleOnChange}
                    availableDates={dates}
                  />
                );
              }}
            />

            {shouldShowSummary && (
              <FutureDaysCalendar
                frecuency={frecuency}
                delivery_date={delivery_date}
              />
            )}
            <div className="flex justify-between py-4 gap-4">
              <Button
                type="button"
                variant="outline"
                className="w-full sm:w-fit"
                onClick={() => navigate({ to: "/register/shipping-methods" })}
              >
                Volver
              </Button>
              <Button type="submit" className="w-full sm:w-fit">
                Siguiente
              </Button>
            </div>
          </form>
        </Form>
      </div>
    );
  }
};

export default FrecuencyForm;
