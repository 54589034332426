import { cn } from "@/lib/utils";
import { MessageSquareText } from "lucide-react";
import { Progress } from "@/components/ui/progress";
import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from "@/components/ui/card";

type PageLayoutProps = {
  title?: string;
  description?: string;
  progress?: number;
  withCard?: boolean;
} & React.ComponentProps<typeof Card>;

function PageLayout({ className, ...props }: PageLayoutProps) {
  const { title, description, progress, children, withCard = true } = props;
  return (
    <>
      <div className="w-full md:w-9/12 mx-auto max-w-5xl">
        {progress && (
          <div className="mb-4">
            <Progress value={progress} />
          </div>
        )}
        {withCard ? (
          <div>
            <Card className={cn("mb-8 relative z-10", className)} {...props}>
              {(title || description) && (
                <CardHeader>
                  {title && <CardTitle className="text-2xl">{title}</CardTitle>}
                  {description && (
                    <CardDescription>{description}</CardDescription>
                  )}
                </CardHeader>
              )}
              <CardContent className="grid gap-4 p-4 md:p-6 pt-0">
                {children}
              </CardContent>
            </Card>
            <div className="flex">
              <a
                href="https://wa.me/+56971295665"
                className="py-2 px-6 pt-4 rounded-md bg-[#075E54] text-white font-semibold text-sm -translate-y-10 translate-x-6 flex space-x-2"
                target="_blank"
                rel="noopener noreferrer"
              >
                <MessageSquareText /> <span>¿Necesitas ayuda?</span>
              </a>
            </div>
          </div>
        ) : (
          children
        )}
      </div>
    </>
  );
}

export default PageLayout;
