import { cn } from "@/lib/utils";

type TableItemProps = {
  title: React.ReactNode;
  content: React.ReactNode;
  isImportant?: boolean;
  variant?: "simple";
};

const TableItem = ({
  title,
  content,
  isImportant,
  variant,
}: TableItemProps) => {
  return (
    <div
      className={cn(
        " bg-orange-100 p-2 px-4 flex justify-between items-center border-b border-b-orange-200",
        variant === "simple" && "bg-white",
      )}
    >
      <div className="text-sm text-muted-foreground leading-none">{title}</div>
      <div
        className={cn(
          "text-base font-normal text-right",
          isImportant && "text-base font-semibold text-primary",
        )}
      >
        <span className="font-sans">{content}</span>
      </div>
    </div>
  );
};

export default TableItem;
