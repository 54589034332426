import { ForgotPassword as ForgotPasswordPage } from "@/pages";
import { createFileRoute } from "@tanstack/react-router";
import { LayoutDefault } from "@/components";

const ForgotLayout = () => (
  <LayoutDefault>
    <ForgotPasswordPage />
  </LayoutDefault>
);

export const Route = createFileRoute("/forgot-password")({
  component: ForgotLayout,
  beforeLoad: () => {
    document.title = "Maifud - Recuperar contraseña";
  },
});
