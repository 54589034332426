import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
} from "@/components/ui/alert-dialog";

type AlertConfirmationProps = {
  open: boolean;
  title: string;
  children: React.ReactNode;
  isLoading?: boolean;
  errorMessage?: React.ReactNode;
  successMessage?: React.ReactNode;
  isDestructive?: boolean;
  onClose: () => void;
  onConfirm: () => void;
};

const AlertConfirmation = ({
  open,
  title,
  children,
  onClose,
  onConfirm,
  isLoading,
  errorMessage,
  successMessage,
  isDestructive,
}: AlertConfirmationProps) => {
  return (
    <AlertDialog open={open}>
      <AlertDialogContent>
        <AlertDialogHeader>
          <AlertDialogTitle>{title}</AlertDialogTitle>
          <AlertDialogDescription>{children}</AlertDialogDescription>
        </AlertDialogHeader>
        {errorMessage}
        {successMessage}
        {!successMessage ? (
          <AlertDialogFooter>
            <AlertDialogCancel disabled={isLoading} onClick={onClose}>
              Cancelar
            </AlertDialogCancel>
            {isDestructive ? (
              <AlertDialogAction
                disabled={isLoading}
                onClick={onConfirm}
                className="bg-red-500 hover:bg-red-500"
              >
                {isLoading ? "Eliminando" : "Eliminar"}
              </AlertDialogAction>
            ) : (
              <AlertDialogAction disabled={isLoading} onClick={onConfirm}>
                {isLoading ? "Guardando" : "Confirmar"}
              </AlertDialogAction>
            )}
          </AlertDialogFooter>
        ) : (
          <AlertDialogFooter>
            <AlertDialogAction onClick={onClose}>Cerrar</AlertDialogAction>
          </AlertDialogFooter>
        )}
      </AlertDialogContent>
    </AlertDialog>
  );
};

export default AlertConfirmation;
