import { Outlet, createFileRoute } from "@tanstack/react-router";
import { RegisterProvider } from "@/context/register";
import { LayoutDefault } from "@/components";

const RegisterLayout = () => (
  <LayoutDefault>
    <RegisterProvider>
      <Outlet />
    </RegisterProvider>
  </LayoutDefault>
);

export const Route = createFileRoute("/register")({
  component: RegisterLayout,
});
