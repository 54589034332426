import { useState } from "react";
import { useNavigate } from "@tanstack/react-router";
import { PageLayout, FrecuencyForm } from "@/components";
import { RadioGroup, RadioGroupItem } from "@/components/ui/radio-group";
import { Label } from "@/components/ui/label";
import weeklyImage from "@/assets/weekly.png";
import biweeklyImage from "@/assets/biweekly.png";
import { useRegister } from "@/context/register";
import {
  type FrecuencyFormSchema,
  FRECUENCY,
} from "@/components/FrecuencyForm/FrecuencyForm";
import { useScrollToTop } from "@/utils/hooks";

type ShippingFrecuency = (typeof FRECUENCY)[keyof typeof FRECUENCY];

const ShippingFrecuency = () => {
  useScrollToTop();
  const { registryData, setRegistryData } = useRegister();

  const navigate = useNavigate();

  if (registryData === null) {
    navigate({ to: "/register/subscriptions" });
    return null;
  }

  const initialFrecuency = registryData
    ? (registryData.shipping_frecuency?.toLowerCase() as ShippingFrecuency)
    : undefined;
  const [selectedFrecuency, setSelectedFrecuency] = useState<
    ShippingFrecuency | undefined
  >(initialFrecuency);

  const handleShippingFrecuencySelection = (value: ShippingFrecuency) => {
    setSelectedFrecuency(value);
    setRegistryData({
      ...registryData,
      // TODO: the backend expects the first letter capitalized. Remove this once the backend is fixed
      shipping_frecuency: value === "semanal" ? "Semanal" : "Quincenal",
    });
  };

  const handleSubmitFrecuencyForm = ({
    shipping_first_delivery_date,
  }: FrecuencyFormSchema) => {
    setRegistryData({
      ...registryData,
      shipping_first_delivery_date,
    });
    goToNextPage();
  };

  const goToNextPage = () => {
    navigate({ to: "/register/user-data" });
  };

  return (
    <PageLayout
      title="Elige frecuencia y día de entrega"
      description="Esto aplica para tu primera caja. Podrás cambiar la frecuencia y el día para tus próximas cajas desde tu cuenta."
      progress={60}
    >
      <div className="flex flex-col space-y-4">
        <div className="flex flex-col space-y-2">
          <Label>Selecciona la frecuencia del envío</Label>
          <RadioGroup
            onValueChange={handleShippingFrecuencySelection}
            defaultValue={selectedFrecuency}
            className="grid grid-cols-3 gap-4"
          >
            <div>
              <RadioGroupItem
                value="semanal"
                id="semanal"
                className="peer sr-only"
              />
              <Label
                htmlFor="semanal"
                className="flex flex-col items-center justify-between rounded-md border-2 border-muted bg-popover p-4 hover:bg-accent hover:text-accent-foreground peer-data-[state=checked]:border-primary [&:has([data-state=checked])]:border-primary"
              >
                <div className="w-24 py-2">
                  <img src={weeklyImage} alt="Icono de plan semanal" />
                </div>
                Semanal
              </Label>
            </div>
            <div>
              <RadioGroupItem
                value="quincenal"
                id="quincenal"
                className="peer sr-only"
              />
              <Label
                htmlFor="quincenal"
                className="flex flex-col items-center justify-between rounded-md border-2 border-muted bg-popover p-4 hover:bg-accent hover:text-accent-foreground peer-data-[state=checked]:border-primary [&:has([data-state=checked])]:border-primary"
              >
                <div className="w-24 py-2">
                  <img src={biweeklyImage} alt="Icono de plan quincenal" />
                </div>
                Quincenal
              </Label>
            </div>
          </RadioGroup>
        </div>
        {selectedFrecuency && (
          <FrecuencyForm
            frecuency={selectedFrecuency}
            initialData={registryData}
            onSubmit={handleSubmitFrecuencyForm}
          />
        )}
      </div>
    </PageLayout>
  );
};

export default ShippingFrecuency;
