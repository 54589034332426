import { Label } from "@/components/ui/label";
import { CardContent, Card } from "@/components/ui/card";
import airImage from "@/assets/air.png";
import foodImage from "@/assets/food.png";
import waterImage from "@/assets/water.png";

type StatsProps = {
  stats: {
    statsCO2: string;
    statsH2O: string;
    statsKg: string;
  };
};

type StatProp = {
  value: string;
  content: string;
  image: string;
  alt: string;
};

const Stat = ({ image, alt, value, content }: StatProp) => {
  return (
    <div className="flex flex-col items-center">
      <div className="w-28 h-28 flex items-center justify-center">
        <img src={image} className="scale-125" alt={alt} />
      </div>
      <strong className="text-primary text-xl font-sans">{value}</strong>
      <p className="text-center text-sm text-muted-foreground">{content}</p>
    </div>
  );
};

const Stats = ({ stats }: StatsProps) => {
  const statsData = [
    {
      image: foodImage,
      alt: "",
      value: stats.statsKg,
      content: "Kgs de alimentos rescatados",
    },
    {
      image: airImage,
      alt: "",
      value: stats.statsCO2,
      content: "Kgs de CO2 que no se emitieron",
    },
    {
      image: waterImage,
      alt: "",
      value: stats.statsH2O,
      content: "Litros de agua que no se perdieron",
    },
  ];
  return (
    <>
      <Label className="mb-2 block">Tu impacto ambiental</Label>
      <Card className="border rounded-lg p-2">
        <CardContent className="grid gap-4 p-2">
          <div className="grid grid-cols-3 gap-2">
            {statsData.map((stat) => (
              <Stat key={stat.image} {...stat} />
            ))}
          </div>
        </CardContent>
      </Card>
    </>
  );
};

export default Stats;
