import { CardContent, Card } from "@/components/ui/card";
import { formatDefault } from "@/utils/dates";
import { formatDistanceStrict } from "date-fns";
import { es } from "date-fns/locale";

type NextOrderProps = {
  deliveryDate: string;
  paymentDatetime: string;
  nextRecipeDatetime: string;
  nextCutoffDatetime: string;
  now: string;
};

const NextOrder = ({
  deliveryDate,
  paymentDatetime,
  now,
  nextRecipeDatetime,
  nextCutoffDatetime,
}: NextOrderProps) => {
  const [_, timezoneInfo] = now.split("T");
  return (
    <div>
      <Card className="border rounded-lg col-span-2 relative z-20">
        <div className="bg-primary px-4 py-1 rounded-tl-lg rounded-tr-lg shadow-sm flex justify-between">
          <strong className="text-white font-normal font-sans">
            Tu proximo envio
          </strong>
        </div>
        <CardContent className="px-4">
          <div className="flex justify-between border-b py-4">
            <div>
              <p className="text-xl font-semibold text-primary">
                {formatDefault(deliveryDate)}
              </p>
              <p className="text-base font-semibold text-muted-foreground">
                Faltan{" "}
                <strong className="text-primary font-semibold">
                  {formatDistanceStrict(
                    now,
                    `${deliveryDate}T${timezoneInfo}`,
                    {
                      locale: es,
                    },
                  )}
                </strong>{" "}
                para tu próximo envío.
              </p>
            </div>
          </div>

          <div className="pt-4 flex">
            <div className="w-[2px] bg-slate-500 h-[160px] relative z-0 translate-x-3"></div>
            <div className="flex flex-col space-y-6 relative z-10">
              <div className="flex gap-4">
                <div>
                  <div className="w-6 h-6 rounded-full bg-primary relative z-10"></div>
                </div>
                <div>
                  <p className="text-sm font-semibold leading-tight">
                    {formatDefault(paymentDatetime)} -{" "}
                    <span className="font-sans text-[10px]">7 PM</span>
                  </p>
                  <p className="text-sm text-muted-foreground">
                    Se hará el cobro de tu próxima caja
                  </p>
                </div>
              </div>

              <div className="flex gap-4">
                <div>
                  <div className="w-6 h-6 rounded-full bg-primary relative z-10"></div>
                </div>
                <div>
                  <p className="text-sm font-semibold leading-tight">
                    {formatDefault(nextRecipeDatetime)} -{" "}
                    <span className="font-sans text-[10px]">9 AM</span>
                  </p>
                  <p className="text-sm text-muted-foreground">
                    Podrás ver y editar el contenido de tu caja. Además, podrar
                    comprar otros productos de Maifud para que vayan con el
                    mismo envío.
                  </p>
                </div>
              </div>

              <div className="flex gap-4">
                <div>
                  <div className="w-6 h-6 rounded-full bg-primary relative z-10"></div>
                </div>
                <div>
                  <p className="text-sm font-semibold leading-tight">
                    {formatDefault(nextCutoffDatetime)} -{" "}
                    <span className="font-sans font-normal text-[10px]">
                      3 PM
                    </span>
                  </p>
                  <p className="text-sm text-muted-foreground">
                    Comenzaremos a preparar tu caja. Desde ese momento ya no
                    podrás hacer cambios.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </CardContent>
      </Card>
    </div>
  );
};

export default NextOrder;
