import { useState } from "react";
import { CardContent, Card } from "@/components/ui/card";
import { CustomLink, OrderProductsAlert } from "@/components";
import happyAvocadoImage from "@/assets/happy_avocado.png";
import { formatDefault } from "@/utils/dates";
import { Button } from "@/components/ui/button";
import { DBOrder, SummaryBoxItem, SummaryUpsellItem } from "@/services/types";
import EditBoxAlert from "./EditBoxAlert";

type BoxClosedBySystemProps = {
  order: DBOrder;
  userInput?: string;
  products?: SummaryBoxItem[];
  upsell?: SummaryUpsellItem[];
  isEditable: boolean;
};

const BoxClosedBySystem = ({
  order,
  products,
  upsell,
  userInput,
  isEditable,
}: BoxClosedBySystemProps) => {
  const [showProductDetails, setShowProductDetails] = useState(false);

  const renderAddress = () => {
    if (order.shipping_type === "PICKUP") {
      const address = `${order.pickup_address}, ${order.pickup_district}`;
      return (
        <div className="mt-4">
          <p className="text-sm text-muted- font-semibold">Retiro en tienda:</p>
          <p className="text-sm text-muted-foreground font-sans">
            <a
              href={`https://www.google.com/maps/place/${address}`}
              target="_blank"
            >
              {address}
            </a>
          </p>
          <p className="text-sm text-muted-foreground font-sans">
            Horario: {order.pickup_opening_hours}
          </p>
        </div>
      );
    }
    if (order.shipping_type === "DELIVERY") {
      const address = `${order.person_address}, ${order.person_address_dpto}, ${order.person_district}`;
      return (
        <div className="mt-4">
          <p className="text-sm text-muted- font-semibold">Dirección:</p>
          <p className="text-sm text-muted-foreground font-sans">{address}</p>
          {order.person_adittional_data && (
            <p className="text-sm text-muted-foreground font-sans">
              "{order.person_adittional_data?.trim()}"
            </p>
          )}
        </div>
      );
    }
    return null;
  };

  return (
    <div>
      <Card className="border rounded-lg col-span-2 relative z-20">
        <div className="bg-primary px-4 py-1 rounded-tl-lg rounded-tr-lg shadow-sm flex justify-between">
          <strong className="text-white font-normal font-sans">
            Tu proximo envio
          </strong>
        </div>
        <CardContent className="px-4">
          <div className="flex justify-between border-b py-4">
            <div>
              <p className="text-xl font-semibold text-primary">
                {formatDefault(order.delivery_date)}
              </p>
              <p className="text-base font-semibold text-muted-foreground">
                {order.subscription_name}
              </p>
              {renderAddress()}
            </div>
          </div>

          <div className="pt-4">
            <div className="flex flex-col md:flex-row justify-between items-center gap-6">
              <div className="space-y-2">
                {userInput && (
                  <p className="text-sm">
                    Recibimos los cambios que pediste y estamos preparando tu
                    caja.
                  </p>
                )}
                <p className="text-sm">
                  Ya estamos preparando tu caja. Gracias por ayudarnos a
                  combatir el desperdicio de alimentos.
                </p>
              </div>
              <div className="flex flex-col gap-4 md:gap-2 items-center md:items-end w-full">
                <Button
                  className="w-full md:w-fit"
                  type="button"
                  onClick={() => setShowProductDetails(true)}
                >
                  Ver contenido caja
                </Button>
                {isEditable && <EditBoxAlert />}
              </div>
            </div>
          </div>

          {showProductDetails && products && upsell && (
            <OrderProductsAlert
              order={order}
              userInput={userInput}
              products={products}
              upsell={upsell}
              onClose={() => setShowProductDetails(false)}
            />
          )}
        </CardContent>
      </Card>
      {isEditable && (
        <div className="flex items-center border px-6 py-4  rounded-bl-md rounded-br-md gap-6 bg-primary/10 -translate-y-[5px] relative z-10">
          <div className="w-[60px]">
            <img src={happyAvocadoImage} alt="Palta felíz" className="w-full" />
          </div>
          <div className="flex flex-col w-full">
            <h3 className="font-semibold">Complementa tu caja</h3>
            <p className="text-muted-foreground text-sm font-semibold">
              Agrega productos de Maifud a tu caja sin costo de envío extra.
            </p>
            <CustomLink
              to="/user/subscription/box/add-products"
              className="text-primary underline font-semibold"
            >
              Ver productos
            </CustomLink>
          </div>
        </div>
      )}
    </div>
  );
};

export default BoxClosedBySystem;
