export const formatCurrency = (
  price: number,
  currency = "CLP",
  locale = "es-CL",
) => {
  return new Intl.NumberFormat(locale, {
    style: "currency",
    currency: currency,
  }).format(price);
};

export const formatStringCurrency = (
  price: string,
  currency = "CLP",
  locale = "es-CL",
) => {
  if (!price.includes("$")) {
    return `$${price}`;
  }
  const [_, priceString] = price.split("$");
  const priceNumber = Number(priceString);
  const prefix = price.includes("-") ? "-" : "";
  return prefix + formatCurrency(priceNumber, currency, locale);
};
