import {
  Card,
  CardHeader,
  CardTitle,
  CardDescription,
  CardFooter,
  CardContent,
} from "@/components/ui/card";
import referralIcon from "@/assets/referral.png";
import { referralQuery } from "@/services/queries";
import { ButtonCustomLink, Loading } from "@/components";
import { Navigate, useSearch, useParams } from "@tanstack/react-router";
import type { ReferralSearchSchema } from "@/routes/li";

const ReferralLanding = () => {
  const { referral_code } = useSearch({
    strict: false,
  }) as ReferralSearchSchema;
  const { referralCode } = useParams({ from: "/r/$referralCode" });
  const {
    data: response,
    isLoading,
    isError,
  } = referralQuery(referral_code || referralCode);
  const data = response?.data;

  const renderContent = () => {
    if (isLoading) {
      return <Loading message="🍊 Preparando Maifud para tí..." />;
    }
    if (isError) {
      return <Navigate to="/" />;
    }
    if (data) {
      const { referrer_name, referrer_code } = data;
      return (
        <Card>
          <CardHeader className="space-y-1">
            <CardTitle className="text-2xl text-center">¡Hola!</CardTitle>
            <CardDescription className="text-center">
              <strong>{referrer_name}</strong> te{" "}
              <strong className="text-primary">regala un envío gratis</strong>{" "}
              en tu suscripción a nuestras cajas imperfectas.
            </CardDescription>
          </CardHeader>
          <CardContent>
            <div className="w-20 h-20 rounded-full mx-auto">
              <img src={referralIcon} alt="Imagen de zanahorias compartiendo" />
            </div>
            <p className="text-center text-muted-foreground text-sm mt-4">
              <a href="/#faq-section" target="_blank" className="underline">
                ¿Quieres saber más de nosotros?
              </a>
            </p>
          </CardContent>
          <CardFooter className="flex flex-col space-y-2">
            <ButtonCustomLink
              to="/register/subscriptions"
              className="w-full"
              search={{ referrer_code }}
            >
              Empecemos
            </ButtonCustomLink>
          </CardFooter>
        </Card>
      );
    }
  };

  return (
    <div className="mx-auto max-w-[400px] w-full mt-8 mb-24">
      {renderContent()}
    </div>
  );
};

export default ReferralLanding;
