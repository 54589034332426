import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
} from "@/components/ui/alert-dialog";

type ConfirmationAlertProps = {
  onConfirm: () => void;
};

const ConfirmationAlert = ({ onConfirm }: ConfirmationAlertProps) => {
  return (
    <AlertDialog open={true}>
      <AlertDialogContent>
        <AlertDialogHeader>
          <AlertDialogTitle>
            Agregar método de pago y finalizar
          </AlertDialogTitle>
          <AlertDialogDescription>
            Se abrirá una nueva ventana para que ingreses tu método de pago y
            finalices el proceso de suscripción
          </AlertDialogDescription>
        </AlertDialogHeader>
        <AlertDialogFooter>
          <AlertDialogAction onClick={onConfirm}>
            Completar suscripción
          </AlertDialogAction>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  );
};

export default ConfirmationAlert;
