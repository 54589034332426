export const getErrorMessage = (errorMessage: string) => {
  if (
    errorMessage.includes("auth/user-not-found") ||
    errorMessage.includes("auth/wrong-password")
  ) {
    return "El usuario o la contraseña no son correctos. Verifica los datos e intenta de nuevo";
  } else if (errorMessage.includes("auth/too-many-requests")) {
    return "Has tenido muchos intentos fallidos. Intentalo de nuevo más tarde.";
  } else {
    return errorMessage;
  }
};
