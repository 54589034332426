import { CardContent, Card } from "@/components/ui/card";
import { ImportantMessage } from "@/components";

const TerminatedSubscription = () => {
  return (
    <Card className="border rounded-lg p-2">
      <CardContent className="grid p-2 space-y-2">
        <div className="space-y-2">
          <ImportantMessage
            title={<p>⚠️ Tu suscripción está desactivada</p>}
            message={
              <div>
                <p className="text-muted-foreground">
                  Tu suscripción está desactivada y no se va a realizar ningún
                  cobro
                </p>
              </div>
            }
          />
        </div>
      </CardContent>
    </Card>
  );
};

export default TerminatedSubscription;
