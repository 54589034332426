import { Alert, AlertDescription, AlertTitle } from "@/components/ui/alert";
import { Button } from "../ui/button";
import { useEffect } from "react";
import * as Sentry from "@sentry/react";

type RetryErrorMessageProps = {
  title: React.ReactNode;
  message: React.ReactNode;
  retry?: () => void;
  error?: Error;
};

const RetryErrorMessage = ({
  title,
  message,
  retry,
  error,
}: RetryErrorMessageProps) => {
  useEffect(() => {
    // Send error details to sentry
    if (error) {
      Sentry.captureException(error);
    }
  }, []);

  return (
    <Alert
      variant="default"
      className="bg-red-100/50 flex flex-col items-center"
    >
      <AlertTitle className="text-base text-center">{title}</AlertTitle>
      <AlertDescription>{message}</AlertDescription>
      {retry && (
        <Button className="mt-4" variant="outline" onClick={retry}>
          Reintentar
        </Button>
      )}
    </Alert>
  );
};

export default RetryErrorMessage;
