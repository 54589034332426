import { Alert, AlertDescription, AlertTitle } from "@/components/ui/alert";

type ImportantMessageProps = {
  title: React.ReactNode;
  message: React.ReactNode;
  variant?: "destructive" | "default";
};

const ImportantMessage = ({
  title,
  message,
  variant = "default",
}: ImportantMessageProps) => {
  return (
    <Alert
      variant={variant}
      className={
        variant === "default" ? "bg-primary/10 border-primary" : "bg-red-500/10"
      }
    >
      <AlertTitle>{title}</AlertTitle>
      <AlertDescription>{message}</AlertDescription>
    </Alert>
  );
};

export default ImportantMessage;
