import { Loading } from "@/components";
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
  AlertDialogTrigger,
} from "@/components/ui/alert-dialog";
import { resetBoxMutation } from "@/services/queries";

const EditBoxAlert = () => {
  const mutation = resetBoxMutation();
  // TODO: Replace with a better option to get error response
  // @ts-expect-error
  const errorMessage = mutation?.error?.response?.data?.detail;

  const handleResetBox = () => {
    mutation.mutate();
  };

  const renderMessage = () => {
    if (mutation.isPending) {
      return <Loading message="Borrando cambios en tu caja..." />;
    }
    if (mutation.isError) {
      if (errorMessage === "Too late") {
        return (
          <p className="text-center text-red-500 text-sm">
            Tu caja ya está siendo armada por lo que no que se pueden hacer más
            modificaciones.
          </p>
        );
      }
      return (
        <p className="text-center text-red-500 text-sm">
          Hubo un problema al intentar resetear tu caja. Intenta de nuevo más
          tarde.
        </p>
      );
    }
    if (mutation.isSuccess) {
      return (
        <p>
          ✅ Los cambios de tu caja fueron eliminados correctamente. Puedes
          volver a editar el contenido de tu caja.
        </p>
      );
    }
    return (
      <p>
        Borraremos los cambios que hiciste a tu caja y podrás hacerlos de nuevo.
      </p>
    );
  };

  return (
    <AlertDialog>
      <AlertDialogTrigger>
        <button className="text-sm text-primary font-semibold" type="button">
          Editar caja
        </button>
      </AlertDialogTrigger>
      <AlertDialogContent>
        <AlertDialogHeader>
          <AlertDialogTitle>¿Editar caja?</AlertDialogTitle>
          <AlertDialogDescription>{renderMessage()}</AlertDialogDescription>
        </AlertDialogHeader>
        {!mutation.isSuccess ? (
          <AlertDialogFooter>
            <AlertDialogCancel>Cancelar</AlertDialogCancel>
            <AlertDialogAction
              disabled={mutation.isPending}
              onClick={handleResetBox}
            >
              Borrar cambios
            </AlertDialogAction>
          </AlertDialogFooter>
        ) : (
          <AlertDialogFooter>
            <AlertDialogCancel>Cerrar</AlertDialogCancel>
          </AlertDialogFooter>
        )}
      </AlertDialogContent>
    </AlertDialog>
  );
};

export default EditBoxAlert;
