import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogFooter,
} from "@/components/ui/dialog";
import { Button } from "@/components/ui/button";
import { RetryErrorMessage } from "@/components";
import { subscriptionPauseMutation } from "./queries";
import { formatDefault } from "@/utils/dates";

type SkipNextDeliveryProps = {
  hasActiveOrder: boolean;
  deliveryDate: string;
  nextDeliveryDate: string;
  onClose: () => void;
};

const SkipNextDelivery = ({
  hasActiveOrder,
  deliveryDate,
  nextDeliveryDate,
  onClose,
}: SkipNextDeliveryProps) => {
  const mutation = subscriptionPauseMutation();

  const handleMutation = () => {
    mutation.mutate({ delivery_date: nextDeliveryDate });
  };

  const mutationResponse = mutation.data?.data;

  const renderContent = () => {
    if (mutation.isError) {
      return (
        <RetryErrorMessage
          title={"No se pudo cambiar el dia. Intenta de nuevo más tarde."}
          message={mutation.error.message}
          retry={handleMutation}
          error={mutation.error}
        />
      );
    }

    return (
      <div className="grid grid-cols-1 gap-4">
        <p>
          Tu siguiente envío es el{" "}
          <strong className="text-primary font-sans">
            {formatDefault(deliveryDate)}
          </strong>
        </p>
        <p>
          ¿Deseas saltarte este envío y recibir tu siguiente envío el{" "}
          <strong className="text-primary font-sans">
            {formatDefault(nextDeliveryDate)}?
          </strong>
        </p>
      </div>
    );
  };

  return (
    <Dialog open={true} onOpenChange={onClose}>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>Saltar siguiente envío</DialogTitle>
        </DialogHeader>
        {mutationResponse?.status === "OK" ? (
          <p className="text-center text-sm font-semibold pt-4">
            ✅ Tu nueva fecha de envío es el{" "}
            <strong className="text-primary ">
              {formatDefault(deliveryDate)}
            </strong>
            .
          </p>
        ) : (
          <div className="grid gap-4 pt-4">{renderContent()}</div>
        )}
        {hasActiveOrder && (
          <p className="text-center text-sm text-primary font-semibold">
            🔔 Tienes una orden activa. El cambio que hagas aplicará para tus
            siguientes envíos.
          </p>
        )}
        <DialogFooter className="gap-4 pt-2 md:gap-2">
          <Button
            type="button"
            variant="secondary"
            onClick={onClose}
            disabled={mutation.isPending}
          >
            Cerrar
          </Button>
          {mutationResponse?.status !== "OK" && (
            <Button
              type="submit"
              onClick={handleMutation}
              disabled={mutation.isPending}
            >
              {mutation.isPending ? "Guardando..." : "Aceptar cambio"}
            </Button>
          )}
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};

export default SkipNextDelivery;
