import SVGSeparator from "./SVGSeparator";
import { Footer as FooterComponent } from "@/components";

const Footer = () => {
  return (
    <>
      <SVGSeparator direction="UP" />
      <div className="bg-[#f6e9dd]">
        <div className="max-w-7xl mx-auto">
          <FooterComponent />
        </div>
      </div>
    </>
  );
};

export default Footer;
