import { cn } from "@/lib/utils";

type SVGSeparatorProps = {
  direction?: "UP" | "DOWN";
};

const SVGSeparator = ({ direction = "DOWN" }: SVGSeparatorProps) => {
  return (
    <div
      className={cn(
        "home-wave",
        direction === "DOWN" ? "rotate-180" : "rotate-0",
      )}
    ></div>
  );
};

export default SVGSeparator;
