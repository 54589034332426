import { PageLayout, UserDataForm } from "@/components";
import { useScrollToTop } from "@/utils/hooks";

function UserData() {
  useScrollToTop();
  return (
    <PageLayout
      title="Tus datos personales"
      description="Crearemos una cuenta con estos datos para que luego puedas revisar o editar tu suscripción"
      progress={80}
    >
      <UserDataForm />
    </PageLayout>
  );
}

export default UserData;
