import { CardContent, Card } from "@/components/ui/card";
import { ImportantMessage, ButtonCustomLink } from "@/components";
import { orderProductsQuery } from "@/services/queries";
import { formatDefault } from "@/utils/dates";
import { DBOrder, SubscriptionState } from "@/services/types";
import WaitingForBoxConfirmation from "./WaitingForBoxConfirmation";
import BoxClosedBySystem from "./BoxClosedBySystem";
import WaitingForRecipe from "./WaitingForRecipe";
import DeliveryDay from "./DeliveryDay";
import BoxConfirmed from "./BoxConfirmed";

type ActiveOrderProps = {
  subscriptionState: SubscriptionState;
  nextCutoffDatetime: string;
  nextRecipeDatetime: string;
  order: DBOrder;
  isEditable: boolean;
};

const ActiveOrder = ({
  subscriptionState,
  order,
  nextCutoffDatetime,
  nextRecipeDatetime,
  isEditable,
}: ActiveOrderProps) => {
  const { delivery_date } = order;
  const { data: productsResponse } = orderProductsQuery(
    order.id,
    subscriptionState === "BOX_CONFIRMED" ||
      subscriptionState === "DELIVERY_DAY" ||
      subscriptionState === "BOX_CLOSED_BY_SYSTEM",
  );

  if (subscriptionState === "WAITING_FOR_BOX_CONFIRMATION") {
    return (
      <WaitingForBoxConfirmation
        order={order}
        deliveryDate={delivery_date}
        nextCutoffDatetime={nextCutoffDatetime}
      />
    );
  }
  if (subscriptionState === "DELIVERY_DAY") {
    const userInput = productsResponse?.data.metadata?.user_input;
    const selectedOrderProducts = productsResponse?.data.content;
    const upsell = productsResponse?.data.upsell;
    return (
      <DeliveryDay
        products={selectedOrderProducts}
        upsell={upsell}
        userInput={userInput}
        order={order}
      />
    );
  }
  if (subscriptionState === "BOX_CONFIRMED") {
    const userInput = productsResponse?.data.metadata?.user_input;
    const products = productsResponse?.data.content;
    const upsell = productsResponse?.data.upsell;
    return (
      <BoxConfirmed
        order={order}
        products={products}
        upsell={upsell}
        userInput={userInput}
        isEditable={isEditable}
      />
    );
  }
  if (subscriptionState === "BOX_CLOSED_BY_SYSTEM") {
    const products = productsResponse?.data.content;
    const upsell = productsResponse?.data.upsell;
    return (
      <BoxClosedBySystem order={order} products={products} upsell={upsell} />
    );
  }
  if (subscriptionState === "WAITING_FOR_RECIPE") {
    return (
      <WaitingForRecipe order={order} nextRecipeDatetime={nextRecipeDatetime} />
    );
  }
  if (subscriptionState === "ORDER_WAITING_FOR_PAYMENT") {
    return (
      <Card className="border rounded-lg p-2">
        <CardContent className="grid p-2 space-y-2">
          <div>
            <p>
              <strong className="text-primary font-semibold font-sans">
                {formatDefault(delivery_date)}
              </strong>
            </p>
          </div>
          <div className="space-y-2">
            <ImportantMessage
              title={<p>🚫 Problema con el pago</p>}
              message={
                <div className="pt-1 space-y-1">
                  <p className="text-muted-foreground text-sm">
                    No pudimos procesar el pago de tu pedido. Por favor, intenta
                    realizar el pago nuevamente o añade un nuevo método de pago.
                    Una vez que se regularice la situación, podremos programar
                    el envío de tu caja.
                  </p>
                </div>
              }
            />
            <div className="flex flex-col pt-2">
              <ButtonCustomLink
                to="/user/payment-methods"
                className="bg-red-500 hover:bg-red-600"
              >
                Ver métodos de pago
              </ButtonCustomLink>
            </div>
          </div>
        </CardContent>
      </Card>
    );
  }
};

export default ActiveOrder;
